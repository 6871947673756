import { FC, useEffect, useState } from 'react';
import LiveGamesService from 'src/services/live-games-service';
import { getIsDocumentHidden } from 'src/utils/pageVisibility/pageVisibilityHelper';
import { FFLiveGamesHeader } from '../ff-live-games-header';
import { LiveGamesDocuments } from '../ff-live-games-header/dto';
import { FMHLiveGamesHeaderMobile } from '../fmh-live-games-header-mobile';
import FMHLiveGamesWrapperProps, { LiveGame } from './dto';

const FMHLiveGamesWrapper: FC<FMHLiveGamesWrapperProps> = ({
    liveGamesPageUrl,
    tournamentName,
}: FMHLiveGamesWrapperProps) => {
    const [games, setGames] = useState<LiveGame[]>();
    const [game, setGame] = useState<LiveGame>();
    const [currentGame, setCurrentGame] = useState<number>(0);
    const [gameDocuments, setGameDocuments] = useState<LiveGamesDocuments>();
    const [isLoading, setIsLoading] = useState(false);
    const intervalValue = 30000;
    const [isVisible, setIsVisible] = useState(getIsDocumentHidden());
    const onVisibilityChange = () => setIsVisible(getIsDocumentHidden());

    const setGameAndMatch = (game: LiveGame) => {
        setGame(game);
    };

    useEffect(() => {
        document.addEventListener('visibilitychange', onVisibilityChange, false);

        return () => {
            document.removeEventListener('visibilitychange', onVisibilityChange);
        };
    });

    useEffect(() => {
        setIsLoading(true);
        LiveGamesService.getLiveGames(tournamentName)
            .then((liveGames: LiveGame[]) => {
                if (liveGames?.length > 0) {
                    setGames(liveGames);
                    const baseGame = liveGames.find((games) => games.baseGame === true);
                    if (baseGame) {
                        const indexOdElement = liveGames.indexOf(baseGame);
                        setCurrentGame(indexOdElement);
                        setGameAndMatch(liveGames[indexOdElement]);
                    }
                }
            })
            .finally(() => setIsLoading(false));
        LiveGamesService.removeIndexOfGameFromLocalStorage();
        const updateLiveGamesInterval = setInterval(() => {
            if (!isVisible) clearInterval(updateLiveGamesInterval);
            LiveGamesService.getLiveGames(tournamentName).then((liveGames: LiveGame[]) => {
                if (liveGames && liveGames.length > 0) {
                    setGames(liveGames);
                    if (LiveGamesService.checkIfIndexOfGameFromLocalStorage()) {
                        setCurrentGame(LiveGamesService.getIndexOfGameFromLocalStorage());
                        setGameAndMatch(liveGames[LiveGamesService.getIndexOfGameFromLocalStorage()]);
                    } else {
                        const baseGame = liveGames.find((games) => games.baseGame === true);
                        if (baseGame) {
                            const indexOdElement = liveGames.indexOf(baseGame);
                            setCurrentGame(indexOdElement);
                            setGameAndMatch(liveGames[indexOdElement]);
                        }
                    }
                }
            });
        }, intervalValue);
        return () => {
            clearInterval(updateLiveGamesInterval);
        };
    }, [tournamentName, isVisible]);

    useEffect(() => {
        if (game?.matchId) {
            LiveGamesService.getStartListsAndMatchReports(game.matchId).then((res) => {
                setGameDocuments(res);
            });
        }
    }, [game?.matchId]);

    const hasPreviousGame = (): boolean | undefined => {
        return games && games?.length > 0 && currentGame > 0;
    };

    const hasNextGame = (): boolean | undefined => {
        return games && games.length > 0 && currentGame < games?.length - 1;
    };

    const previousGame = (): void => {
        if (games && games.length > 0 && currentGame > 0) {
            const gameIndex = currentGame - 1;
            setCurrentGame(gameIndex);
            setGame(games[gameIndex]);
            LiveGamesService.setIndexOfGameToLocalStorage(gameIndex.toString());
        }
    };

    const nextGame = (): void => {
        if (games && games.length > 0 && currentGame < games?.length - 1) {
            const gameIndex = currentGame + 1;
            setCurrentGame(gameIndex);
            setGame(games[gameIndex]);
            LiveGamesService.setIndexOfGameToLocalStorage(gameIndex.toString());
        }
    };

    return (
        <>
            <div className="d-none d-md-block">
                <FFLiveGamesHeader
                    liveGamesPageUrl={liveGamesPageUrl}
                    liveGame={game}
                    funcPrevious={previousGame}
                    funcNext={nextGame}
                    hasPreviousGame={hasPreviousGame()}
                    hasNextGame={hasNextGame()}
                    documents={gameDocuments}
                    showDynamicControls={true}
                    includeSeasonName={true}
                    isGameAvailable={isLoading || !!game}
                />
            </div>
            <div className="d-block d-md-none">
                <FMHLiveGamesHeaderMobile
                    liveGamesPageUrl={liveGamesPageUrl}
                    liveGame={game}
                    funcPrevious={previousGame}
                    funcNext={nextGame}
                    hasPreviousGame={hasPreviousGame()}
                    hasNextGame={hasNextGame()}
                    documents={gameDocuments}
                    showDynamicControls={true}
                    includeSeasonName={true}
                    isGameAvailable={isLoading || !!game}
                />
            </div>
        </>
    );
};

export default FMHLiveGamesWrapper;
