import classNames from 'classnames';
import { FC, useState } from 'react';
import FFLogoProps, { LogoType } from './dto';
import Styles from './ff-logo.module.scss';
import { ReactComponent as TeamLogoPlaceholder } from '@/icons/team-logo-placeholder.svg';
import { ReactComponent as TournamentPlaceholder } from '@/icons/tournament-placeholder.svg';

const showPlaceholder = (type?: LogoType) => {
    switch (type) {
        case LogoType.Team:
            return <TeamLogoPlaceholder />;

        case LogoType.Competition:
            return <TournamentPlaceholder />;

        default:
            return null;
    }
};

const FFLogo: FC<FFLogoProps> = ({ src, className, logoType, alt }: FFLogoProps): JSX.Element => {
    const [isImagePresent, setIsImagePresent] = useState<boolean>(true);

    return (
        <div className={classNames(className, { [Styles.withBorder]: src?.includes('flags') })}>
            {src && (
                <>
                    <img
                        src={src}
                        alt={alt}
                        onLoad={() => setIsImagePresent(true)}
                        onError={() => setIsImagePresent(false)}
                        loading="lazy"
                        className={isImagePresent ? 'd-block' : 'd-none'}
                    />
                    {!isImagePresent && showPlaceholder(logoType)}
                </>
            )}
        </div>
    );
};

export default FFLogo;
