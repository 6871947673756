declare global {
    interface Window {
        AdobeDC: any;
    }
}

export default class AdobeViewSDKClient {
    readyPromise;
    adobeDCView;

    constructor() {
        this.readyPromise = new Promise<void>((resolve) => {
            if (window.AdobeDC) {
                resolve();
            } else {
                /* Wait for Adobe Acrobat Services PDF Embed API to be ready */
                document.addEventListener('adobe_dc_view_sdk.ready', () => {
                    resolve();
                });
            }
        });
        this.adobeDCView = undefined;
    }

    ready() {
        return this.readyPromise;
    }

    previewFile(fileUrl: string, divId: string, fileName?: string) {
        const config = {
            clientId: process.env.REACT_APP_ADOBE_CLIENT_ID,
            divId,
        };

        /* Initialize the AdobeDC View object */
        this.adobeDCView = new window.AdobeDC.View(config);

        /* Invoke the file preview API on Adobe DC View object */
        const previewFilePromise = this.adobeDCView.previewFile(
            {
                content: {
                    location: {
                        url: fileUrl,
                    },
                },
                metaData: {
                    fileName,
                },
            },
            {
                showFullScreen: true,
                showAnnotationTools: false,
                showDownloadPDF: false,
                showPrintPDF: false,
                showBookmarks: false,
                enableFormFilling: false,
            },
        );

        return previewFilePromise;
    }
}
