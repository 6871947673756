import { MAIN_DIV_ID } from 'src/constants/constants';
import { Maybe } from 'src/types/maybe';

export const isDirRtl = (): boolean => {
    const mainDiv = document.getElementById(MAIN_DIV_ID);
    return mainDiv?.dir === 'rtl';
};

export const ifContainSpecialChar = (text: string): boolean => {
    const format = new RegExp(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/);
    return text ? false : format.test(text);
};

export const isArrayInvalid = (arr: Maybe<any[]>): boolean => {
    return !Array.isArray(arr) || !arr.length;
};

export const isObjectInArray = (arr: any[], valueToBeChecked: any, key: string): boolean => {
    const filteredFilters = arr.filter((value) => value.hasOwnProperty(key) && value[key] === valueToBeChecked);

    return !isArrayInvalid(filteredFilters);
};

export const isLinkInternal = (url: string): boolean => {
    return url.startsWith('/');
};

export const isOnInstallableApp = (): boolean =>
    ['fullscreen', 'standalone', 'minimal-ui'].some(
        (displayMode) => window.matchMedia(`(display-mode: ${displayMode})`).matches,
    );

export const isOnSafari = (): boolean => {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.includes('safari') && !userAgent.includes('chrome') && !userAgent.includes('chromium');
};
