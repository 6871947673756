/* eslint-disable react/jsx-no-target-blank */
import { FC, useState } from 'react';
import { FMHArrowButton } from 'src/atoms/fmh-arrow-button';
import { Icon } from 'src/components/icon-generator';
import LiveGamesService from 'src/services/live-games-service';
import { FMHHighlightLabel } from 'src/atoms/fmh-highlight-label';
import FMHLiveScore from 'src/atoms/fmh-live-score/fmh-live-score';
import { isLinkInternal } from 'src/utils/check';
import { FFHeading, HeadingSizeProp } from 'src/atoms/ff-heading';
import styles from './ff-live-games-header.module.scss';
import FFLiveGamesHeaderProps from './dto';
import LiveGamesHeaderBottomRow from './live-games-header-bottom-row/live-games-header-bottom-row';
import GameDetailedStatistics from './../fmh-game-detailed-statistics/fmh-game-detailed-statistics';
import { ReactComponent as FFDownArrowIcon } from '@/icons/ff-chevron/ff-chevron-down.svg';

const FFLiveGamesHeader: FC<FFLiveGamesHeaderProps> = ({
    liveGamesPageUrl,
    funcNext,
    funcPrevious,
    hasPreviousGame,
    hasNextGame,
    liveGame,
    documents,
    showDynamicControls,
    includeSeasonName,
    isGameAvailable = true,
    isExpandable,
    link,
}: FFLiveGamesHeaderProps) => {
    const isOpenNewTab = liveGamesPageUrl ? !isLinkInternal(liveGamesPageUrl) : false;

    const showLogoAndControls = funcPrevious && funcNext && showDynamicControls ? true : false;
    const [isToggled, setIsToggled] = useState<boolean>(false);

    const expandMenuButtonPressed = () => {
        setIsToggled((prevState) => !prevState);
    };

    return (
        <>
            <div className={`${styles.liveMatchHeader}`}>
                <div className={`ff-mb-8 ${styles.liveMatchHeaderTop}`}>
                    <div className={`${styles.bannerAndControls}`}>
                        {showLogoAndControls && (
                            <>
                                <div className={`${styles.tournamentLogo}`}>
                                    {liveGame?.tournamentLogo ? (
                                        <img src={`${liveGame?.tournamentLogo}`} alt="image" />
                                    ) : (
                                        <FMHHighlightLabel text="Today" />
                                    )}
                                </div>
                                {isGameAvailable && (
                                    <div className={`${styles.arrows}`}>
                                        <FMHArrowButton
                                            disabled={!hasPreviousGame}
                                            direction={'left'}
                                            func={funcPrevious}
                                        />
                                        <FMHArrowButton disabled={!hasNextGame} direction={'right'} func={funcNext} />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    {isGameAvailable ? (
                        <FMHLiveScore liveGame={liveGame} />
                    ) : (
                        <FFHeading
                            className={`ff-m-0 ${styles.noMatchTodayText}`}
                            size={HeadingSizeProp.H4}
                            text="No matches scheduled today"
                        />
                    )}
                    <a
                        className={`${styles.viewMore}`}
                        href={liveGamesPageUrl ?? undefined}
                        target={isOpenNewTab ? '_blank' : ''}
                        rel={isOpenNewTab ? 'noopener noreferrer' : ''}
                    >
                        {liveGamesPageUrl && (
                            <>
                                <div className={`${styles.label}`}>View More</div>
                                <div className={`${styles.arrow}`}>
                                    <Icon icon="chevronRight" width={14} height={20} />
                                </div>
                            </>
                        )}
                    </a>
                </div>
                {liveGame && (
                    <LiveGamesHeaderBottomRow
                        isLive={liveGame?.status === 'Live'}
                        isFinished={liveGame?.status === 'Played'}
                        items={LiveGamesService.getMatchData(liveGame, includeSeasonName)}
                        documents={documents}
                    />
                )}
                {isExpandable && (
                    <button
                        disabled={!(liveGame?.gameEvents?.length !== null && liveGame?.gameEvents?.length !== 0)}
                        className={`ff-px-0 ${styles.filesDownloadButton} ${isToggled ? styles.isToggled : ''}`}
                        onClick={expandMenuButtonPressed}
                    >
                        <FFDownArrowIcon className={`${styles.chevronIcon}`} />
                    </button>
                )}
                {isToggled && <GameDetailedStatistics gameEvents={liveGame?.gameEvents} link={link} />}
            </div>
        </>
    );
};

export default FFLiveGamesHeader;
