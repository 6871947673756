import classnames from 'classnames';
import { Icon } from 'src/components/icon-generator';
import { FMHLink } from 'src/atoms/fmh-link';
import { FFImage } from 'src/atoms/ff/ff-image';
import NavItemProps from './dto';
import styles from './nav-item.module.scss';

const NavItem = (props: NavItemProps): JSX.Element => {
    const Container = (props: any): JSX.Element =>
        props.href ? <FMHLink {...props}>{props.children}</FMHLink> : <div {...props}>{props.children}</div>;

    return (
        <Container
            className={classnames(styles.root, { [styles.active]: props.isActive })}
            href={props.link}
            onClick={props.onClick}
        >
            {props.iconType && <Icon icon={props.iconType} />}
            {props.image && <FFImage {...props.image} />}
            <span>{props.label}</span>
        </Container>
    );
};

export default NavItem;
