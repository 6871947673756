import { FC } from 'react';
import { FMHCheckbox } from 'src/atoms/fmh-checkbox';
import styles from './fmh-checkbox-with-label.module.scss';
import FMHCheckboxWithLabelProps from './dto';

const FMHCheckboxWithLabel: FC<FMHCheckboxWithLabelProps> = (props: FMHCheckboxWithLabelProps): JSX.Element => {
    return (
        <div className={`${styles.container} ${props.className}`}>
            <div className={styles.checkboxDiv}>
                <FMHCheckbox onValueChange={props.onValueChange} isChecked={props.isChecked} />
            </div>
            <div className={styles.childrenDiv}>{props.children}</div>
        </div>
    );
};

export default FMHCheckboxWithLabel;
