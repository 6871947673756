import ImageStep1 from '../../icons/pwa-instructions/step-1.png';
import ImageStep2 from '../../icons/pwa-instructions/step-2.png';
import ImageStep3 from '../../icons/pwa-instructions/step-3.png';
import ImageStep4 from '../../icons/pwa-instructions/step-4.png';

import AndroidStep1 from '../../icons/pwa-instructions/android-step-1.png';
import AndroidStep2 from '../../icons/pwa-instructions/android-step-2.png';

export const PWAInstallSteps = [
    {
        stepNo: 1,
        image: ImageStep1,
        instruction: 'Navigate to media.fifa.com on safari and press the share button',
    },
    {
        stepNo: 2,
        image: ImageStep2,
        instruction: 'Select ‘Add to Home Screen’ from the popup',
    },
    {
        stepNo: 3,
        image: ImageStep3,
        instruction:
            'Click ‘Add’ in the top right corner to finish installing the application. It will now be on your home screen',
    },
    {
        stepNo: 4,
        image: ImageStep4,
        instruction: 'You can now access the Media Hub from the icon on your home screen. Setup is complete.',
    },
];

export const AndroidPWAInstallSteps = [
    {
        stepNo: 1,
        image: AndroidStep1,
        instruction: 'Navigate to media.fifa.com on Chrome and press menu burger',
    },
    {
        stepNo: 2,
        image: AndroidStep2,
        instruction: 'Select ‘Install the app’ from the menu',
    },
];
