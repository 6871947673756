import classNames from 'classnames';
import { Icon } from 'src/components/icon-generator';
import { BannerType } from '../fmh-banner/dto';
import FMHOpaqueBannerProps, { OpaqueBannerType } from './dto';
import styles from './fmh-opaque-banner.module.scss';

const getClassNameByType = (colorType: BannerType): string => {
    switch (colorType) {
        case BannerType.WARNING:
            return styles.warning;
        default:
            return styles.info;
    }
};

const getIconByType = (iconType: OpaqueBannerType): JSX.Element => {
    const commonProps = { width: 24, height: 24 };

    switch (iconType) {
        case OpaqueBannerType.Update:
            return <Icon icon="refresh" {...commonProps} />;
        default:
            return <Icon icon="info" {...commonProps} />;
    }
};

const FMHOpaqueBanner = ({
    colorType = BannerType.DEFAULT,
    iconType = OpaqueBannerType.Info,
    showIcon = false,
    children,
    overrideClassNames,
}: FMHOpaqueBannerProps) => {
    return (
        <header
            className={classNames(
                styles.banner,
                overrideClassNames?.banner,
                getClassNameByType(colorType),
                'ff-px-24',
                'ff-py-16',
                'ff-py-md-24',
            )}
        >
            {showIcon && <span className={styles.icon}>{getIconByType(iconType)}</span>}
            <p>{children}</p>
        </header>
    );
};

export default FMHOpaqueBanner;
