import classNames from 'classnames';
import Styles from './featured-carousel-skeleton.module.scss';

const FeaturedCarouselSkeleton = () => {
    return (
        <div className={classNames(Styles.fullWidthSection, 'container-lg')}>
            <div className="d-flex flex-column flex-lg-row-reverse">
                <div className={Styles.rectangle}></div>
                <div className={classNames(Styles.rectangle2, 'd-flex flex-column justify-content-lg-end ff-pl-lg-48')}>
                    <div className={Styles.title}></div>
                    <div className={Styles.title2}></div>
                    <div className={Styles.button}></div>
                </div>
            </div>
            <div className="d-none d-lg-flex ff-mt-48">
                <div className={Styles.circle}></div>
                <div className={Styles.circle}></div>
            </div>
        </div>
    );
};

export default FeaturedCarouselSkeleton;
