import { Link } from 'src/types/apiDtos';
import { Maybe } from 'src/types/maybe';

export enum BannerType {
    SUCCESS = 1,
    ERROR = 2,
    WARNING = 3,
    DEFAULT = 4,
    TRANSLATION_UNAVAILABLE = 5,
    ERROR_SECONDARY = 6,
    STATISTICS_BANNER = 7,
}

type FMHBannerProps = {
    className?: Maybe<string>;
    message: string;
    title?: Maybe<string>;
    type?: Maybe<BannerType>;
    link?: Maybe<Link>;
    isClosable?: Maybe<boolean>;
    isPopupBlockerBanner?: Maybe<boolean>;
    onBannerCloseClick?: Maybe<() => void>;
    onClickFunc?: Maybe<() => void>;
    sticky?: Maybe<boolean>;
    showWarningIcon?: Maybe<boolean>;
};

export default FMHBannerProps;
