import { Icon } from '../icon-generator';
import { IconType } from '../icon-generator/dto';
import Styles from './loading.module.scss';

const Loading = () => {
    return (
        <div className={`ff-py-24 ${Styles.loadingWrapper}`}>
            <Icon icon={IconType.Loader} />
        </div>
    );
};

export default Loading;
