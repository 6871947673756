import JSZip from 'jszip';
import FileSaver from 'file-saver';
import Promise from 'bluebird';
import axios from 'axios';
import { DownloadStatus } from 'src/molecules/fmh-download-package-button/dto';
import { Logger } from './logger';
import api from '@/utils/api';

export const downloadFromApiEndpoint = (downloadEndpoint: string): Promise => {
    return api.get(downloadEndpoint).then((res: any) => {
        if (res.data) {
            window.open(res.data);
        }
    });
};

export const downloadFromApiEndpoints = (
    downloadApiEndpoints,
    setDownloadProgress: ((val: number) => void) | undefined = undefined,
    setDownloadStatus: ((val: DownloadStatus) => void) | undefined = undefined,
    setDownloadMessage: ((val: string) => void) | undefined = undefined,
) => {
    let downloadTotal = 0;
    const downloadProgresses: Array<number> = [];
    const downloadUrls: Array<string> = [];
    const promises: Array<any> = [];
    let updateDownloadProgressInterval: NodeJS.Timeout;

    downloadApiEndpoints.map(() => {
        downloadProgresses.push(0);
    });

    const updateDownloadProgress = () => {
        let downloaded = 0;
        downloadProgresses.forEach((el) => {
            downloaded += el;
        });
        const downloadProgress = Math.round((100 * downloaded) / downloadTotal);
        if (setDownloadProgress) setDownloadProgress(downloadProgress);
        if (setDownloadMessage) setDownloadMessage(`Download Started (${downloadProgress}%)`);
    };

    const download = async (url: string) => {
        let downloadStarted = false;
        const index = downloadUrls.indexOf(url);
        const resp = await axios({
            url,
            method: 'get',
            onDownloadProgress: (p) => {
                if (!downloadStarted) {
                    downloadTotal += p.total;
                }

                downloadStarted = true;
                downloadProgresses[index] = p.loaded;
            },
            responseType: 'blob',
        });
        return resp.data;
    };
    const downloadByGroup = (urls /*, files_per_group = 5*/) => {
        return Promise.map(
            urls,
            async (url) => {
                return await download(url);
            },
            //{ concurrency: files_per_group }, Let's test without this option and see if it does cause any errors
        );
    };
    const exportZip = (blobs: Array<Blob>, filenames: Array<string>) => {
        const zip = JSZip();

        blobs.forEach((blob, i) => {
            zip.file(decodeURI(filenames[i]), blob);
        });

        zip.generateAsync({ type: 'blob' }).then((zipFile) => {
            const currentDate = new Date().getTime();
            const fileName = `MediaPackage-${currentDate}.zip`;
            if (setDownloadMessage) setDownloadMessage(`Download Complete`);
            return FileSaver.saveAs(zipFile, fileName);
        });
    };

    const downloadAndZip = (urls: Array<string>) => {
        return downloadByGroup(urls /*, 5*/).then((blobs) => {
            clearInterval(updateDownloadProgressInterval);
            if (setDownloadProgress) setDownloadProgress(100);
            if (setDownloadStatus) setDownloadStatus(DownloadStatus.Finished);
            if (setDownloadMessage) setDownloadMessage(`Zipping...`);

            const filenames = urls.map((url) => {
                const urlObject = new URL(url);
                const parts = urlObject.pathname.split('/');
                let fileName = parts[parts.length - 1];
                if (url.includes('/imagen-original-video/')) fileName += '.mp4';

                return fileName;
            });

            exportZip(blobs, filenames);
        });
    };

    downloadApiEndpoints.forEach((downloadEndpoint: string) => {
        promises.push(
            !downloadEndpoint.includes('/imagen-original-video/')
                ? api.get(downloadEndpoint).then((res: any) => {
                      if (res.data) {
                          downloadUrls.push(res.data);
                      }
                  })
                : new Promise((resolve) => {
                      setTimeout(resolve, 0);
                  }).then(() => {
                      downloadUrls.push(downloadEndpoint);
                  }),
        );
    });

    Promise.all(promises)
        .then(() => {
            updateDownloadProgressInterval = setInterval(updateDownloadProgress, 500);
            downloadAndZip(downloadUrls);
        })
        .catch((e) => {
            Logger.error(e);
            setDownloadMessage?.('Error downloading');
            setDownloadStatus?.(DownloadStatus.Error);
        });
};
