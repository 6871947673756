import { useMemo } from 'react';
import { createTheme, StyledEngineProvider, Theme, ThemeOptions, ThemeProvider } from '@mui/material';

const Provider = ({ children }) => {
    const themeOptions: ThemeOptions = useMemo(
        () => ({
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 768,
                    md: 1024,
                    lg: 1266,
                    xl: 1440,
                },
            },
            direction: 'ltr',
            mixins: {
                toolbar: {
                    minHeight: 60,
                    paddingTop: 8,
                    paddingBottom: 8,
                },
            },
            typography: {
                htmlFontSize: 16,
                fontWeightLight: 300,
                fontWeightRegular: 400,
                fontWeightMedium: 500,
                fontWeightBold: 600,
                h1: {
                    fontWeight: 600,
                    fontSize: '2.375rem',
                    lineHeight: 1.21,
                    textTransform: 'none',
                },
                h2: {
                    fontWeight: 600,
                    fontSize: '1.875rem',
                    lineHeight: 1.27,
                    textTransform: 'none',
                },
                h3: {
                    fontWeight: 600,
                    fontSize: '1.5rem',
                    lineHeight: 1.33,
                    textTransform: 'none',
                },
                h4: {
                    fontWeight: 600,
                    fontSize: '1.25rem',
                    lineHeight: 1.4,
                    textTransform: 'none',
                },
                h5: {
                    fontWeight: 600,
                    fontSize: '1rem',
                    lineHeight: 1.5,
                    textTransform: 'none',
                },
                h6: {
                    fontWeight: 400,
                    fontSize: '0.875rem',
                    lineHeight: 1.57,
                    textTransform: 'none',
                },
                caption: {
                    fontWeight: 400,
                    fontSize: '0.75rem',
                    lineHeight: 1.66,
                    textTransform: 'none',
                },
                body1: {
                    fontSize: '0.875rem',
                    lineHeight: 1.57,
                    textTransform: 'none',
                },
                body2: {
                    fontSize: '0.75rem',
                    lineHeight: 1.66,
                    textTransform: 'none',
                },
                subtitle1: {
                    fontSize: '0.875rem',
                    fontWeight: 600,
                    lineHeight: 1.57,
                    textTransform: 'none',
                },
                subtitle2: {
                    fontSize: '0.75rem',
                    fontWeight: 500,
                    lineHeight: 1.66,
                    textTransform: 'none',
                },
                overline: {
                    lineHeight: 1.66,
                },
            },
        }),
        [],
    );

    const themes: Theme = createTheme(themeOptions);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes}>{children}</ThemeProvider>
        </StyledEngineProvider>
    );
};

export default Provider;
