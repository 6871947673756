import classNames from 'classnames';
import { useEffect, useState } from 'react';
import api, { externalPortalsEndpoint } from 'src/utils/api';
import { BottomNavigationBarMobile } from 'src/organisms/bottom-navigation-bar-mobile';
import useOverlay from 'src/utils/overlay-provider';
import { FCHeader } from 'src/organisms/fmh-header';
import { FFMobileSidebarMenu } from 'src/organisms/fmh-mobile-header-menu';
import { FMHExternalPortalLink } from 'src/atoms/ff/fmh-portals-dropdown/dto';
import { isArrayInvalid } from 'src/utils/check';
import { FFImage } from 'src/atoms/ff/ff-image';
import { useLocation } from 'react-router-dom';
import { Skeleton } from '../loading-skeleton';
import { SkeletonType } from '../loading-skeleton/dto';
import { Icon } from '../icon-generator';
import { IconType } from '../icon-generator/dto';
import LayoutProps, { MenuItem } from './dto';
import Styles from './layout.module.scss';

const Layout = ({ pageConfigurationData, isAuthenticated, showHeader, headerFooterLoading, children }: LayoutProps) => {
    const [openSideMenu, setOpenSideMenu] = useState(false);
    const [portalLinks, setPortalLinks] = useState<Array<FMHExternalPortalLink>>([]);
    const [sideMenuItems, setSideMenuItems] = useState<MenuItem[]>([]);
    const [shouldSideBarIncludeLogoutBtn, setShouldSideBarIncludeLogoutBtn] = useState(false);

    const { bannerRef } = useOverlay();

    const unauthenticatedFrontPageUri = '/home';
    const authenticatedFrontPageUri =
        pageConfigurationData?.alternativeFrontPageUri ||
        pageConfigurationData?.frontPageUri ||
        unauthenticatedFrontPageUri;

    const location = useLocation();

    useEffect(() => {
        setOpenSideMenu(false);
    }, [location.pathname, location.search]);

    useEffect(() => {
        if (openSideMenu) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    }, [openSideMenu]);

    useEffect(() => {
        api.get(externalPortalsEndpoint).then((res: any) => {
            if (res.status === 200 && res.data) setPortalLinks(res.data);
        });
    }, []);

    // handler when bottom menu icon is clicked
    // open navigation side menu bar in mobile
    const handleOpenNavSideBar = () => {
        const sideNavMenuItems = pageConfigurationData?.defaultHeader.headerMenu ?? [];
        setShouldSideBarIncludeLogoutBtn(false);

        // if the same side menu items is present then this is a second click on the same items
        // so set it to close
        if (JSON.stringify(sideMenuItems) === JSON.stringify(sideNavMenuItems)) {
            setOpenSideMenu((prev) => !prev);
        } else {
            // else keep the side menu open
            // and update it with navigation menu items
            setOpenSideMenu(true);
            setSideMenuItems(pageConfigurationData?.defaultHeader.headerMenu ?? []);
        }
    };

    // handler when probile icon is clicked
    // open profile menu side bar in mobile
    const handleMobileProfileClick = () => {
        setShouldSideBarIncludeLogoutBtn(true);
        const profileMenuItems: MenuItem[] = [
            { title: 'View profile', uri: '/profile', id: 'view-profile' },
            { title: 'View subscriptions', uri: '/subscriptions', id: 'view-subscriptions' },
        ];

        const menuItems: MenuItem[] = [];
        if (pageConfigurationData && !isArrayInvalid(pageConfigurationData.menuItemGroups)) {
            pageConfigurationData.menuItemGroups?.forEach((group) => {
                if (isArrayInvalid(group.items)) return;

                menuItems.push({
                    title: group.label,
                    id: group.label,
                    children: group.items?.map((item) => ({
                        title: item.label,
                        id: item.label,
                        uri: item.linkTo.uri,
                        icon: item.icon ? (
                            <FFImage
                                src={item.icon.src}
                                alt={item.icon.alt}
                                title={item.icon.title}
                                height={item.icon.height}
                                width={item.icon.width}
                                aspectRatioHeight="1"
                                aspectRatioWidth="1"
                            />
                        ) : (
                            <Icon icon={IconType.FIFALogo} className={Styles.icon} />
                        ),
                    })),
                });
            });
        }

        if (menuItems.length !== 0) {
            profileMenuItems.splice(2, 0, ...menuItems);
        }

        if (portalLinks.length !== 0) {
            profileMenuItems.splice(2, 0, {
                title: 'Switch site',
                id: 'switch-site',
                children: portalLinks.map((item, index) => ({
                    title: item.channelName,
                    uri: item.portalUrl,
                    id: `switch-site-#-${index}`,
                })),
            });
        }

        // if the same side menu items is present then this is a second click on the same items
        // so set it to close
        if (JSON.stringify(sideMenuItems) === JSON.stringify(profileMenuItems)) {
            setOpenSideMenu((prev) => !prev);
        } else {
            // else keep the side menu open
            // and update it with profile menu items
            setOpenSideMenu(true);
            setSideMenuItems(profileMenuItems);
        }
    };

    return (
        <>
            {headerFooterLoading && showHeader && <Skeleton variant={SkeletonType.Header} />}

            {!headerFooterLoading && pageConfigurationData && showHeader && (
                <FCHeader
                    firstLvlMenu={pageConfigurationData.defaultHeader.headerMenu}
                    logInHoldingScreenLink={pageConfigurationData.logInHoldingScreenLink}
                    search={pageConfigurationData.search}
                    homePageUri={
                        isAuthenticated
                            ? authenticatedFrontPageUri
                            : pageConfigurationData.frontPageUri ?? unauthenticatedFrontPageUri
                    }
                    closeSideMenu={() => setOpenSideMenu(false)}
                    classNames={classNames(openSideMenu && Styles.fixedHeader)}
                    handleMobileProfileClick={handleMobileProfileClick}
                />
            )}

            <div ref={bannerRef}></div>

            {children}

            {headerFooterLoading ? (
                <Skeleton variant={SkeletonType.BottomNavigationBarMobile} />
            ) : (
                showHeader && (
                    <div className="d-block d-lg-none">
                        <BottomNavigationBarMobile
                            quickNavigationLinks={pageConfigurationData?.quickNavigationLinks ?? []}
                            openSideMenu={openSideMenu}
                            setOpenSideMenu={setOpenSideMenu}
                            handleOpenSideMenu={handleOpenNavSideBar}
                        />
                    </div>
                )
            )}

            {openSideMenu && (
                <FFMobileSidebarMenu
                    firstLvlMenu={sideMenuItems}
                    closeMenu={() => setOpenSideMenu(false)}
                    shouldIncludeLogoutBtn={shouldSideBarIncludeLogoutBtn}
                />
            )}
        </>
    );
};

export default Layout;
