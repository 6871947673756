import api from 'src/utils/api';
import LanguageFunction from 'src/utils/languageCode';

const getSearchSuggestions = async (searchString: string, isFifacomSuggestionEnabled: boolean): Promise<string[]> => {
    const locale = LanguageFunction.getLocalStorageLocale();
    const res = await api.get('/v1/search/suggestion', {
        params: { searchString, locale, contentTypes: isFifacomSuggestionEnabled ? 'fifacom' : '' },
    });
    return res.data;
};

const getSearchResults = async (
    path: string,
    searchString: string,
    skip: number,
    range?: string,
    sort?: string,
    contentTypes?: string,
): Promise<any> => {
    const locale = LanguageFunction.getLocalStorageLocale();

    const res = await api.get(
        `${path}&searchString=${searchString ?? ''}&locale=${locale}&skip=${skip}${range}${sort}${contentTypes}`,
    );

    return res.data;
};

const SearchService = {
    getSearchResults,
    getSearchSuggestions,
};

export default SearchService;
