import classNames from 'classnames';
import Styles from './bottom-nav-bar-mobile-skeleton.module.scss';

const BottomNavBarMobileSkeleton = () => {
    return (
        <div className={classNames(Styles.wrapper, 'd-flex d-lg-none')}>
            <div className="d-flex flex-row align-items-center justify-content-around container">
                {Array.from(Array(3)).map((_, i) => (
                    <span className={Styles.circle} key={i}></span>
                ))}
            </div>
        </div>
    );
};

export default BottomNavBarMobileSkeleton;
