/* eslint-disable no-console */

import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from '../appInsights';

const log = (message: string, severityLevel: SeverityLevel, details?: { [key: string]: any }) => {
    const shouldLogToAppInsights = process.env.NODE_ENV && process.env.NODE_ENV === 'production';

    if (shouldLogToAppInsights) {
        appInsights?.trackTrace(
            {
                message,
                severityLevel,
            },
            details,
        );
    } else {
        switch (severityLevel) {
            case SeverityLevel.Verbose:
                console.trace(message, details);
                break;
            case SeverityLevel.Information:
                console.info(message, details);
                break;
            case SeverityLevel.Warning:
                console.warn(message, details);
                break;
            case SeverityLevel.Error:
                console.error(message, details);
                break;
            case SeverityLevel.Critical:
                console.error(message, details);
        }
    }
};

const verbose = (message: string, details?: { [key: string]: any }) => {
    log(message, SeverityLevel.Verbose, details);
};

const info = (message: string, details?: { [key: string]: any }) => {
    log(message, SeverityLevel.Information, details);
};

const warn = (message: string, details?: { [key: string]: any }) => {
    log(message, SeverityLevel.Warning, details);
};

const error = (message: string, details?: { [key: string]: any }) => {
    log(message, SeverityLevel.Error, details);
};

const critical = (message: string, details?: { [key: string]: any }) => {
    log(message, SeverityLevel.Critical, details);
};

export const Logger = {
    verbose,
    info,
    warn,
    error,
    critical,
};
