import type { Dictionary } from 'src/types/dictionary';
import type { QueryParameter } from 'src/types/query-parameters';

export const objectToQuerystring = <T>(obj: Dictionary<T>): string => {
    const doEncode = (val: string | number | boolean): string => {
        return encodeURIComponent(val);
    };
    return Object.keys(obj).reduce((str, key) => {
        const tmpValue = obj[String(key)];
        if (!tmpValue) {
            return str;
        }

        key = doEncode(key);
        const delimiter = str.length === 0 ? '?' : '&';
        if (Array.isArray(tmpValue)) {
            const keyAndVal = tmpValue.map((v, i) => `${i === 0 ? delimiter : '&'}${key}=${doEncode(v)}`).join('');
            return `${str}${keyAndVal}`;
        } else if (typeof tmpValue === 'string' || typeof tmpValue === 'number' || typeof tmpValue === 'boolean') {
            const val = doEncode(tmpValue);
            return `${str}${delimiter}${key}=${val}`;
        } else {
            return str;
        }
    }, '');
};

export const querystringToObject = (query: string): Dictionary<QueryParameter> => {
    return query.split('&').reduce((obj, current) => {
        const [key, value] = decodeURIComponent(current).split('=');
        if (!value || !key) {
            return obj;
        }
        const prevValue = obj[String(key)];
        if (prevValue) {
            //if value is an array then add key, if not then set to array and add key
            const keyAsArray: Array<string> = Array.isArray(prevValue) ? prevValue : [prevValue];

            keyAsArray.push(value);
            obj[String(key)] = keyAsArray;
        } else {
            obj[String(key)] = value;
        }
        return obj;
    }, {} as Dictionary<QueryParameter>);
};

export const getUrlAndQuery = (
    url: string,
): { asPath: string; query: Dictionary<QueryParameter>; queryStr: string } => {
    const queryIndexStart = url.indexOf('?');
    const asPath = queryIndexStart === -1 ? url : url.slice(0, queryIndexStart);
    const querySlice = url.slice(queryIndexStart + 1);

    const query = querystringToObject(querySlice);
    const queryStr = query.q ? (query.q as string).trim() : '';

    return { asPath, query, queryStr };
};

export const joinPath = (...args: Array<string>): string => args.join('/').replace(/\/+/g, '/');
