import { format, parseISO } from 'date-fns';
import ar from 'date-fns/esm/locale/ar-SA';

const defaultLanguage: Language = { id: 'en', text: 'English', shortText: 'EN' };
const localeLocalStorageKey = 'locale';
const articleLocalStorageKey = 'articleEntry';

export type Language = {
    id: string;
    text: string;
    shortText: string;
    localisedSlug?: string;
};

const setLocalStorageLocale = (value: string): void => {
    if (getLocalStorageLocale() !== value) {
        window.localStorage.setItem(localeLocalStorageKey, value);
    }
};

/**
 * @deprecated It uses local storage for storing state information, please use the current page locale
 */
const getLocalStorageLocale = (): string => {
    return window.localStorage.getItem(localeLocalStorageKey) ?? defaultLanguage.id;
};

const getLanguageCode = (path: string): string => {
    const splitPath: string[] = path?.slice(1)?.split(`/`);
    if (!splitPath) return '';
    const code = splitPath[0];
    if (code?.length === 2) {
        return code;
    }
    return '';
};

const getCorrectApiPageUrl = (path: string, localeOverride?: string): string => {
    const langCode = getLanguageCode(path);
    const isQuestionMarkInPath = path.includes('?');
    let urlParameterSeparator = '?';
    if (isQuestionMarkInPath) urlParameterSeparator = '&';

    const localizedPath = () => {
        if (langCode) {
            if (localeOverride) return `${path.slice(3)}${urlParameterSeparator}locale=${localeOverride}`;
            return `${path.slice(3)}${urlParameterSeparator}locale=${langCode}`;
        } else if (localeOverride) {
            return `${path}${urlParameterSeparator}locale=${localeOverride}`;
        } else if (window.localStorage.getItem(localeLocalStorageKey)) {
            return `${path}${urlParameterSeparator}locale=${window.localStorage.getItem(localeLocalStorageKey)}`;
        }
        return `${path}${urlParameterSeparator}locale=${defaultLanguage.id}`;
    };

    const result = localizedPath();

    return result;
};

const removeArticleLocalStorageKey = (): void => {
    window.localStorage.removeItem(articleLocalStorageKey);
};

const storeArticleEntryId = (value: string): void => {
    window.localStorage.setItem(articleLocalStorageKey, value);
};

const formatDate = (value: string, localeOverride: string | undefined | null = undefined): string => {
    const date = parseISO(value);
    const locale: string = localeOverride ?? getLocalStorageLocale();
    try {
        if (locale === 'ar') return format(date, 'd MMMM yyyy, HH:mm (x)', { locale: ar });
        return format(date, 'd MMM yyyy, HH:mm (x)');
    } catch (error) {
        return value;
    }
};

const LanguageFunction = {
    defaultLanguage,
    getLanguageCode,
    getCorrectApiPageUrl,
    setLocalStorageLocale,
    getLocalStorageLocale,
    removeArticleLocalStorageKey,
    storeArticleEntryId,
    formatDate,
};

export default LanguageFunction;
