import { FC, SyntheticEvent } from 'react';
import classNames from 'classnames';
import styles from './ff-figure.module.scss';
import FFFigureProps from './dto';
import { ImageTransformProps } from '@/utils/ff/image-transform';
import { BynderImageTransform, isSourceTransformable, useBynderOnload } from '@/utils/ff/bynder-transform';

/**
 *
 * @param FFFigureProps
 * @returns JSX.Element
 *
 * Example usage <FFFigure src="https://someImage.png" title="Some title" alt="Some alternate text" aspect-ratio-width="16" aspect-ratio-height="9" />
 */

/*
const getLocale = (locale: string): string => {
    const currentLanguageLabel = IMAGE_FALLBACK_ALT.find((el) => el.languageCode === locale)?.text ?? '';
    return currentLanguageLabel;
};
*/

const handleImageLoadBySize = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    const imgElement = event.currentTarget as HTMLImageElement;
    if (imgElement.naturalWidth === imgElement.naturalHeight) {
        imgElement.style.padding = '0 60px';
        imgElement.style.backgroundColor = '#00182F';
    }
};

const FFFigure: FC<FFFigureProps> = (props: FFFigureProps): JSX.Element | null => {
    const { isVerticalImage, handleImageLoad } = useBynderOnload(props.src);

    const handleLoad = (event: SyntheticEvent<HTMLImageElement, Event>) => {
        handleImageLoad(event);
        if (props.isVideoCard) {
            handleImageLoadBySize(event);
        }
    };

    // Default aspect ratio of 16-by-9 if not specified in props
    let aspectRatioWidth: number | null = null;
    let aspectRatioHeight: number | null = null;
    if (
        props.aspectRatioWidth &&
        props.aspectRatioWidth !== '' &&
        props.aspectRatioHeight &&
        props.aspectRatioHeight !== ''
    ) {
        // Parse to intergers
        aspectRatioWidth = parseInt(props.aspectRatioWidth);
        aspectRatioHeight = parseInt(props.aspectRatioHeight);
    }

    // Method to call imageTransform, which return new image url from image transformation service with the specified width and quality
    const src = (width, quality) => {
        if (props.skipProcessingService) return props.src;

        const imageTransformOptions: ImageTransformProps = {
            imgSrc: props.src,
            imageWidth: width,
            quality,
            cropMode: props.cropMode ?? 'fill',
        };

        if (aspectRatioWidth && aspectRatioHeight) {
            imageTransformOptions['aspectRatioWidth'] = aspectRatioWidth;
            imageTransformOptions['aspectRatioHeight'] = aspectRatioHeight;
        }
        if ('focalPosX' && 'focalPosY' in props) {
            imageTransformOptions['focalPosX'] = props.focalPosX;
            imageTransformOptions['focalPosY'] = props.focalPosY;
        } else if ('focalAI' in props) {
            imageTransformOptions['focalAI'] = props.focalAI;
        }
        if (props.colorize && props.colorize.hex && props.colorize.opacity) {
            imageTransformOptions.colorize = props.colorize;
        }

        return BynderImageTransform(imageTransformOptions);
    };

    // Picture element with five difference sources depending on device viewport (based on grid system breakpoints)

    const imageSizesPx = [320, 576, 768, 992, 1200, 1400];

    const handleOnClick = (e) => {
        if (props.onClick != null) {
            props.onClick(e);
        }
    };

    const aspectRatio = !isSourceTransformable(props.src) ? `${aspectRatioWidth}/${aspectRatioHeight}` : '';

    const picture = (
        <picture className={styles.picture}>
            {props.sizes ? (
                imageSizesPx
                    // Sort to ensure wider breakpoints are on top to achieve the best media query match:
                    .sort((s1, s2) => s2 - s1)
                    .map((imageSizePx) => (
                        <source
                            key={`w-${imageSizePx}`}
                            media={`(min-width: ${imageSizePx}px)`}
                            srcSet={src(imageSizePx, 'best')}
                        />
                    ))
            ) : (
                <>
                    <source media="(min-width: 1400px)" srcSet={src('2048', 'best')} />
                    <source media="(min-width: 1200px)" srcSet={src('1400', 'best')} />
                    <source media="(min-width: 992px)" srcSet={src('1200', 'best')} />
                    <source media="(min-width: 768px)" srcSet={src('992', 'auto')} />
                    <source media="(min-width: 576px)" srcSet={src('768', 'auto')} />
                    <source media="(min-width: 320px)" srcSet={src('576', 'eco')} />
                </>
            )}
            <img
                className={classNames(styles.img, isVerticalImage ? styles.verticalImage : styles.horizontalImage)}
                decoding="async"
                loading="lazy"
                src={src('50', 'low')}
                alt={props.alt}
                title={props.title}
                width="100%"
                height="100%"
                onClick={handleOnClick}
                onLoad={handleLoad}
                style={{ aspectRatio }}
            />
        </picture>
    );

    // Return JSX element either as <figure> with <figcaption> inside or not depending on props.
    return (
        <div className={`${styles.ffFigure} ${props.className ? props.className : ''}`}>
            {props.caption ? (
                <figure className={styles.figure}>
                    {picture}
                    {props.caption && (
                        <figcaption
                            className={`ff-py-8 ff-px-16 ff-m-0 caption ff-text-grey-slate ${props.captionClassName}`}
                        >
                            {props.caption}
                        </figcaption>
                    )}
                </figure>
            ) : (
                picture
            )}
        </div>
    );
};

export default FFFigure;
