import { FMHCheckboxWithLabel } from 'src/molecules/fmh-checkbox-with-label';
import { BytesToMegaBytes } from 'src/utils/converters';
import { Icon } from 'src/components/icon-generator';
import { IconType } from 'src/components/icon-generator/dto';
import { Tooltip } from '@mui/material';
import { usePDFViewerContext } from 'src/utils/contexts/pdfViewerContext';
import styles from './fmh-download-checkboxes.module.scss';
import FMHDownloadCheckboxesProps from './dto';

const FMHDownloadCheckboxes = (props: FMHDownloadCheckboxesProps): JSX.Element | null => {
    const pdfViewerContext = usePDFViewerContext();

    const handleOnViewPDFClick = (fileUrl: string | undefined, title: string) => {
        if (!fileUrl) {
            return;
        }

        pdfViewerContext.setPreviewFile(fileUrl, title);
    };

    return props.items.length > 0 ? (
        <>
            <p className={styles.groupTitle}>{props.title}</p>

            {props.items.map(
                (item, index) =>
                    item.downloadEndpoint && (
                        <div className="d-flex flex-row justify-content-between align-items-center ff-my-8" key={index}>
                            <FMHCheckboxWithLabel
                                isChecked={props.checkboxSelections[index]}
                                onValueChange={(val) => props.onCheck(val, index, item)}
                                className={styles.downloadOption}
                            >
                                <div className={styles.downloadTitle}>{item.title}</div>
                                {props.showFileSizes && (
                                    <div className={styles.downloadSize}>{`${BytesToMegaBytes(
                                        Number(item.fileSizeInBytes),
                                    )} MB`}</div>
                                )}
                            </FMHCheckboxWithLabel>
                            {item.pdfPreviewFileUrl && (
                                <Tooltip title="View">
                                    <div
                                        className={styles.viewButton}
                                        onClick={() => handleOnViewPDFClick(item.pdfPreviewFileUrl, item.title)}
                                    >
                                        <Icon icon={IconType.View} className={styles.viewIcon} />
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    ),
            )}
            <hr className={`${styles.divider}`} />
        </>
    ) : null;
};

export default FMHDownloadCheckboxes;
