import { FC } from 'react';
import { FMHLink } from 'src/atoms/fmh-link';
import styles from './ff-mobile-header-menu-third-level-item.module.scss';

type thirdLevelProps = {
    title: string;
    uri?: string;
    active?: boolean;
    closeMenu: any;
};

const FFMobileHeaderMenuThirdLevelItem: FC<thirdLevelProps> = (props: thirdLevelProps): JSX.Element => {
    return (
        <div className={'ff-mb-0 ff-px-32 ff-bg-white'}>
            <FMHLink href={props.uri} onClick={() => props.closeMenu()}>
                <p className={styles.thirdLevelTitle}>{props.title}</p>
            </FMHLink>
        </div>
    );
};

export default FFMobileHeaderMenuThirdLevelItem;
