import { ReactEventHandler, useCallback, useState } from 'react';
import { ImageTransformProps } from './image-transform';

export const isSourceTransformable = (src: string): boolean => {
    return src?.split('/')?.includes('transform');
};

export const BynderImageTransform = ({
    imgSrc,
    cropMode,
    aspectRatioWidth,
    aspectRatioHeight,
    imageWidth,
    imageHeight,
    quality,
    colorize,
}: ImageTransformProps): string => {
    // Query string to image transformation service
    let query = '';

    // Specify crop mode, or use fill
    if (cropMode) {
        query += `io=transform:${cropMode}`;
    } else {
        query += 'io=transform:fill';
    }

    // Calculate aspect ratio value parsable by image transformation service if both aspect ratio width & height is provided
    if (aspectRatioWidth && aspectRatioHeight && aspectRatioHeight >= 1 && aspectRatioWidth >= 1) {
        query += `,aspectratio:${aspectRatioWidth}x${aspectRatioHeight}`;
        if (imageHeight && imageWidth && imageWidth / imageHeight > aspectRatioWidth / aspectRatioHeight) {
            query += `,width:${imageWidth - 360}`;
        }
    } else {
        // Specify height and width if presented
        if (imageWidth) {
            query += `,width:${imageWidth}`;
        }
        if (imageHeight) {
            query += `,height:${imageHeight}`;
        }
    }

    switch (quality) {
        case 'best':
            query += `&quality=100`;
            break;
        case 'eco':
            query += `&quality=60`;
            break;
        case 'low':
            query += `&quality=30`;
            break;
        case 'auto':
        default:
            break;
    }

    // Specify colorize if presented
    if (colorize && colorize.hex && colorize.opacity) {
        query += `&io=overlay:box,color:${colorize.hex},opacity:${colorize.opacity}`;
    }

    // If imgSrc already has query params (indicated by "?"), use "&" to add more
    if (imgSrc && imgSrc.includes('?')) {
        const imgSrcSplit = imgSrc.split('?');
        return `${imgSrcSplit[0]}?${query}&${imgSrcSplit[1]}`;
    }
    // Return new image uri using the image src and query paramaters from props.
    return `${imgSrc}?${query}`;
};

export function useBynderOnload(src: string) {
    const [isVerticalImage, setIsVerticalImage] = useState(false);

    const handleImageLoad: ReactEventHandler<HTMLImageElement> = useCallback(
        (event) => {
            if (isSourceTransformable(src)) {
                return;
            }

            if (!event.currentTarget) {
                return;
            }

            if (event.currentTarget.naturalHeight > event.currentTarget.naturalWidth) {
                setIsVerticalImage(true);
            }
        },
        [src],
    );

    return {
        isVerticalImage,
        handleImageLoad,
    };
}
