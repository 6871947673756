type FFLogoProps = {
    src: string | undefined;
    alt?: string | undefined;
    logoType?: LogoType;
    className?: string;
};

export enum LogoType {
    Team = 1,
    Competition = 2,
}

export default FFLogoProps;
