import { FC } from 'react';
import { ReactComponent as SeekForward } from 'src/icons/video-icons/SeekForward.svg';
import { ReactComponent as SeekBackward } from 'src/icons/video-icons/SeekBackward.svg';
import IconProps, { IconType } from './dto';
import styles from './icon-generator.module.scss';
import { ReactComponent as ChevronUp } from '@/icons/ff-chevron/ff-chevron-up.svg';
import { ReactComponent as ChevronDown } from '@/icons/ff-chevron/ff-chevron-down.svg';
import { ReactComponent as ChevronLeft } from '@/icons/ff-chevron/ff-chevron-left.svg';
import { ReactComponent as ChevronRight } from '@/icons/ff-chevron/ff-chevron-right.svg';
import { ReactComponent as Check } from '@/icons/ff-check.svg';
import { ReactComponent as Grid } from '@/icons/ff-grid-view.svg';
import { ReactComponent as Burger } from '@/icons/Burger-menu.svg';
import { ReactComponent as Logout } from '@/icons/ff-logout-icon.svg';
import { ReactComponent as User } from '@/icons/User.svg';
import { ReactComponent as Divider } from '@/icons/Divider.svg';
import { ReactComponent as World } from '@/icons/WorldIcon.svg';
import { ReactComponent as Loader } from '@/icons/fmh-loader.svg';
import { ReactComponent as FAQDevelopment } from '@/icons/fc-faq/fc-development.svg';
import { ReactComponent as FAQFifa } from '@/icons/fc-faq/fc-fifa.svg';
import { ReactComponent as FAQFootball } from '@/icons/fc-faq/fc-football.svg';
import { ReactComponent as FAQLegal } from '@/icons/fc-faq/fc-legal.svg';
import { ReactComponent as FAQMember } from '@/icons/fc-faq/fc-member.svg';
import { ReactComponent as FAQOrdering } from '@/icons/fc-faq/fc-ordering.svg';
import { ReactComponent as FAQOther } from '@/icons/fc-faq/fc-other.svg';
import { ReactComponent as FAQPitch } from '@/icons/fc-faq/fc-pitch.svg';
import { ReactComponent as FAQTicketing } from '@/icons/fc-faq/fc-ticketing.svg';
import { ReactComponent as FAQTv } from '@/icons/fc-faq/fc-tv.svg';
import { ReactComponent as Play } from '@/icons/video-icons/PlayButton.svg';
import { ReactComponent as Pause } from '@/icons/video-icons/PauseButton.svg';
import { ReactComponent as Pdf } from '@/icons/ff-pdf-icon.svg';
import { ReactComponent as Plus } from '@/icons/PlusIcon.svg';
import { ReactComponent as Minus } from '@/icons/MinusIcon.svg';
import { ReactComponent as FMHLogo } from '@/icons/FMH-logo.svg';
import { ReactComponent as FMHLogoShort } from '@/icons/ff-logo/fmh-logo-short.svg';
import { ReactComponent as Cross } from '@/icons/Cross.svg';
import { ReactComponent as FfCross } from '@/icons/ff-cross.svg';
import { ReactComponent as Bell } from '@/icons/bell.svg';
import { ReactComponent as BellInverted } from '@/icons/bell-inverted.svg';
import { ReactComponent as FIFALogo } from '@/icons/ff-logo/fc-logo-simple.svg';
import { ReactComponent as ExternalLink } from '@/icons/fmh-external-link.svg';
import { ReactComponent as Word } from '@/icons/fmh-documents/fmh-word.svg';
import { ReactComponent as Excel } from '@/icons/fmh-documents/fmh-excel.svg';
import { ReactComponent as Ellipse } from '@/icons/fmh-ellipse.svg';
import { ReactComponent as Twitter } from '@/icons/fmh-social-media/fmh-twitter.svg';
import { ReactComponent as Facebook } from '@/icons/fmh-social-media/fmh-facebook.svg';
import { ReactComponent as Youtube } from '@/icons/fmh-social-media/fmh-youtube.svg';
import { ReactComponent as LinkedIn } from '@/icons/fmh-social-media/fmh-linkedin.svg';
import { ReactComponent as Instagram } from '@/icons/fmh-social-media/fmh-instagram.svg';
import { ReactComponent as Calendar } from '@/icons/ff-calendar.svg';
import { ReactComponent as ArrowLeft } from '@/icons/fmh-arrow-left.svg';
import { ReactComponent as Attention } from '@/icons/fmh-attention-icon.svg';
import { ReactComponent as AttentionInverted } from '@/icons/fmh-attention-inverted-icon.svg';
import { ReactComponent as Sort } from '@/icons/ff-sort.svg';
import { ReactComponent as Search } from '@/icons/ff-search.svg';
import { ReactComponent as BusScheduleAvgTime } from '@/icons/bus-schedule/bus-schedule-avg-time.svg';
import { ReactComponent as BusScheduleAvgTimeMobile } from '@/icons/bus-schedule/bus-schedule-avg-time-mobile.svg';
import { ReactComponent as LocationOrigin } from '@/icons/bus-schedule/location-original.svg';
import { ReactComponent as LocationDestination } from '@/icons/bus-schedule/location-destination.svg';
import { ReactComponent as ThreeDotVertical } from '@/icons/bus-schedule/three-dot-vertical.svg';
import { ReactComponent as BusScheduleCalendar } from '@/icons/bus-schedule/calendar.svg';
import { ReactComponent as Filter } from '@/icons/ff-filter.svg';
import { ReactComponent as MediaPackage } from '@/icons/fmh-media-package.svg';
import { ReactComponent as CalendarBlue } from '@/icons/calendar-blue.svg';
import { ReactComponent as Bus } from '@/icons/bus-schedule/bus-icon.svg';
import { ReactComponent as DottedLine } from '@/icons/bus-schedule/dotted-line.svg';
import { ReactComponent as Clock } from '@/icons/clock.svg';
import { ReactComponent as Walking } from '@/icons/bus-schedule/walking.svg';
import { ReactComponent as RouteIconsVertical } from '@/icons/bus-schedule/route-icons-vertical.svg';
import { ReactComponent as Zoom } from '@/icons/fmh-zoom.svg';
import { ReactComponent as Live } from '@/icons/live-games-statistics/live.svg';
import { ReactComponent as Newsroom } from '@/icons/newsroom.svg';
import { ReactComponent as Ball } from '@/icons/live-games-statistics/ball.svg';
import { ReactComponent as RedCard } from '@/icons/live-games-statistics/red-card.svg';
import { ReactComponent as YellowCard } from '@/icons/live-games-statistics/yellow-card.svg';
import { ReactComponent as SubstitutionOn } from '@/icons/live-games-statistics/substitution-on.svg';
import { ReactComponent as SubstitutionOff } from '@/icons/live-games-statistics/substitution-off.svg';
import { ReactComponent as RankingArrowUp } from '@/icons/live-games-statistics/ranking-arrow-up.svg';
import { ReactComponent as RankingArrowDown } from '@/icons/live-games-statistics/ranking-arrow-down.svg';
import { ReactComponent as Info } from '@/icons/fmh-info.svg';
import { ReactComponent as Refresh } from '@/icons/fmh-refresh.svg';
import { ReactComponent as AudioWave } from '@/icons/fmh-audio-wave.svg';
import { ReactComponent as AudioBackground } from '@/icons/fmh-audio-background.svg';
import { ReactComponent as Accreditation } from '@/icons/fmh-accreditation.svg';
import { ReactComponent as BottomNavBus } from '@/icons/bottom-nav-bar/bus.svg';
import { ReactComponent as BottomNavCalendar } from '@/icons/bottom-nav-bar/calendar.svg';
import { ReactComponent as BottomNavHamburger } from '@/icons/bottom-nav-bar/hamburger.svg';
import { ReactComponent as BottomNavHome } from '@/icons/bottom-nav-bar/home.svg';
import { ReactComponent as BottomNavTicket } from '@/icons/bottom-nav-bar/ticket.svg';
import { ReactComponent as BottomNavTrophy } from '@/icons/bottom-nav-bar/trophy.svg';
import { ReactComponent as WhereToWatchIndicator } from '@/icons/where-to-watch-indicator.svg';
import { ReactComponent as Star } from '@/icons/star.svg';
import { ReactComponent as View } from '@/icons/fmh-view-icon.svg';
import { ReactComponent as MapPin } from '@/icons/map-pin.svg';
import { ReactComponent as ContactAvatarPlaceholder } from '@/icons/Avatar-placeholder-small.svg';
import { ReactComponent as XOutlined } from '@/icons/x-outlined.svg';
import { ReactComponent as InstagramOutlined } from '@/icons/instagram-outlined.svg';
import { ReactComponent as YoutubeFilled } from '@/icons/youtube-filled.svg';
import { ReactComponent as TiktokOutlined } from '@/icons/tiktok-outlined.svg';
import { ReactComponent as LinkedinSquareFilled } from '@/icons/linkedin-square-filled.svg';
import { ReactComponent as Email } from '@/icons/email.svg';
import { ReactComponent as Phone } from '@/icons/phone.svg';

const Icon: FC<IconProps> = (props: IconProps): JSX.Element => {
    switch (props.icon) {
        case IconType.ChevronUp:
            return <ChevronUp {...props} />;
        case IconType.ChevronDown:
            return <ChevronDown {...props} />;
        case IconType.ChevronLeft:
            return <ChevronLeft {...props} />;
        case IconType.ChevronRight:
            return <ChevronRight {...props} />;
        case IconType.Check:
            return <Check {...props} />;
        case IconType.Grid:
            return <Grid {...props} />;
        case IconType.Burger:
            return <Burger {...props} />;
        case IconType.Logout:
            return <Logout {...props} />;
        case IconType.User:
            return <User {...props} />;
        case IconType.World:
            return <World {...props} />;
        case IconType.Divider:
            return <Divider {...props} />;
        case IconType.Loader:
            return <Loader {...props} />;
        case IconType.FAQDevelopment:
            return <FAQDevelopment {...props} />;
        case IconType.FAQFifa:
            return <FAQFifa {...props} />;
        case IconType.FAQFootball:
            return <FAQFootball {...props} />;
        case IconType.FAQLegal:
            return <FAQLegal {...props} />;
        case IconType.FAQMember:
            return <FAQMember {...props} />;
        case IconType.FAQOrdering:
            return <FAQOrdering {...props} />;
        case IconType.FAQOther:
            return <FAQOther {...props} />;
        case IconType.FAQPitch:
            return <FAQPitch {...props} />;
        case IconType.FAQTicketing:
            return <FAQTicketing {...props} />;
        case IconType.FAQTv:
            return <FAQTv {...props} />;
        case IconType.Play:
            return <Play {...props} />;
        case IconType.Pause:
            return <Pause {...props} />;
        case IconType.Pdf:
            return <Pdf {...props} />;
        case IconType.Plus:
            return <Plus {...props} />;
        case IconType.Minus:
            return <Minus {...props} />;
        case IconType.FMHLogo:
            return <FMHLogo {...props} />;
        case IconType.FMHLogoShort:
            return <FMHLogoShort {...props} />;
        case IconType.Cross:
            return <Cross {...props} />;
        case IconType.FfCross:
            return <FfCross {...props} />;
        case IconType.Bell:
            return <Bell {...props} />;
        case IconType.BellInverted:
            return <BellInverted {...props} />;
        case IconType.FIFALogo:
            return <FIFALogo {...props} />;
        case IconType.ExternalLink:
            return <ExternalLink {...props} />;
        case IconType.Word:
            return <Word {...props} />;
        case IconType.Excel:
            return <Excel {...props} />;
        case IconType.Ellipse:
            return <Ellipse {...props} />;
        case IconType.Twitter:
            return <Twitter {...props} />;
        case IconType.Facebook:
            return <Facebook {...props} />;
        case IconType.Youtube:
            return <Youtube {...props} />;
        case IconType.LinkedIn:
            return <LinkedIn {...props} />;
        case IconType.Instagram:
            return <Instagram {...props} />;
        case IconType.SeekBackward:
            return <SeekBackward {...props} />;
        case IconType.SeekForward:
            return <SeekForward {...props} />;
        case IconType.ArrowRight:
            return <ArrowLeft {...props} className={`${styles.rotateY180} ${props.className}`} />;
        case IconType.ArrowLeft:
            return <ArrowLeft {...props} />;
        case IconType.Attention:
            return <Attention {...props} />;
        case IconType.AttentionInverted:
            return <AttentionInverted {...props} />;
        case IconType.Calendar:
            return <Calendar {...props} />;
        case IconType.Sort:
            return <Sort {...props} />;
        case IconType.Search:
            return <Search {...props} />;
        case IconType.BusScheduleAvgTime:
            return <BusScheduleAvgTime {...props} />;
        case IconType.BusScheduleAvgTimeMobile:
            return <BusScheduleAvgTimeMobile {...props} />;
        case IconType.LocationOrigin:
            return <LocationOrigin {...props} />;
        case IconType.LocationDestination:
            return <LocationDestination {...props} />;
        case IconType.ThreeDotVertical:
            return <ThreeDotVertical {...props} />;
        case IconType.BusScheduleCalendar:
            return <BusScheduleCalendar {...props} />;
        case IconType.Filter:
            return <Filter {...props} />;
        case IconType.MediaPackage:
            return <MediaPackage {...props} />;
        case IconType.CalendarBlue:
            return <CalendarBlue {...props} />;
        case IconType.Bus:
            return <Bus {...props} />;
        case IconType.DottedLine:
            return <DottedLine {...props} />;
        case IconType.Clock:
            return <Clock {...props} />;
        case IconType.Walking:
            return <Walking {...props} />;
        case IconType.RouteIconsVertical:
            return <RouteIconsVertical {...props} />;
        case IconType.Zoom:
            return <Zoom {...props} />;
        case IconType.Live:
            return <Live {...props} />;
        case IconType.Newsroom:
            return <Newsroom {...props} />;
        case IconType.Ball:
            return <Ball {...props} />;
        case IconType.RedCard:
            return <RedCard {...props} />;
        case IconType.YellowCard:
            return <YellowCard {...props} />;
        case IconType.SubstitutionOn:
            return <SubstitutionOn {...props} />;
        case IconType.SubstitutionOff:
            return <SubstitutionOff {...props} />;
        case IconType.RankingArrowUp:
            return <RankingArrowUp {...props} />;
        case IconType.RankingArrowDown:
            return <RankingArrowDown {...props} />;
        case IconType.Info:
            return <Info {...props} />;
        case IconType.Refresh:
            return <Refresh {...props} />;
        case IconType.AudioWave:
            return <AudioWave {...props} />;
        case IconType.AudioBackground:
            return <AudioBackground {...props} />;
        case IconType.Accreditation:
            return <Accreditation {...props} />;
        case IconType.BottomNavBus:
            return <BottomNavBus {...props} />;
        case IconType.BottomNavCalendar:
            return <BottomNavCalendar {...props} />;
        case IconType.BottomNavHamburger:
            return <BottomNavHamburger {...props} />;
        case IconType.BottomNavHome:
            return <BottomNavHome {...props} />;
        case IconType.BottomNavTicket:
            return <BottomNavTicket {...props} />;
        case IconType.BottomNavTrophy:
            return <BottomNavTrophy {...props} />;
        case IconType.WhereToWatchIndicator:
            return <WhereToWatchIndicator {...props} />;
        case IconType.Star:
            return <Star {...props} />;
        case IconType.View:
            return <View {...props} />;
        case IconType.MapPin:
            return <MapPin {...props} />;
        case IconType.ContactAvatarPlaceholder:
            return <ContactAvatarPlaceholder {...props} />;
        case IconType.InstagramOutlined:
            return <InstagramOutlined {...props} />;
        case IconType.LinkedInSquareFilled:
            return <LinkedinSquareFilled {...props} />;
        case IconType.TiktokOutlined:
            return <TiktokOutlined {...props} />;
        case IconType.XOutlined:
            return <XOutlined {...props} />;
        case IconType.YoutubeFilled:
            return <YoutubeFilled {...props} />;
        case IconType.Email:
            return <Email {...props} />;
        case IconType.Phone:
            return <Phone {...props} />;
        default:
            return <></>;
    }
};

export default Icon;
