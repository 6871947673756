import { FC } from 'react';
import { Icon } from 'src/components/icon-generator';
import { IconType } from 'src/components/icon-generator/dto';
import Clock from 'react-live-clock';
import styles from './fmh-media-screen-header.module.scss';
import { FFImageProps } from '@/atoms/ff/ff-image';
import { FFFigure } from '@/atoms/ff/ff-figure';
import { Maybe } from '@/types/maybe';

export type FMHMediaScreenHeaderProps = {
    logo: Maybe<FFImageProps>;
    displayClock: boolean;
    timezone: Maybe<string>;
};
const FMHMediaScreenHeader: FC<FMHMediaScreenHeaderProps> = (props: FMHMediaScreenHeaderProps): JSX.Element => {
    return (
        <header className={`${styles.header}`}>
            <div>
                <div className={styles.clockContainer}>
                    {props.displayClock && (
                        <Clock
                            format={'HH:mm'}
                            ticking={true}
                            timezone={props.timezone ?? undefined}
                            className={styles.clock}
                        />
                    )}
                </div>
                <Icon icon={IconType.FMHLogo} />
                <div className={styles.logoContainer}>
                    {props.logo && <FFFigure className={styles.logo} {...props.logo} caption={undefined} />}
                </div>
            </div>
        </header>
    );
};

export default FMHMediaScreenHeader;
