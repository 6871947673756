import classNames from 'classnames';
import { FC } from 'react';
import { FMHLink } from 'src/atoms/fmh-link';
import { Icon } from 'src/components/icon-generator';
import { IconType } from 'src/components/icon-generator/dto';
import { SecondLevelMenu } from '../dto';
import styles from './fc-header-nav-link-group.module.scss';
import { FFHeading, HeadingSizeProp } from '@/atoms/ff-heading';

type headerProps = {
    toggledClassName: string;
    secondLevelMenuItem: SecondLevelMenu;
    handleClick: (event, index) => void;
    handleClose: (event, index) => void;
    title: string;
    isActive: boolean;
    index: number;
    className?: string;
};

const FCNavLinkGroup: FC<headerProps> = (props: headerProps): JSX.Element => {
    const menuItemList = props.secondLevelMenuItem.children.map((menuItem, index) => (
        <li key={index} className={styles.linkLayout}>
            <FMHLink
                href={menuItem.uri}
                className={styles.linkStyle}
                onClick={(e) => props.handleClose(e, props.index)}
            >
                {menuItem.title}
            </FMHLink>
        </li>
    ));

    if (props.secondLevelMenuItem.uri) {
        return (
            <FMHLink
                href={props.secondLevelMenuItem.uri}
                className={classNames(styles.menuItem, styles.link, 'ff-mb-0')}
                onClick={(e) => props.handleClose(e, props.index)}
            >
                <div className={classNames(styles.subHeader, props.className)}>{props.secondLevelMenuItem.title}</div>
            </FMHLink>
        );
    }

    return (
        <>
            <div
                className={classNames(styles.subHeader, props.className, props.isActive && styles.open)}
                onClick={(e) => {
                    props.handleClick(e, props.index);
                }}
            >
                <div
                    className={classNames(
                        'd-flex justify-content-between align-items-center',
                        props.isActive && styles.open,
                    )}
                >
                    <FFHeading
                        className={classNames('ff-mb-0', styles.menuItem)}
                        text={props.secondLevelMenuItem.title}
                        size={HeadingSizeProp.H6}
                    />
                    <Icon
                        icon={IconType.ChevronUp}
                        className={classNames(styles.icon, props.isActive && styles.open)}
                    />
                </div>
            </div>
            {props.isActive && <ul>{menuItemList}</ul>}
        </>
    );
};

export default FCNavLinkGroup;
