import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { FFButton } from 'src/atoms/ff/ff-button';
import { ButtonType } from 'src/atoms/ff/ff-button/dto';
import axios from 'axios';
import { Logger } from 'src/utils/logger';
import { FMHOpaqueBanner } from 'src/organisms/fmh-opaque-banner';
import PDFViewerProps from './dto';
import Styles from './pdf-viewer.module.scss';

const PDFViewer = ({ open, divId, fileUrl, fileName, hasError, onClose }: PDFViewerProps) => {
    const downloadInBrowser = () => {
        axios
            .get(fileUrl, {
                responseType: 'blob',
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${fileName}.pdf`);
                link.click();
            })
            .catch((error) => {
                Logger.error(`An error occur while trying to download ${fileUrl}: ${error}`);
                window.open(fileUrl);
            });
    };

    return (
        <Dialog open={open} scroll={'paper'} PaperProps={{ sx: { height: '90dvh' } }} fullWidth onClose={onClose}>
            <DialogContent dividers={true}>
                {hasError ? (
                    <div className={Styles.errorBanner}>
                        <FMHOpaqueBanner showIcon>
                            <p className="ff-mb-4">File cannot be opened.</p>
                            <p className="ff-mb-0">You can still download the file to view offline.</p>
                        </FMHOpaqueBanner>
                    </div>
                ) : (
                    <div id={divId} key={divId}></div>
                )}
            </DialogContent>
            <DialogActions>
                <FFButton text="Download" func={downloadInBrowser} />
                <FFButton text="Close" kind={ButtonType.Secondary} func={onClose} />
            </DialogActions>
        </Dialog>
    );
};

export default PDFViewer;
