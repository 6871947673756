import classNames from 'classnames';
import { FC } from 'react';
import { FFSearchBar } from 'src/molecules/ff/ff-search-bar';
import { Icon } from 'src/components/icon-generator';
import styles from './fc-search-section.module.scss';
import { FCSearchSectionProps } from '.';

const FCSearchSection: FC<FCSearchSectionProps> = ({
    searchBarProps,
    isSearchActive,
    setSearchActive,
}: FCSearchSectionProps): JSX.Element => {
    const closeSearch = () => {
        if (isSearchActive) {
            setSearchActive(false);
        }
    };

    return (
        <div
            className={classNames(
                'ff-bg-blue-cinema container-fluid',
                styles.container,
                isSearchActive ? 'd-block' : 'd-none',
            )}
        >
            <div className={styles.closeWrapper}>
                <div className={styles.close} onClick={closeSearch}>
                    <Icon icon="cross" />
                </div>
            </div>
            <div className="row ff-p-16 ff-pb-48 ff-pt-96 ff-p-md-96">
                <div className="col-12">
                    <FFSearchBar {...searchBarProps} />
                </div>
            </div>
        </div>
    );
};

export default FCSearchSection;
