import { FC } from 'react';
import FMHDetailedStatisticsPlayerProps from './dto';
import styles from './fmh-detailed-statistics-player.module.scss';
import { ReactComponent as Ball } from '@/icons/live-games-statistics/ball.svg';
import { ReactComponent as YellowCard } from '@/icons/live-games-statistics/yellow-card.svg';
import { ReactComponent as RedCard } from '@/icons/live-games-statistics/red-card.svg';
import { ReactComponent as SubstitutionOn } from '@/icons/live-games-statistics/substitution-on.svg';
import { ReactComponent as SubstitutionOff } from '@/icons/live-games-statistics/substitution-off.svg';

const FMHDetailedStatisticsPlayer: FC<FMHDetailedStatisticsPlayerProps> = (props: FMHDetailedStatisticsPlayerProps) => {
    return (
        <>
            <li className={`${styles.event}`} key={props.index}>
                <span className={`${styles.firstColumn}`}>
                    <span className={`${styles.element}`}>
                        {(props.eventType === 'Goal' || props.eventType === 'Booking') &&
                            props.teamType === 'HomeTeam' &&
                            props.playerName}
                        {props.eventType === 'Substitution' &&
                            props.teamType === 'HomeTeam' &&
                            `${props.playerOffName}`}
                        <span>{props.teamType === 'HomeTeam' && props.minute}</span>
                    </span>
                </span>
                <span>
                    {props.eventType === 'Goal' && <Ball />}
                    {props.eventType === 'Booking' && props.card === 'Yellow' && <YellowCard />}
                    {props.eventType === 'Booking' && (props.card === 'Red' || props.card === 'DoubleYellow') && (
                        <RedCard />
                    )}
                    {props.eventType === 'Substitution' && props.teamType === 'HomeTeam' && <SubstitutionOff />}
                    {props.eventType === 'Substitution' && props.teamType === 'AwayTeam' && <SubstitutionOn />}
                </span>
                <span className={`${styles.thirdColumn}`}>
                    <span className={`${styles.element}`}>
                        {(props.eventType === 'Goal' || props.eventType === 'Booking') &&
                            props.teamType === 'AwayTeam' &&
                            props.playerName}
                        {props.eventType === 'Substitution' &&
                            props.teamType === 'AwayTeam' &&
                            `${props.playerOffName}`}
                        <span>{props.teamType === 'AwayTeam' && props.minute}</span>
                    </span>
                </span>
            </li>
            {props.eventType === 'Substitution' && (
                <li className={`${styles.event}`} key={props.index}>
                    <span className={`${styles.firstColumn}`}>
                        <span className={`${styles.element}`}>
                            <span>{props.teamType === 'HomeTeam' && `${props.playerOnName}`}</span>
                            <span>{props.teamType === 'HomeTeam' && props.minute}</span>
                        </span>
                    </span>
                    <span>
                        {props.eventType === 'Substitution' && props.teamType === 'HomeTeam' && <SubstitutionOn />}
                        {props.eventType === 'Substitution' && props.teamType === 'AwayTeam' && <SubstitutionOff />}
                    </span>
                    <span className={`${styles.thirdColumn}`}>
                        <span className={`${styles.element}`}>
                            <span>{props.teamType === 'AwayTeam' && `${props.playerOnName}`}</span>
                            <span>{props.teamType === 'AwayTeam' && props.minute}</span>
                        </span>
                    </span>
                </li>
            )}
        </>
    );
};

export default FMHDetailedStatisticsPlayer;
