import { FC } from 'react';
import { FMHNotificationBanner } from 'src/molecules/fmh-notification-banner';
import { FMHPopupBlockerBanner } from 'src/molecules/fmh-popup-blocker-banner';
import FMHBannerProps from './dto';

export const FMHBanner: FC<FMHBannerProps> = (props: FMHBannerProps): JSX.Element => {
    if (props.isPopupBlockerBanner) {
        return <FMHPopupBlockerBanner {...props} />;
    } else {
        return <FMHNotificationBanner {...props} />;
    }
};
export default FMHBanner;
