import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// eslint-disable-next-line import/no-named-as-default-member
i18n.use(initReactI18next).init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en: {
            translation: {
                calendar: {
                    more: 'more',
                    moreHint: 'Show {{count}} more events',
                    today: 'Today',
                    appointmentType: 'Appointment type',
                    to: 'to',
                    eventStatus: {
                        label: 'Event status',
                        new: 'New',
                        toBeConfirmed: 'To be confirmed',
                        updated: 'Updated',
                        tentative: 'Tentative',
                        confirmed: 'Confirmed',
                        postponed: 'Postponed',
                        canceled: 'Canceled',
                    },
                    noticeMessage:
                        'Notice: Every effort is made to ensure that the information presented in the calendar is correct and up to date. Teams sometimes alter their plans so times and/or locations are subject to change without notice',
                    events: 'Events',
                    teams: 'Teams',
                    venues: 'Venues',
                    allday: 'All Day',
                },
            },
        },
        ar: {
            translation: {
                calendar: {
                    more: 'المزيد',
                    moreHint: 'عرض {{count}} أحداث أخرى',
                    today: 'اليوم',
                    appointmentType: 'نوع الموعد',
                    to: 'إلى',
                    eventStatus: {
                        label: 'حالة الحدث',
                        new: 'جديد',
                        toBeConfirmed: 'سيتم تأكيدها',
                        updated: 'محدث',
                        tentative: 'لم تتأكد بعد',
                        confirmed: 'غير متأكد',
                        postponed: 'مؤجل',
                        canceled: 'ملغى',
                    },
                    noticeMessage:
                        'تنبيه: يتم بذل كل الجهود للتأكد من أن المعلومات المقدمة في التقويم صحيحة ودائما محدثة. أحيانا الفرق تغير خططها وبالتالي من الإمكان تغيير المواعيد و/أو المواقع دون إشعار. ',
                    events: 'الأحداث',
                    teams: 'فرق',
                    venues: 'الأماكن',
                    allday: 'طوال اليوم',
                },
            },
        },
    },
});

export default i18n;
