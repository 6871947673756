export const ApplicationName = 'Mediahub';

export const QueryParameterNames = {
    ReturnUrl: 'returnUrl',
    Message: 'message',
};

export const LogoutActions = {
    LogoutCallback: 'logout-callback',
    Logout: 'logout',
    LoggedOut: 'logged-out',
};

export const LoginActions = {
    Login: 'login',
    LoginCallback: 'login-callback',
    LoginFailed: 'login-failed',
    Profile: 'profile',
    Register: 'register',
};

const prefix = '/authentication';

const apiAuthorizationClientConfigurationUrl = process.env.REACT_APP_AUTHORIZATION_CONFIGURATION_URL;
const apiLogoutUrl = process.env.REACT_APP_LOGOUT_URL;

export const ApplicationPaths = {
    DefaultLoginRedirectPath: '/',
    ApiAuthorizationClientConfigurationUrl: apiAuthorizationClientConfigurationUrl,
    ApiLogoutUrl: apiLogoutUrl,
    ApiAuthorizationPrefix: prefix,
    Login: `${prefix}/${LoginActions.Login}`,
    LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,
    LoginCallback: `${prefix}/${LoginActions.LoginCallback}`,
    Register: `https://extranets.fifa.com/en/registration/mediahub`,
    Profile: `https://extranets.fifa.com/en/Utils1/My-Tools/settings1`,
    LogOut: `${prefix}/${LogoutActions.Logout}`,
    LoggedOut: `${prefix}/${LogoutActions.LoggedOut}`,
    LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
    IdentityRegisterPath: 'Identity/Account/Register',
    IdentityManagePath: 'Identity/Account/Manage',
};

export const DEFAULT_USER_MANAGEMENT_SETTINGS = {
    authority: process.env.REACT_APP_AUTHORIZATION_CONFIGURATION_AUTHORITY,
    client_id: process.env.REACT_APP_AUTHORIZATION_CONFIGURATION_CLIENT_ID,
    post_logout_redirect_uri: process.env.REACT_APP_AUTHORIZATION_CONFIGURATION_POST_LOGOUT_REDIRECT_URL,
    redirect_uri: process.env.REACT_APP_AUTHORIZATION_CONFIGURATION_REDIRECT_URL,
    response_type: process.env.REACT_APP_AUTHORIZATION_CONFIGURATION_RESPONSE_TYPE,
    scope: process.env.REACT_APP_AUTHORIZATION_CONFIGURATION_SCOPE,
    automaticSilentRenew: true,
    includeIdTokenInSilentRenew: true,
    loadUserInfo: false,
};
