/* eslint-disable max-len */
export const META_FALLBACK_URL = 'https://localhost:3000';
export const META_FALLBACK_TITLE = 'FIFA MediaHub';
export const IMAGE_FALLBACK_URL =
    'https://digitalhub.fifa.com/m/605981858ebaad97/webimage-129802200HC016_FIFA_Executi.png';
export const META_FALLBACK_DESCRIPTION =
    'The official site of the international governing body of football with news, national associations, competitions, results, fixtures, development, organisation, world rankings, statistics, the International Football Association Board, history, laws of the game, futsal, publications, downloads, and contact details.';

export const DEFAULT_FDCP_API_URL = 'https://api.fifa.com/api/v3';

export const IMAGE_PROCESSING_SERVICE_URL = `https://digitalhub.fifa.com`;
export const IMAGE_FALLBACK_ALT = [
    {
        languageCode: 'en',
        text: 'Image not found',
    },
    {
        languageCode: 'es',
        text: 'Foto no encontrada',
    },
    {
        languageCode: 'fr',
        text: 'image non trouvée',
    },
    {
        languageCode: 'de',
        text: 'Bild nicht gefunden',
    },
];

export const DEFAULT_OVERLAY_COLOR = '000f2c';
export const DEFAULT_OVERLAY_OPACITY = 15;

export const ARAB_CUP_PAGE_ADDRESS = '/tournaments/mens/arabcup2021';

export enum FIFAColor {
    FIFADarkBlue,
    FIFALighterBlue,
    FIFASnowGrey,
    FIFALightestGrey,
    FIFAYellow,
}

export enum DocumentSortOption {
    Alphabetical,
    PublicationDate,
    DescendingAlphabetical,
}

export const DocumentSortOptionLabels = {
    [DocumentSortOption.Alphabetical]: 'A-Z',
    [DocumentSortOption.DescendingAlphabetical]: 'Z-A',
};

export const MAIN_DIV_ID = 'main-body';

export enum MediaCardType {
    Video = 'Video',
    Audio = 'Audio',
    Image = 'Image',
    Logo = 'Logo',
}

export const DEFAULT_START_DATE = '1904/01/01';

export const LIVE_GAME_HEADER_V2_FLAG = 'LiveGamesHeader';

export const SEASON_ID_PARAM_KEY = 'season';

export const FIXTURE_RESULTS_URL = '/fixture-results';
