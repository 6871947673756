import { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants';
import authService from './AuthorizeService';

type AuthorizeRouteProps = {
    path: string;
    component: any;
};

type AuthorizeRouteState = {
    ready: boolean;
    authenticated: boolean;
};

export default class AuthorizeRoute extends Component<AuthorizeRouteProps, AuthorizeRouteState> {
    private _subscription?: number;
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            authenticated: false,
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    render() {
        const { ready, authenticated } = this.state;
        const link = document.createElement('a');
        link.href = this.props.path;
        const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
        const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(
            returnUrl,
        )}`;
        if (!ready) {
            return <div />;
        } else {
            const { component: Component, ...rest } = this.props;
            return (
                <Route
                    {...rest}
                    render={(props) => {
                        if (authenticated) {
                            return <Component {...props} />;
                        } else {
                            return <Redirect to={redirectUrl} />;
                        }
                    }}
                />
            );
        }
    }

    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        this.setState({ ready: true, authenticated });
    }

    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false });
        await this.populateAuthenticationState();
    }
}
