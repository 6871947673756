import { FC, useContext, useEffect, useState } from 'react';
import api from 'src/utils/api';
import { ConfigurationContext } from 'src/App';
import { Icon } from 'src/components/icon-generator';
import { IconType } from 'src/components/icon-generator/dto';
import { isArrayInvalid } from 'src/utils/check';
import { FFImage } from '../ff-image';
import FMHPortalsDropdownProps, { FMHExternalPortalLink } from './dto';
import { FFDropdown } from '@/atoms/ff-dropdown';
import DropdownStyle from '@/atoms/ff-dropdown/ff-dropdown.module.scss';
import { DropdownEntry, OptionGroup } from '@/atoms/ff-dropdown/dto';

const FMHPortalsDropdown: FC<FMHPortalsDropdownProps> = (props: FMHPortalsDropdownProps): JSX.Element | null => {
    const [portalLinks, setPortalLinks] = useState<Array<FMHExternalPortalLink>>([]);
    const [optionGroups, setOptionGroups] = useState<Array<OptionGroup>>([]);
    const { pageConfigurationData } = useContext(ConfigurationContext);

    useEffect(() => {
        api.get(`${props.src}`).then((res: any) => {
            if (res.status === 200 && res.data) setPortalLinks(res.data);
        });
    }, [props.src]);

    useEffect(() => {
        const externalPortalsOption: Array<OptionGroup> = [];
        const externalPortals: Array<DropdownEntry> = portalLinks.map((p) => ({
            text: p.channelName,
            uri: p.portalUrl,
        }));

        if (externalPortals.length > 0)
            externalPortalsOption.push({
                label: 'Switch Site',
                options: externalPortals,
            });

        let menuItemGroups: Array<OptionGroup> = [];
        if (pageConfigurationData && pageConfigurationData.menuItemGroups) {
            const validMenuItemGroups = pageConfigurationData.menuItemGroups.filter(
                (menuItemGroup) => !isArrayInvalid(menuItemGroup.items),
            );

            menuItemGroups = validMenuItemGroups.map((menuItemGroup) => {
                const options: Array<DropdownEntry> = menuItemGroup.items!.map((item) => ({
                    text: item.label,
                    uri: item.linkTo?.uri,
                    icon: item.icon ? (
                        <FFImage
                            src={item.icon.src}
                            alt={item.icon.alt}
                            title={item.icon.title}
                            height={item.icon.height}
                            width={item.icon.width}
                            aspectRatioHeight="1"
                            aspectRatioWidth="1"
                        />
                    ) : (
                        <Icon icon={IconType.FIFALogo} className={DropdownStyle.icon} />
                    ),
                }));

                return {
                    label: menuItemGroup.label,
                    options,
                };
            });
        }

        setOptionGroups([...externalPortalsOption, ...menuItemGroups]);
    }, [portalLinks, pageConfigurationData]);

    const viewProfileLink = { text: 'View profile', uri: '/profile' };
    const viewSubscriptionsLink = { text: 'View subscriptions', uri: '/subscriptions' };
    const logoutLink = { text: 'Log out', uri: props.logoutPath, id: '0' };

    return (
        <FFDropdown
            optionGroups={optionGroups}
            profileLink={viewProfileLink}
            subscriptionsLink={viewSubscriptionsLink}
            logoutLink={logoutLink}
            selected={props.profileImage}
            includeArrow={false}
            preText={props.userName}
        />
    );
};

export default FMHPortalsDropdown;
