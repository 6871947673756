import { ReactNode } from 'react';
import { BannerType } from '../fmh-banner/dto';

export enum OpaqueBannerType {
    Info,
    Update,
}

type FMHOpaqueBannerProps = {
    children: ReactNode;
    colorType?: BannerType;
    iconType?: OpaqueBannerType;
    showIcon?: boolean;
    overrideClassNames?: {
        banner?: string;
    };
};

export default FMHOpaqueBannerProps;
