import classNames from 'classnames';
import Styles from './full-width-card-section-skeleton.module.scss';

const FullWidthCardSectionSkeleton = () => {
    return (
        <div className={Styles.fullWidthSection}>
            <div
                className={classNames(
                    Styles.wrapper,
                    'container d-flex align-items-start justify-content-center flex-column',
                )}
            >
                <div className={Styles.rectangle}></div>
                <div className={Styles.rectangle2}></div>
                <div className={Styles.button}></div>
            </div>
        </div>
    );
};

export default FullWidthCardSectionSkeleton;
