import { FC } from 'react';
import { FMHLink } from 'src/atoms/fmh-link';
import { Icon } from 'src/components/icon-generator';
import { IconType } from 'src/components/icon-generator/dto';
import styles from './fmh-arrow-button.module.scss';
import FMHArrowButtonProps from './dto';

const FMHArrowButton: FC<FMHArrowButtonProps> = (props: FMHArrowButtonProps): JSX.Element => {
    const getIconType = (): IconType => {
        return props.direction === 'left' ? IconType.ChevronLeft : IconType.ChevronRight;
    };

    const buttonElement = props.link ? (
        <FMHLink
            href={props.link}
            onClick={props.func ?? undefined}
            className={`${styles.button} ${props.className} ${props.disabled ? styles.disabled : ''}`}
        >
            <span aria-hidden="true">
                <Icon icon={getIconType()} />
            </span>
        </FMHLink>
    ) : (
        <button
            disabled={props.disabled ?? false}
            onClick={props.func ?? undefined}
            className={`${styles.button} ${props.className} ${props.disabled ? styles.disabled : ''}`}
        >
            <span aria-hidden="true">
                <Icon icon={getIconType()} />
            </span>
        </button>
    );

    return buttonElement;
};

export default FMHArrowButton;
