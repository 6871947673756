import { FC, useState } from 'react';
import classNames from 'classnames';
import { FMHLink } from 'src/atoms/fmh-link';
import { Icon } from 'src/components/icon-generator';
import { IconType } from 'src/components/icon-generator/dto';
import { MenuItem } from 'src/components/layout/dto';
import styles from './ff-mobile-header-menu-first-level-item.module.scss';
import { FFMobileHeaderMenuSecondLevelItem } from '@/molecules/ff-mobile-header-menu-second-level-item';

type firstLevelProps = {
    title: string;
    uri?: string;
    active?: boolean;
    menuItems?: Array<MenuItem>;
    parentItem: MenuItem;
    hasActiveChild?: boolean;
    closeMenu: any;
    trailingIcon?: JSX.Element;
};

const FFMobileHeaderMenuFirstLevelItem: FC<firstLevelProps> = (props: firstLevelProps): JSX.Element => {
    const [openSecondLevel, setOpenSecondLevel] = useState(false);
    const [activeChildIndex, setActiveChildIndex] = useState(-1);

    const mobileMenu = props.menuItems?.map((item, index) => {
        return (
            <li key={item.id || `second-level-item-${index}`} id={item.id || `second-level-item-${index}`}>
                <FFMobileHeaderMenuSecondLevelItem
                    title={item.title}
                    uri={item.uri}
                    open={item.isOpen}
                    active={index === activeChildIndex}
                    parentItem={item}
                    menuItems={item.children}
                    hasActiveChild={item.hasActiveChild}
                    icon={item.icon}
                    click={() => (index === activeChildIndex ? setActiveChildIndex(-1) : setActiveChildIndex(index))}
                    closeMenu={props.closeMenu}
                />
            </li>
        );
    });

    return (
        <>
            <div className={classNames(styles.row, 'ff-px-24 ff-py-16')} role="button">
                {props.uri ? (
                    <FMHLink
                        href={props.uri}
                        onClick={() => props.closeMenu()}
                        className="d-flex justify-content-between align-items-center ff-mb-0"
                    >
                        <p className={styles.firstLevelTitle}>{props.title}</p>
                        {props.trailingIcon}
                    </FMHLink>
                ) : (
                    <div
                        onClick={() => setOpenSecondLevel(true)}
                        className="d-flex justify-content-between align-items-center"
                    >
                        <p className={styles.firstLevelTitle}>{props.title}</p>
                        {props.menuItems?.length !== 0 && <Icon icon={IconType.ChevronRight} className={styles.icon} />}
                    </div>
                )}
            </div>
            {openSecondLevel && (
                <div className={styles.firstLevelOverlay}>
                    <div
                        className={classNames(styles.firstLevelTitleContainer, 'd-flex align-items-center ff-p-16')}
                        onClick={() => setOpenSecondLevel(false)}
                        role="button"
                    >
                        <Icon icon={IconType.ChevronLeft} className={styles.icon} />
                        <p className={classNames(styles.firstLevelTitle)}>{props.title}</p>
                    </div>
                    <ul>{mobileMenu}</ul>
                </div>
            )}
        </>
    );
};

export default FFMobileHeaderMenuFirstLevelItem;
