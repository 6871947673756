import classNames from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import FFNavItem from '../fc-header-nav-item/fc-header-nav-item';
import { FirstLevelMenu } from '../dto';
import styles from './fc-header-menu.module.scss';

type headerProps = {
    toggledClassName: string;
    firstLvlMenu: Array<FirstLevelMenu>;
};

const FCMenu: FC<headerProps> = (props: headerProps): JSX.Element => {
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const navRef = useRef<HTMLElement>(null);

    const location = useLocation();

    useEffect(() => {
        setOpenBackdrop(false);
    }, [location.pathname, location.search]);

    const handleOpenBackdrop = () => {
        setOpenBackdrop((prev) => {
            if (!prev) {
                document.body.style.overflow = 'hidden';
                document.body.style.paddingRight = '15px';
            } else {
                document.body.style.overflow = '';
                document.body.style.paddingRight = '';
            }
            return !prev;
        });
    };

    const itemList = props.firstLvlMenu.map((item, index) => (
        <FFNavItem key={index} firstLvlMenuItem={item} handleOpenBackdrop={handleOpenBackdrop} />
    ));

    return (
        <>
            <nav className={classNames(props.toggledClassName, styles.menuDiv)} ref={navRef}>
                <div className={classNames('ff-px-32 ff-px-md-0 container')}>
                    <ul className="d-flex align-items-center justify-content-center">{itemList}</ul>
                </div>
            </nav>
            {openBackdrop && (
                <div className={styles.backdrop} style={{ top: navRef.current?.getBoundingClientRect().bottom }}></div>
            )}
        </>
    );
};

export default FCMenu;
