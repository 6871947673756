import classNames from 'classnames';
import Styles from './highlight-section-skeleton.module.scss';

export enum HighlightSectionSkeletonSize {
    EXPANDED = 0,
    COMPRESS = 1,
}

const HighlightSectionSkeleton = ({ size = HighlightSectionSkeletonSize.EXPANDED }) => {
    return (
        <section className="ff-section">
            <div className={classNames(Styles.wrapper, 'container')}>
                <div className="row">
                    <div className="col-lg-3">
                        <div className={classNames(Styles.rectangle, 'ff-mt-0 ff-mb-16')}></div>
                        <div className={classNames(Styles.rectangle2, 'd-none d-lg-block')}></div>
                    </div>

                    <div className="col-lg-9">
                        <div className={classNames('', Styles.grid)}>
                            <div className={classNames(Styles.square)}></div>
                            <div className={classNames(Styles.square)}></div>
                            <div className={classNames(Styles.square2)}></div>
                            <div className={classNames(Styles.square2, 'd-none d-md-block')}></div>
                            <div className={classNames(Styles.square2, 'd-none d-md-block')}></div>
                        </div>
                        {size === HighlightSectionSkeletonSize.EXPANDED && (
                            <>
                                <div className={classNames(Styles.rectangle3, 'ff-my-32')}></div>
                                <div className={classNames('', Styles.grid2)}>
                                    <div className={classNames(Styles.square)}></div>
                                    <div className={classNames(Styles.square)}></div>
                                    <div className={classNames(Styles.square)}></div>
                                    <div className={classNames(Styles.square)}></div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HighlightSectionSkeleton;
