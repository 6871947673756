import { FC, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { isLinkInternal } from 'src/utils/check';
import FMHLinkProps from './dto';

export const checkIfExternalLink = (url: string | undefined): boolean => {
    if (!url) return false;
    if (url.startsWith('/')) return false;
    try {
        return new URL(url).origin !== location.origin;
    } catch {
        return true;
    }
};

const FMHLink: FC<FMHLinkProps> = forwardRef<HTMLAnchorElement, FMHLinkProps>(
    ({ children, openInNewTab, previousPageTitle, search, ...props }, ref): JSX.Element => {
        const httpOrHttps = new RegExp('^(http|https|mailto)');

        const isLinkHashLink = (url: string): boolean => {
            return url.includes('#');
        };

        if (!props.href)
            return (
                <a {...props} href={undefined}>
                    {children}
                </a>
            );

        return (
            <>
                {isLinkHashLink(props.href) ? (
                    <HashLink
                        {...props}
                        ref={ref}
                        className={props.className}
                        onClick={props.onClick}
                        title={props.title}
                        to={props.href}
                    >
                        {children}
                    </HashLink>
                ) : isLinkInternal(props.href) ? (
                    <Link
                        {...props}
                        ref={ref}
                        className={props.className}
                        to={{
                            pathname: props.href,
                            search,
                            state: { previousPageTitle },
                        }}
                        onClick={props.onClick}
                        title={props.title}
                        target={openInNewTab ? '_blank' : ''}
                        rel={openInNewTab ? 'noopener noreferrer' : ''}
                    >
                        {children}
                    </Link>
                ) : checkIfExternalLink(props.href) ? (
                    httpOrHttps.test(props.href ?? '') ? (
                        <a {...props} ref={ref} rel={`${props.rel ?? ''}`.trim()} target="_blank">
                            {children}
                        </a>
                    ) : (
                        <>
                            {props.href?.includes('@') && (
                                <a
                                    {...props}
                                    ref={ref}
                                    href={`mailto:${props.href}`}
                                    rel={`${props.rel ?? ''} noreferrer`.trim()}
                                    target={`_blank`}
                                >
                                    {children}
                                </a>
                            )}
                        </>
                    )
                ) : (
                    <a {...props} ref={ref}>
                        {children}
                    </a>
                )}
            </>
        );
    },
);

export default FMHLink;
