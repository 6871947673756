import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Icon } from 'src/components/icon-generator';
import { IconType } from 'src/components/icon-generator/dto';
import DropdownAccordionItemProps from './dto';
import Style from './ff-dropdown-accordion-item.module.scss';

function FFDropdownAccordionItem(props: DropdownAccordionItemProps) {
    const detailsElRef = useRef<HTMLDetailsElement>(null);

    useEffect(() => {
        if (!props.isOpen && detailsElRef.current) {
            detailsElRef.current.removeAttribute('open');
        }
    }, [props.isOpen]);

    return (
        <details className={props.classNames} ref={detailsElRef} onClick={props.onClick} key={props.label}>
            <summary className={props.overrideClassNames?.summaryTitle}>
                <span className={classNames(Style.dropupContentButton, props.overrideClassNames?.dropupContentButton)}>
                    {props.label}
                </span>
                <div className={classNames(Style.summaryChevron, props.iconProps?.rotate180deg && Style.rotate180deg)}>
                    <Icon icon={props.icon ?? IconType.ChevronRight} stroke={props.iconProps?.stroke} />
                </div>
            </summary>

            <div
                className={classNames(Style.summaryContent, props.overrideClassNames?.summaryContent)}
                data-options
                key={props.label}
            >
                {props.options}
            </div>
        </details>
    );
}

export default React.memo(FFDropdownAccordionItem);
