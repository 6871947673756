import React, { createContext, useContext, useMemo, useState } from 'react';

interface ArticleContextType {
    articleName?: string;
    setArticleName: React.Dispatch<React.SetStateAction<string | undefined>>;
    isNews?: boolean;
    setIsNews: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    relativeUrl?: string;
    setRelativeUrl: React.Dispatch<React.SetStateAction<string | undefined>>;
}
const ArticleContext: React.Context<ArticleContextType> = createContext({} as ArticleContextType);

// ArticleDetailsProvider to wrap around our main App.tsx component
export const ArticleDetailsProvider = ({ children }) => {
    const [articleName, setArticleName] = useState<string>();
    const [isNews, setIsNews] = useState<boolean>();
    const [relativeUrl, setRelativeUrl] = useState<string>();

    // Using React Memo, to ensure that the ArticleDetailsProvider only update when the overlay state changes. Otherwise the whole tree under the ArticleDetailsProvider will re-render children at every state and value change
    const articleDetails = useMemo(
        () => ({
            articleName,
            setArticleName,
            isNews,
            setIsNews,
            relativeUrl,
            setRelativeUrl,
        }),
        [articleName, relativeUrl, isNews],
    );

    return <ArticleContext.Provider value={articleDetails}>{children}</ArticleContext.Provider>;
};

// useArticleDetails is a context helper function to be used inside the components, that need information about overlay overlay
const useArticleDetails = () => {
    return useContext(ArticleContext);
};
export default useArticleDetails;
