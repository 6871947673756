export const enum IconType {
    ArrowRight = 'arrowRight',
    ArrowLeft = 'arrowLeft',
    ChevronUp = 'chevronUp',
    ChevronDown = 'chevronDown',
    ChevronRight = 'chevronRight',
    ChevronLeft = 'chevronLeft',
    Check = 'check',
    Grid = 'grid',
    Burger = 'burger',
    Logout = 'logout',
    User = 'user',
    World = 'world',
    Divider = 'divider',
    Loader = 'loader',
    FAQFifa = 'faqFifa',
    FAQTicketing = 'faqTicketing',
    FAQLegal = 'faqLegal',
    FAQOrdering = 'faqOrdering',
    FAQDevelopment = 'faqDevelopment',
    FAQMember = 'faqMember',
    FAQFootball = 'faqFootball',
    FAQPitch = 'faqPitch',
    FAQTv = 'faqTv',
    FAQOther = 'faqOther',
    Play = 'play',
    Pause = 'pause',
    Pdf = 'pdf',
    Plus = 'plus',
    Minus = 'minus',
    FMHLogo = 'fmhLogo',
    FMHLogoShort = 'fmhLogoShort',
    Cross = 'cross',
    FfCross = 'ffCross',
    Bell = 'bell',
    BellInverted = 'bellInverted',
    FIFALogo = 'fifaLogo',
    Word = 'word',
    Excel = 'excel',
    ExternalLink = 'externalLink',
    Ellipse = 'ellipse',
    Facebook = 'facebook',
    Instagram = 'instagram',
    LinkedIn = 'linkedin',
    Youtube = 'youtube',
    Twitter = 'twitter',
    SeekForward = 'seekForward',
    SeekBackward = 'seekBackward',
    Attention = 'attention',
    AttentionInverted = 'attentionInverted',
    Calendar = 'calendar',
    Sort = 'sort',
    Search = 'search',
    BusScheduleAvgTime = 'busScheduleAvgTime',
    BusScheduleAvgTimeMobile = 'busScheduleAvgTimeMobile',
    LocationOrigin = 'locationOrigin',
    LocationDestination = 'locationDestination',
    ThreeDotVertical = 'threeDotVertical',
    BusScheduleCalendar = 'busScheduleCalendar',
    Filter = 'filter',
    MediaPackage = 'mediaPackage',
    CalendarBlue = 'calendarBlue',
    Bus = 'bus',
    DottedLine = 'dottedLine',
    Clock = 'clock',
    Walking = 'walking',
    RouteIconsVertical = 'routeIconsVertical',
    Zoom = 'zoom',
    Live = 'live',
    Newsroom = 'newsroom',
    Ball = 'ball',
    RedCard = 'redCard',
    YellowCard = 'yellowCard',
    SubstitutionOn = 'substitutionOn',
    SubstitutionOff = 'substitutionOff',
    RankingArrowUp = 'rankingArrowUp',
    RankingArrowDown = 'rankingArrowDown',
    Info = 'info',
    Refresh = 'refresh',
    AudioWave = 'audioWave',
    AudioBackground = 'audioBackground',
    Accreditation = 'accreditation',
    BottomNavBus = 'bottomNavBus',
    BottomNavCalendar = 'bottomNavCalendar',
    BottomNavHamburger = 'bottomNavHamburger',
    BottomNavHome = 'bottomNavHome',
    BottomNavTicket = 'bottomNavTicket',
    BottomNavTrophy = 'bottomNavTrophy',
    WhereToWatchIndicator = 'whereToWatchIndicator',
    Star = 'star',
    View = 'view',
    MapPin = 'mapPin',
    ContactAvatarPlaceholder = 'contactAvatarPlaceholder',
    InstagramOutlined = 'instagram-outlined',
    LinkedInSquareFilled = 'linkedinSquareFilled',
    YoutubeFilled = 'youtubeFilled',
    XOutlined = 'XOutlined',
    TiktokOutlined = 'tiktokOutlined',
    Email = 'email',
    Phone = 'phone',
}

type IconProps = {
    icon: IconType | string;
} & React.SVGProps<SVGSVGElement>;

export default IconProps;
