import React, { createContext, ReactNode, useCallback, useContext, useMemo, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { FMHBanner } from 'src/organisms/fmh-banner';
import FMHBannerProps, { BannerType } from 'src/organisms/fmh-banner/dto';
import FMHLiveGamesWrapper from 'src/molecules/fmh-live-games-wrapper/fmh-live-games-wrapper';
import { Link } from 'src/types/apiDtos';
import { Maybe } from 'src/types/maybe';

interface OverlayContextType {
    bannerRef: React.RefObject<HTMLDivElement>;
    overlay?: JSX.Element;
    clear: () => void;
    addBanner: (
        message: string,
        title?: Maybe<string>,
        link?: Maybe<Link>,
        type?: Maybe<BannerType>,
        isCloseable?: Maybe<boolean>,
        props?: Maybe<FMHBannerProps>,
    ) => HTMLDivElement;
    addLiveGamesBanner: (
        dynamicFootballStatisticsPageUrl?: Maybe<string>,
        tournamentName?: Maybe<string>,
    ) => HTMLDivElement;
}
const OverlayContext: React.Context<OverlayContextType> = createContext({} as OverlayContextType);

// OverlayProvider to wrap around our main App.tsx component
export const OverlayProvider = ({ children }: { children: ReactNode }) => {
    const bannerRef = useRef<HTMLDivElement>(null);
    // state to hold active overlay

    const addBannerToRefAndRender = useCallback(
        (element: JSX.Element, div: HTMLDivElement) => {
            bannerRef.current?.appendChild(div);
            createRoot(div).render(element);
        },
        [bannerRef],
    );

    const addBanner = useCallback(
        (
            message: string,
            title?: Maybe<string>,
            link?: Maybe<Link>,
            type?: Maybe<BannerType>,
            isCloseable?: Maybe<boolean>,
            props?: Maybe<FMHBannerProps>,
        ): HTMLDivElement => {
            const newDiv = document.createElement('div');
            const banner = (
                <FMHBanner
                    {...(props ?? {})}
                    message={message}
                    title={title}
                    onBannerCloseClick={isCloseable ? () => newDiv.remove() : null}
                    link={link}
                    type={type ? type : BannerType.DEFAULT}
                    sticky={true}
                />
            );

            addBannerToRefAndRender(banner, newDiv);

            return newDiv;
        },
        [addBannerToRefAndRender],
    );

    const addLiveGamesBanner = useCallback(
        (dynamicFootballStatisticsPageUrl?: Maybe<string>, tournamentName?: Maybe<string>): HTMLDivElement => {
            const newDiv = document.createElement('div');
            const banner = tournamentName ? (
                <FMHLiveGamesWrapper
                    tournamentName={tournamentName}
                    liveGamesPageUrl={dynamicFootballStatisticsPageUrl}
                />
            ) : (
                <></>
            );

            addBannerToRefAndRender(banner, newDiv);

            return newDiv;
        },
        [addBannerToRefAndRender],
    );

    const clear = useCallback(() => {
        if (bannerRef && bannerRef.current) bannerRef.current.innerHTML = '';
    }, [bannerRef]);

    // Using React Memo, to ensure that the OverlayProvider only update when the overlay state changes. Otherwise the whole tree under the OverlayProvider will re-render children at every state and value change
    const overlayValues = useMemo(
        () => ({
            bannerRef,
            addBanner,
            addLiveGamesBanner,
            clear,
        }),
        [addBanner, addLiveGamesBanner, clear],
    );

    return <OverlayContext.Provider value={overlayValues}>{children}</OverlayContext.Provider>;
};

// useOverlay is a context helper function to be used inside the components, that need information about overlay overlay
const useOverlay = () => {
    return useContext(OverlayContext);
};
export default useOverlay;
