import { FFImageProps } from 'src/atoms/ff/ff-image';
import { AudioProps, MediaProps } from 'src/templates/video-page-template/dto';
import { Maybe } from 'src/types/maybe';
import { FFDocumentCardProps } from '../ff/ff-document-card';
import { FFVideoCardProps } from '../ff/ff-video-card';

export enum DownloadStatus {
    Initial,
    Finished,
    Error,
}

type FMHDownloadPackageButtonProps = {
    className?: Maybe<string>;

    videosDownloadApiEndpoints?: Array<string>;
    videosDownloadLabels?: Array<string>;
    videosDownloadSizes?: Array<number>;

    additionalVideoFiles?: Maybe<Array<FFVideoCardProps & MediaProps>>;
    additionalVideoFilesTitle?: Maybe<string>;

    additionalImageFiles?: Maybe<Array<FFImageProps & MediaProps>>;
    additionalImageFilesTitle?: Maybe<string>;

    additionalDocumentFiles?: Maybe<Array<FFDocumentCardProps & MediaProps>>;
    additionalDocumentFilesTitle?: Maybe<string>;

    additionalAudioFiles?: Maybe<Array<AudioProps & MediaProps>>;
    additionalAudioFilesTitle?: Maybe<string>;

    isAllSelected?: Maybe<boolean>;
    onSelectAll?: Maybe<(isAllSelected: boolean) => void>;
    downloadEndpoints?: Maybe<Array<string>>;
    onDownloadEndpointsChange?: Maybe<(endpoints: Array<string>) => void>;

    showAllFileSize?: Maybe<boolean>;
    showFileSizes?: Maybe<boolean>;
    buttonName?: Maybe<string>;
    onDownloadButtonClicked?: Maybe<(endpoints: string[]) => void>;

    isMatchCardDropdown?: Maybe<boolean>;
};

export default FMHDownloadPackageButtonProps;
