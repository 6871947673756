import { ImageTransformProps } from 'src/utils/ff/image-transform';
import { BynderImageTransform } from 'src/utils/ff/bynder-transform';
import FFImageProps from './dto';

export const getCorrectImageSrc = (
    props: FFImageProps,
    aspectRatioWidth?: number | null,
    aspectRatioHeight?: number | null,
) => {
    if (props.skipProcessingService) return props.src;

    const imageTransformOptions: ImageTransformProps = {
        imgSrc: props.src,
        imageWidth: typeof props.width === 'string' ? Number(props.width) : props.width,
        imageHeight: typeof props.height === 'string' ? Number(props.height) : props.height,
        quality: 'auto',
        cropMode: props.cropMode ?? 'fill',
    };
    if (aspectRatioWidth && aspectRatioHeight) {
        imageTransformOptions['aspectRatioWidth'] = aspectRatioWidth;
        imageTransformOptions['aspectRatioHeight'] = aspectRatioHeight;
    }
    if ('focalPosX' && 'focalPosY' in props) {
        imageTransformOptions['focalPosX'] = props.focalPosX;
        imageTransformOptions['focalPosY'] = props.focalPosY;
    } else if ('focalAI' in props) {
        imageTransformOptions['focalAI'] = props.focalAI;
    }
    if (props.colorize && props.colorize.hex && props.colorize.opacity) {
        imageTransformOptions.colorize = props.colorize;
    }

    return BynderImageTransform(imageTransformOptions);
};
