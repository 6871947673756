export enum HeadingSizeProp {
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3',
    H4 = 'h4',
    H5 = 'h5',
    H6 = 'h6',
}

type FFHeadingProps = {
    text: string;
    size: HeadingSizeProp;
    className?: string;
};

export default FFHeadingProps;
