import classNames from 'classnames';
import { useState, useRef } from 'react';
import Slider, { Settings as SliderSettings } from 'react-slick';
import { FFButton } from 'src/atoms/ff/ff-button';
import { Icon } from 'src/components/icon-generator';
import { ButtonType } from 'src/atoms/ff/ff-button/dto';
import { useHistory } from 'react-router-dom';
import { isOnSafari } from 'src/utils/check';
import { IconType } from 'src/components/icon-generator/dto';
import { AndroidPWAInstallSteps, PWAInstallSteps } from './dto';
import Styles from './pwa-install-instructions.module.scss';

const PWAInstallInstruction = () => {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const sliderRef = useRef<any>();

    const settings: SliderSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        afterChange: (currentSlide) => {
            setActiveIndex(currentSlide);
        },
    };

    const handleNext = () => {
        if (!sliderRef) return;

        sliderRef.current?.slickNext();
    };

    const handlePrev = () => {
        if (!sliderRef) return;

        sliderRef.current?.slickPrev();
    };

    const history = useHistory();
    const redirectToHome = () => {
        history.push('/home');
    };

    const installSteps = isOnSafari() ? PWAInstallSteps : AndroidPWAInstallSteps;

    const isLastStep = activeIndex === installSteps.length - 1;

    return (
        <div className={classNames(Styles.container)}>
            <div className={Styles.innerContainer}>
                <h5 className={Styles.overline}>{isOnSafari() ? 'iOS' : 'Android'} Installation instructions</h5>
                <Slider ref={(ref) => (sliderRef.current = ref)} {...settings} className={Styles.slider}>
                    {installSteps.map((step) => (
                        <div key={step.stepNo}>
                            <div className={Styles.instructionStep}>
                                <h2 className={Styles.headline}>
                                    Step {step.stepNo} (of {installSteps.length})
                                </h2>
                                <p className="ff-mb-16">{step.instruction}</p>
                                <img className={Styles.instructionImage} src={step.image} />
                            </div>
                        </div>
                    ))}
                </Slider>
                <div className={classNames(Styles.actions, 'd-flex flex-row')}>
                    <button
                        className={classNames(Styles.carouselControl, { [Styles.disabled]: activeIndex === 0 })}
                        onClick={handlePrev}
                    >
                        <Icon icon={IconType.ChevronLeft} />
                    </button>
                    <button
                        className={classNames(Styles.carouselControl, { [Styles.disabled]: isLastStep })}
                        onClick={handleNext}
                    >
                        <Icon icon={IconType.ChevronRight} />
                    </button>
                    {isLastStep && (
                        <FFButton
                            text="Visit site"
                            kind={ButtonType.Primary}
                            postfixIcon={IconType.ArrowRight}
                            func={redirectToHome}
                            className={Styles.visitSiteBtn}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default PWAInstallInstruction;
