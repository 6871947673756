import { FC } from 'react';
import { Helmet } from 'react-helmet';

import MainProps from './dto';

export const Main: FC<MainProps> = ({ children }: MainProps): JSX.Element => {
    return (
        <main>
            <Helmet></Helmet>
            {children}
        </main>
    );
};

export default Main;
