import { format } from 'date-fns';
import { FC } from 'react';
import { Icon } from 'src/components/icon-generator';
import { LogoType } from '../ff/ff-logo/dto';
import FFLogo from '../ff/ff-logo/ff-logo';
import FMHLiveScoreProps from './dto';
import styles from './fmh-live-score.module.scss';

const FMHLiveScore: FC<FMHLiveScoreProps> = ({ liveGame }: FMHLiveScoreProps) => {
    return (
        <>
            <div className={`ff-ml-xxl-96 ff-ml-xl-32 ff-ml-lg-24 ff-ml-md-16 ff-ml-sm-8 ${styles.liveScores}`}>
                <div className={`${styles.country}`}>
                    <FFLogo
                        logoType={LogoType.Team}
                        className={`${styles.flag} d-flex justify-content-center`}
                        src={`${liveGame?.homeTeam?.pictureUrl}`}
                        alt="Home team logo"
                    />
                    <div className={`${styles.name}`}>{liveGame?.homeTeam?.name}</div>
                </div>
                <div className={`${styles.scoreAndTime}`}>
                    {liveGame?.status === 'Live' && <Icon className="ff-mt-8" icon="live" />}
                    {(liveGame?.status === 'Live' || liveGame?.status === 'Played') && (
                        <div className={`${styles.score}`}>
                            {liveGame?.homeTeam?.score} - {liveGame?.awayTeam?.score}
                        </div>
                    )}
                    {(liveGame?.status === 'ToBePlayed' || liveGame?.status === 'LineUps') && (
                        <div className={`${styles.time}`}>
                            {liveGame.date && format(new Date(liveGame.date), 'HH:mm')}
                        </div>
                    )}
                    {liveGame?.status === 'Played' && <div className={`${styles.time}`}>Full-Time</div>}
                    {liveGame?.period &&
                        liveGame?.status === 'Played' &&
                        liveGame?.resultType === 'AggregatedExtraTime' && <div className={`${styles.time}`}>(AET)</div>}
                    {liveGame?.period &&
                        liveGame?.status === 'Played' &&
                        liveGame?.resultType === 'PenaltyShootout' && (
                            <div className={`${styles.time}`}>
                                {liveGame?.homeTeamPenaltyScore}-{liveGame?.awayTeamPenaltyScore} (Pen)
                            </div>
                        )}
                    {liveGame?.status === 'Live' && (
                        <div className={`${styles.time}`}>
                            {liveGame?.period !== 'PenaltyShootout' && <span>{liveGame.matchTime}</span>}
                            {liveGame?.period && liveGame?.status === 'Live' && liveGame?.period === 'ExtraTime' && (
                                <span className={`${styles.time}`}> (ET)</span>
                            )}
                        </div>
                    )}
                    {liveGame?.status === 'Postponed' && <div className={`${styles.time}`}>Postponed</div>}
                    {liveGame?.period && liveGame?.period === 'PenaltyShootout' && (
                        <div className={`${styles.time}`}>
                            {liveGame?.homeTeamPenaltyScore}-{liveGame?.awayTeamPenaltyScore} (Pen)
                        </div>
                    )}
                    {liveGame?.status === 'Abandoned' && <div className={`${styles.time}`}>Abandoned</div>}
                    {liveGame?.status === 'Canceled' && <div className={`${styles.time}`}>Canceled</div>}
                    {liveGame?.status === 'Forfeited' && <div className={`${styles.time}`}>Forfeited</div>}
                    {liveGame?.status === 'Suspended' && <div className={`${styles.time}`}>Suspended</div>}
                </div>
                <div className={`${styles.country}`}>
                    <FFLogo
                        logoType={LogoType.Team}
                        className={`${styles.flag} d-flex justify-content-center`}
                        src={`${liveGame?.awayTeam?.pictureUrl}`}
                        alt="Away team logo"
                    />
                    <div className={`${styles.name}`}>{liveGame?.awayTeam?.name}</div>
                </div>
            </div>
        </>
    );
};

export default FMHLiveScore;
