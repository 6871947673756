import { Language } from './languageCode';

export const languageOptionsSort = (a: Language, b: Language, currentLocale?: string) => {
    if (currentLocale === a.id) return -1;
    if (currentLocale === b.id) return 1;
    const isARtl = a.id === 'ar';
    const isBRtl = b.id === 'ar';
    if (isARtl && !isBRtl) return 1;
    if (!isARtl && isBRtl) return -1;
    return a.text.localeCompare(b.text);
};
