import { withRouter } from 'react-router-dom';
import { parse } from 'qs';
import { useEffect, useState } from 'react';
import { Main } from '@/components/main';
import authService from '@/components/authorization/AuthorizeService';

const TimelyEvent = (props: any) => {
    const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
    const [isLoginNeeded, setIsLoginNeeded] = useState(false);

    useEffect(() => {
        authService.getAccessToken().then((accessToken) => {
            if (accessToken) {
                setAccessToken(accessToken);
            } else {
                setIsLoginNeeded(true);
            }
        });
    }, []);

    const eventUrl = parse(props.location.search, { ignoreQueryPrefix: true }).eventUrl;

    const redirectUrl = `${eventUrl}?access_token=${accessToken}`;
    if (accessToken) {
        window.location.replace(redirectUrl);
    }
    if (isLoginNeeded) {
        authService.login(location.href);
    }

    return (
        <Main title="Redirect to event">
            <div />
        </Main>
    );
};

export default withRouter(TimelyEvent);
