import ReactGA from 'react-ga4';

const googleAnalyticsMeasureId = process.env.REACT_APP_GOOGLE_TRACKING_MEASURE_ID;

const initializeTracking = () => {
    if (googleAnalyticsMeasureId !== undefined) {
        ReactGA.initialize(googleAnalyticsMeasureId?.toString());
    }
};

const sendEvent = (categoryName: string, eventName: string, labelDescription: string) => {
    ReactGA.event({
        category: categoryName,
        action: eventName,
        label: labelDescription,
    });
};

const GoogleTrackingServices = {
    initializeTracking,
    sendEvent,
};

export default GoogleTrackingServices;
