import classNames from 'classnames';
import { Fragment } from 'react';
import { FMHProfileImage } from 'src/atoms/ff/fmh-profile-image';
import { ButtonType, Size } from 'src/atoms/ff/ff-button/dto';
import { FMHLink } from 'src/atoms/fmh-link';
import { Link } from 'src/types/apiDtos';
import { externalPortalsEndpoint, profileImageEndpoint } from 'src/utils/api';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import { FFButton } from '@/atoms/ff/ff-button';
import FMHPortalsDropdown from '@/atoms/ff/fmh-portals-dropdown/fmh-portals-dropdown';

export type AuthData = {
    userName: string;
    isAuthenticated: boolean;
};

interface LoginMenuProps {
    authData: AuthData | undefined;
    loginButtonClassname: string;
    logInHoldingScreenLink: Link;
    setIsUserAuthenticated?: React.Dispatch<React.SetStateAction<boolean>>;
}

const authenticatedView = (userName, profilePath, logoutPath) => {
    const profileImage: any = (
        <FMHProfileImage width="24px" height="24px" isSquared={true} src={profileImageEndpoint} />
    );

    return (
        <Fragment>
            {navigator.onLine && (
                <>
                    <FMHPortalsDropdown
                        src={externalPortalsEndpoint}
                        logoutPath={logoutPath}
                        profileImage={profileImage}
                        profilePath={profilePath}
                        userName={userName}
                    />
                </>
            )}
        </Fragment>
    );
};

const anonymousView = (_registerPath, logInHoldingScreenLink, loginButtonClassname) => {
    return (
        <Fragment>
            {logInHoldingScreenLink && navigator.onLine && (
                <>
                    <FMHLink
                        className={classNames(loginButtonClassname, 'd-inline d-lg-none')}
                        href={logInHoldingScreenLink.uri}
                    >
                        {logInHoldingScreenLink.text}
                    </FMHLink>
                    <FFButton
                        className={classNames(loginButtonClassname, 'd-none d-lg-block')}
                        text={logInHoldingScreenLink.text}
                        size={Size.Small}
                        kind={ButtonType.Primary}
                        link={logInHoldingScreenLink.uri}
                    />
                </>
            )}
        </Fragment>
    );
};

export const LoginMenu: React.FC<LoginMenuProps> = ({
    authData,
    loginButtonClassname,
    logInHoldingScreenLink,
}: LoginMenuProps) => {
    if (!authData?.isAuthenticated) {
        const registerPath = `${ApplicationPaths.Register}`;
        return anonymousView(registerPath, logInHoldingScreenLink, loginButtonClassname);
    } else {
        const profilePath = `${ApplicationPaths.Profile}`;
        const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
        return authenticatedView(authData?.userName, profilePath, logoutPath);
    }
};
