import api, { apiEndpoints } from 'src/utils/api';
import moment from 'moment';
import { LiveGame } from 'src/molecules/fmh-live-games-wrapper/dto';
import { LiveGamesDocuments } from 'src/molecules/ff-live-games-header/dto';
import { Maybe } from 'src/types/maybe';
import { StandingGroup } from 'src/Sections/fmh-tournament-groups-section/fmh-tournament-groups-section';
import { WorldRankingsDto } from 'src/types/footballData/footballDataDtos';

const getLiveGames = async (competitionId: string): Promise<LiveGame[]> => {
    const res = await api.get(`${apiEndpoints.liveGames}/game/${competitionId}`);
    return res.data;
};

const getTournamentGamesUntilToday = async (
    competitionId: string,
    tournamentStartDate: string,
    tournamentEndDate: string,
): Promise<LiveGame[]> => {
    const res = await api.get(`${apiEndpoints.tournamentGamesUntilToday}/${competitionId}`, {
        params: { from: tournamentStartDate, to: tournamentEndDate },
    });
    return res.data;
};

const getTournamentStandingGroups = async (
    competitionId: string,
    seasonId: string,
    stageId: string,
    locale: string,
): Promise<Array<StandingGroup>> => {
    const res = await api.get(`v1/liveGames/groups/${competitionId}/${seasonId}/${stageId}?locale=${locale}`);
    return res.data;
};

const getWorldRankings = async (locale: string): Promise<WorldRankingsDto> => {
    const res = await api.get(`v1/liveGames/worldRankings?locale=${locale}`);
    return res.data;
};

const getStartListsAndMatchReports = async (matchId: string): Promise<LiveGamesDocuments> => {
    const res = await api.get(`${apiEndpoints.liveGames}/startList/${matchId}`);
    return res.data;
};

const removeIndexOfGameFromLocalStorage = () => {
    const updateLocalStorageInterval = setInterval(() => {
        localStorage.removeItem('IndexOfGame');
    }, 300000);
    return () => {
        clearInterval(updateLocalStorageInterval);
    };
};

const setIndexOfGameToLocalStorage = (indexOfElement: string): void => {
    localStorage.setItem('IndexOfGame', indexOfElement);
};

const checkIfIndexOfGameFromLocalStorage = (): boolean => {
    if (localStorage.getItem('IndexOfGame') !== null) {
        return true;
    } else {
        return false;
    }
};

const getIndexOfGameFromLocalStorage = (): number => {
    const index = localStorage.getItem('IndexOfGame');
    let parsedIndex = 0;

    if (index) {
        parsedIndex = parseInt(index);
    }

    return parsedIndex;
};

export const getMatchData = (game: LiveGame, includeSeasonName: Maybe<boolean>): string[] => {
    const matchDataArray = [] as string[];

    if (includeSeasonName && game?.seasonName) {
        matchDataArray.push(game?.seasonName);
    }
    if (game?.date) {
        matchDataArray.push(moment(game?.date).format('LL'));
    }
    if (game?.groupName) {
        matchDataArray.push(game?.groupName);
    }
    if (game?.round) {
        matchDataArray.push(game?.round);
    }
    if (game?.stadium) {
        matchDataArray.push(game?.stadium);
    }

    return matchDataArray;
};

const LiveGamesService = {
    getLiveGames,
    getTournamentGamesUntilToday,
    getTournamentStandingGroups,
    getStartListsAndMatchReports,
    removeIndexOfGameFromLocalStorage,
    setIndexOfGameToLocalStorage,
    getIndexOfGameFromLocalStorage,
    checkIfIndexOfGameFromLocalStorage,
    getMatchData,
    getWorldRankings,
};

export default LiveGamesService;
