import { api } from '@/utils/api';

const synchronizeUser = (): void => {
    const url = `/v1/userSynchronization`;
    api.post(url).then((_) => {});
};

const SynchronizationService = {
    synchronizeUser,
};

export default SynchronizationService;
