import { FC, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { isArrayInvalid } from 'src/utils/check';
import classNames from 'classnames';
import { FFDropdownAccordionItem } from '../ff-dropdown-accordion-item';
import { checkIfExternalLink } from '../fmh-link/fmh-link';
import { FMHLink } from '../fmh-link';
import Style from './ff-dropdown.module.scss';
import DropdownProps, { DropdownEntry } from './dto';
import { ReactComponent as DownArrowIcon } from '@/icons/ff-chevron/ff-chevron-down.svg';
import { ReactComponent as LogOutIcon } from '@/icons/ff-logout-icon.svg';

const FFDropdown: FC<DropdownProps> = (props: DropdownProps): JSX.Element => {
    const divRef = useRef<HTMLDivElement>(null);
    const [isToggled, setIsToggled] = useState(false);
    const [activeIndexOptionGroup, setActiveIndexOptionGroup] = useState(-1);

    const profileLink = mapToOptions(props.profileLink);
    const subscriptionsLink = mapToOptions(props.subscriptionsLink);
    const logoutLink = mapToOptions(props.logoutLink);

    function mapToOptions(element) {
        const dropdownEntry = element as DropdownEntry;
        if (dropdownEntry.text) {
            if (dropdownEntry.id === '0') {
                return (
                    <NavLink
                        tag={Link}
                        to={dropdownEntry.uri || ''}
                        className={classNames(Style.dropupContentButton, 'ff-mb-0')}
                        key={dropdownEntry.id}
                        onClick={() => setIsToggled(false)}
                    >
                        <div className="d-flex flex-row justify-content-between">
                            {dropdownEntry.text}
                            <LogOutIcon width="16" height="16" className={Style.logoutIcon}></LogOutIcon>
                        </div>
                    </NavLink>
                );
            } else {
                return (
                    <FMHLink
                        href={dropdownEntry.uri || ''}
                        key={dropdownEntry.id}
                        className={Style.dropupContentButton}
                        openInNewTab={checkIfExternalLink(dropdownEntry.uri || '')}
                        onClick={() => setIsToggled(false)}
                    >
                        <div className="d-flex align-items-center">
                            {dropdownEntry.icon && (
                                <div className={classNames(Style.icon, 'ff-mr-16')}>{dropdownEntry.icon}</div>
                            )}
                            {dropdownEntry.text}
                        </div>
                    </FMHLink>
                );
            }
        }
    }

    const toggle = useCallback(
        (val) => {
            const profileImageElement = document.getElementById('profileImage');
            if (profileImageElement) {
                if (!isToggled && val) {
                    profileImageElement.style.borderColor = '#0088e7';
                } else {
                    profileImageElement.style.borderColor = '#fff';
                }
            }

            setIsToggled(val);
        },
        [isToggled],
    );

    const optionGroups = props.optionGroups.map((group, index) => {
        if (isArrayInvalid(group.options)) return;

        const options = group.options.map((option, index) => (
            <Fragment key={`${option.id}-${index}`}>{mapToOptions(option)}</Fragment>
        ));

        return (
            <FFDropdownAccordionItem
                label={group.label}
                options={options}
                key={`${group.label}-${index}`}
                isOpen={activeIndexOptionGroup === index}
                onClick={() => setActiveIndexOptionGroup(index)}
                classNames={Style.dropdownAccordionItem}
            />
        );
    });

    const closeDropup = useCallback(
        (e) => {
            if (!divRef.current) return;

            const isClickInside = divRef.current?.contains(e.target);

            if (!isClickInside) {
                toggle(false);
            }
        },
        [toggle],
    );

    useEffect(() => {
        document.addEventListener('click', closeDropup, false);

        return () => {
            document.removeEventListener('click', closeDropup);
        };
    }, [closeDropup]);

    useEffect(() => {
        if (isToggled) {
            document.body.style.overflow = 'hidden';
            document.body.style.paddingRight = '15px';
        } else {
            document.body.style.overflow = '';
            document.body.style.paddingRight = '';
        }
    }, [isToggled]);

    return (
        <>
            <div ref={divRef} className={`${Style.dropup} ${isToggled ? Style.dropupActive : null}`}>
                <button
                    className={`card-heading-tiny ff-mb-0 ${Style.dropupButton}
                        ${props.includeBorder ? '' : Style.noBorder}`}
                    onClick={() => toggle(!isToggled)}
                >
                    <div className="d-flex align-items-center justify-content-end">
                        <div className="d-flex flex-row">
                            {props.preText ? (
                                <p className={`ff-mb-0 ff-text-white ${Style.username} ${Style.pretext}`}>
                                    {props.preText}
                                </p>
                            ) : null}
                            <div
                                className={`ff-mb-0 ff-text-white header-dropdown-small ${Style.username} ${Style.selected}`}
                            >
                                {props.selected}
                            </div>
                        </div>
                        <div
                            className={`${Style.arrowContainer} ${isToggled ? Style.rotate : null} ff-ml-8`}
                            style={{ display: props.includeArrow ? 'flex' : 'none' }}
                        >
                            <DownArrowIcon width="16" height="16"></DownArrowIcon>
                        </div>
                    </div>
                </button>
                <div
                    className={`
                            ${`${Style.dropdownContent} ff-py-8`}
                            ${props.isRightAligned ? Style.isRightAligned : ''}
                            ${props.isDropup ? Style.isDropup : ''}
                        `}
                    style={{
                        display: isToggled ? 'block' : 'none',
                        maxHeight: `calc(95dvh - ${divRef.current?.getBoundingClientRect().bottom || 0}px)`,
                        overflowY: 'auto',
                    }}
                >
                    {profileLink}
                    {subscriptionsLink}
                    {optionGroups}
                    {logoutLink}
                </div>
            </div>
            {isToggled && (
                <div
                    className={Style.backdrop}
                    style={{
                        top: divRef.current?.getBoundingClientRect().bottom,
                    }}
                ></div>
            )}
        </>
    );
};

export default FFDropdown;
