// @ts-ignore
import { createContext, useCallback, useMemo, useRef, useId, useState, useContext } from 'react';
import AdobeViewSDKClient from 'src/components/adobe-view-sdk-client/AdobeViewSDKClient';
import { PDFViewer } from 'src/components/pdf-viewer';
import { Logger } from '../logger';

interface PDFViewerContextType {
    setPreviewFile: (fileUrl: string, fileName: string) => void;
}

const PDFViewerContext = createContext({} as PDFViewerContextType);

export const PDFViewerContextProvider = ({ children }) => {
    const viewSDKClientRef = useRef(new AdobeViewSDKClient());
    const previewFileId = useId();
    const [openPDFViewer, setOpenPDFViewer] = useState(false);
    const [fileUrl, setFileUrl] = useState('');
    const [fileName, setFileName] = useState('');
    const [hasError, setHasError] = useState(false);

    const setPreviewFile = useCallback(
        (fileUrl: string, fileName: string) => {
            const viewSDKClient = viewSDKClientRef.current;
            setOpenPDFViewer(true);
            setFileUrl(fileUrl);
            setFileName(fileName);
            setHasError(false);

            let timer;
            const loadPreviewFile = async () => {
                await viewSDKClient.ready();
                await viewSDKClient.previewFile(fileUrl, previewFileId, fileName);
            };

            Promise.race([
                // Get PDF viewer SDK ready and load preview File
                loadPreviewFile(),
                // Cancel PDF Viewer loading after 15 seconds
                new Promise((_r, reject) => (timer = setTimeout(() => reject('Timeout'), 15000))),
            ])
                .catch((error) => {
                    Logger.error(error);
                    setHasError(error);
                })
                .finally(() => clearTimeout(timer));
        },
        [previewFileId],
    );

    const pdfViewerContextValues = useMemo(
        () => ({
            setPreviewFile,
            setOpenPDFViewer,
        }),
        [setPreviewFile],
    );

    return (
        <PDFViewerContext.Provider value={pdfViewerContextValues}>
            {children}
            <PDFViewer
                fileUrl={fileUrl}
                fileName={fileName}
                open={openPDFViewer}
                divId={previewFileId}
                hasError={hasError}
                onClose={() => setOpenPDFViewer(false)}
            />
        </PDFViewerContext.Provider>
    );
};

export const usePDFViewerContext = () => useContext(PDFViewerContext);
