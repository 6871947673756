import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PWAInstallInstruction } from 'src/organisms/pwa-install-instructions';
import { PWAInstallLanding } from 'src/organisms/pwa-install-landing';
import { isOnSafari } from 'src/utils/check';

const TRUE = 'TRUE';
const PWA_WAIT_TIME = 5000;

const PWAInstallation = () => {
    const [isAppInstalled, setIsAppInstalled] = useState<boolean>(localStorage.getItem('isAppInstalled') === TRUE);
    const [shouldShowInstructionStep, setShouldShowInstructionStep] = useState(false);

    const [isButtonEnabled, setIsButtonEnabled] = useState(false);

    useEffect(() => {
        const handleBeforeInstallPrompt = (event: any) => {
            window['installPrompt'] = event;
            setIsButtonEnabled(true);
        };

        const handleAppInstalled = () => {
            localStorage.setItem('isAppInstalled', TRUE);
            setIsAppInstalled(true);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        window.addEventListener('appinstalled', handleAppInstalled);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
            window.removeEventListener('appinstalled', handleAppInstalled);
        };
    }, []);

    useEffect(() => {
        if (isButtonEnabled) return;

        const timeout = setTimeout(() => {
            if (!isButtonEnabled) setShouldShowInstructionStep(true);
        }, PWA_WAIT_TIME);

        return () => {
            clearTimeout(timeout);
        };
    }, [isButtonEnabled]);

    const handleInstall = () => {
        const installPrompt = window['installPrompt'];

        if (installPrompt) {
            return installPrompt.prompt();
        } else {
            setShouldShowInstructionStep(true);
        }
    };

    const history = useHistory();
    if (isAppInstalled || window.screen.width > 1024) {
        history.push('/home');
    }

    if (shouldShowInstructionStep) {
        return <PWAInstallInstruction />;
    }

    return <PWAInstallLanding handleInstall={handleInstall} isButtonEnabled={isButtonEnabled || isOnSafari()} />;
};

export default PWAInstallation;
