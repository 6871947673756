import classNames from 'classnames';
import { FC, useState } from 'react';
import Select from 'react-dropdown-select';
import uuid from 'src/utils/uuid';
import { Icon } from 'src/components/icon-generator';
import { FMHLink } from '../fmh-link';
import FMHLanguageDropdownProps, { DropdownItem } from './dto';
import Styles from './fmh-language-dropdown.module.scss';

const FMHLanguageDropdown: FC<FMHLanguageDropdownProps> = ({
    options,
    selectedLabel,
    selectedLabelMobile,
    suffixLabel,
    icon,
    currentSelected,
    className,
}: FMHLanguageDropdownProps): JSX.Element => {
    const uniqueId = uuid();
    const trueSelected = currentSelected ? options.filter((item) => item.value === currentSelected) : options;
    const [selected, setSelected] = useState<DropdownItem>(trueSelected[0]);

    const renderMenuItem = ({ item, methods }: { item: DropdownItem; methods: any }): JSX.Element => {
        const isItemSelected = item.value === selected.value;

        return item.uri ? (
            <FMHLink href={item.uri} role="button" key={item.value + uniqueId} onClick={() => methods.addItem(item)}>
                <div
                    className={classNames(
                        'ff-px-32 ff-py-8 align-items-center',
                        Styles.item,
                        isItemSelected && Styles.selected,
                    )}
                >
                    <div className={classNames('d-flex', item.isRtl && Styles.flexReverse)}>
                        {item.labelPrefix && (
                            <p className={classNames('ff-my-0', Styles.itemPrefix)}>{item.labelPrefix}</p>
                        )}
                        <p>{item.label}</p>
                    </div>
                    {isItemSelected && <Icon className={classNames(Styles.checkIcon, 'ff-mx-8')} icon="check" />}
                </div>
            </FMHLink>
        ) : (
            <></>
        );
    };

    const renderDropdownButton = () => {
        return (
            <div className={Styles.dropdownButton}>
                {icon && (
                    <span className={classNames('ff-md-mr-16', Styles.dropdownButton__icon)}>
                        <Icon icon={icon} />
                    </span>
                )}
                <span className={Styles.dropdownButton__selected}>{selectedLabel}</span>
                <span className={Styles.dropdownButton__selected__mobile}>{selectedLabelMobile}</span>
                {suffixLabel && (
                    <span className={classNames('ff-my-0 ff-mx-8', Styles.dropdownButton__label)}>{suffixLabel}</span>
                )}
            </div>
        );
    };

    return (
        <Select
            onChange={(values) => {
                setSelected(values[0]);
            }}
            options={options}
            values={[selected] ?? []}
            key={uniqueId}
            searchable={false}
            closeOnSelect={true}
            className={classNames('ff-mb-0 ff-py-8 ff-px-16', Styles.dropdown, className)}
            itemRenderer={renderMenuItem}
            contentRenderer={renderDropdownButton}
        />
    );
};

export default FMHLanguageDropdown;
