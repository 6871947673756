import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './fmh-checkbox.module.scss';
import FMHCheckboxProps from './dto';

export const FMHCheckbox: FC<FMHCheckboxProps> = ({ isEnabled = true, ...props }: FMHCheckboxProps): JSX.Element => {
    const [isChecked, setIsChecked] = useState<boolean>();

    useEffect(() => {
        setIsChecked(props.isChecked ? props.isChecked : false);
    }, [props.isChecked]);

    return (
        <div
            className={`${props.className ?? ''} ${styles.checkboxAreaContainer}`}
            onClick={(e) => {
                if (isEnabled) {
                    e.preventDefault();
                    const newVal = !isChecked;

                    setIsChecked(newVal);
                    if (props.onValueChange) props.onValueChange(newVal);
                }
            }}
        >
            <label
                className={styles.container}
                style={{ height: props.height || '24px', width: props.width || '24px' }}
            >
                <input type={'checkbox'} checked={isChecked ?? false} onChange={() => {}} />
                <span className={classNames(styles.checkmark, props.overrideClassNames?.checkMark)} />
            </label>

            {props.label && (
                <span className={classNames(styles.label, props.overrideClassNames?.label)}>{props.label}</span>
            )}
        </div>
    );
};

export default FMHCheckbox;
