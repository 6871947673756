import { FC, useEffect, useState } from 'react';
import api from 'src/utils/api';
import { ProfileImageType } from 'src/types/apiDtos';
import { Icon } from 'src/components/icon-generator';
import { IconType } from 'src/components/icon-generator/dto';
import classNames from 'classnames';
import { Logger } from 'src/utils/logger';
import { FFImage } from '../ff-image';
import styles from './fmh-profile-image.module.scss';
import FMHProfileImageProps from './dto';

const FMHProfileImage: FC<FMHProfileImageProps> = ({
    isSquared = false,
    ...props
}: FMHProfileImageProps): JSX.Element | null => {
    const [image, setImageData] = useState<ProfileImageType>();

    useEffect(() => {
        api.get(props.src)
            .then((res: any) => {
                if (res.status === 200 && res.data) setImageData(res.data);
            })
            .catch((error) => {
                Logger.error(error);
            });
    }, [props.src]);

    return (
        <>
            {image ? (
                <div
                    id="profileImage"
                    className={classNames(styles.outerDiv, {
                        [styles.rounded]: !isSquared,
                        [styles.squared]: isSquared,
                    })}
                    style={{ height: props.height, width: props.width }}
                >
                    <FFImage
                        src={`data:image/png;base64,${image.photo}`}
                        skipProcessingService={true}
                        width={props.width}
                        height={props.height}
                        alt={'Profile'}
                        title={''}
                        className={styles.image}
                    />
                </div>
            ) : (
                <div className={styles.userIcon}>
                    <Icon icon={IconType.User} />
                </div>
            )}
        </>
    );
};

export default FMHProfileImage;
