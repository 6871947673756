export function getBrowserDocumentHiddenProp() {
    if (typeof document.hidden !== 'undefined') {
        return 'hidden';
    } else {
        return 'visible';
    }
}

export function getIsDocumentHidden() {
    return !document[getBrowserDocumentHiddenProp()];
}
