import classNames from 'classnames';
import { ForwardRefRenderFunction, ForwardedRef, forwardRef } from 'react';
import FFNavLinkGroup from '../fc-header-nav-link-group/fc-header-nav-link-group';
import { SecondLevelMenu } from '../dto';
import styles from './fc-header-nav-sub-menu.module.scss';

type headerProps = {
    toggledClassName: string;
    secondLvlMenu: Array<SecondLevelMenu>;
    previousTitle: string;
    menuOpened: boolean;
    dropdownLock: any;
    dropdownUnlock: any;
    activeChildIndex: number;
    ref: ForwardedRef<HTMLUListElement>;
};

const FCNavSubMenu: ForwardRefRenderFunction<HTMLUListElement, headerProps> = (
    props: headerProps,
    ref: ForwardedRef<HTMLUListElement>,
): JSX.Element => {
    const itemList = props.secondLvlMenu.map((item, index) => (
        <li key={index} id={`${index}`} className={styles.linkLayout}>
            <FFNavLinkGroup
                title={item.title}
                toggledClassName={styles.subGroup}
                secondLevelMenuItem={item}
                handleClose={(e) => props.dropdownLock(e, index)}
                handleClick={(e) => props.dropdownUnlock(e, index)}
                isActive={props.activeChildIndex === index}
                index={index}
            />
        </li>
    ));

    return (
        <ul className={classNames(props.toggledClassName, styles.subMenuWrapper)} ref={ref}>
            {itemList}
        </ul>
    );
};

export default forwardRef<HTMLUListElement, headerProps>(FCNavSubMenu);
