export enum ResourceSetCommon {
    _Identifier = 'common',
    FifaCom = 'common.fifacom',
    FifaPlus = 'common.fifaplus',
    Cancel = 'common.cancel',
    Submit = 'common.submit',
    SortBy = 'common.sort_by',
    Current = 'common.current',
    History = 'common.history',
    ShowMore = 'common.show_more',
}

export enum ResourceSetElasticSearch {
    _Identifier = 'elastic_search',
    Placeholder = 'elastic_search.placeholder',
    Title = 'elastic_search.search_page_title',
    FilterButton = 'elastic_search.filters',
    NarrowSearch = 'elastic_search.narrow_search',
    DateRange = 'elastic_search.date_range',
    ApplyFilters = 'elastic_search.apply_filters',
    ContentTypes = 'elastic_search.content_types',
    SelectedFilters = 'elastic_search.selected_filters',
    Platforms = 'elastic_search.platforms',
    DateRangeAll = 'elastic_search.date_range_all',
    DateRangeWeek = 'elastic_search.date_range_week',
    DateRangeMonth = 'elastic_search.date_range_month',
    DateRangeHalfYear = 'elastic_search.date_range_half_year',
    DateRangeYear = 'elastic_search.date_range_year',
    DateRangeCustom = 'elastic_search.date_range_custom',
    DateRangePicker = 'elastic_search.date_range_picker',
    DateRangeTo = 'elastic_search.date_range_to',
    DateRangeFrom = 'elastic_search.date_range_from',
    ShowingResults = 'elastic_search.showing_results',
}

export enum ResourceSetMediaListSection {
    _Identifier = 'media_list_section',
    AllLabel = 'media_list_section.all_label',
    PackageLabel = 'media_list_section.package_label',
    NoContentLabel = 'media_list_section.no_content_label',
    SearchInputPlaceHolder = 'media_list_section.search_input_place_holder',
    LanguagesLabel = 'media_list_section.languages_label',
}

export enum ResourceSetImageGalleryPageTemplate {
    _Identifier = 'image_gallery_page_template',
    NoContentLabel = 'image_gallery_page_template.no_content_label',
    ButtonBackLabel = 'image_gallery_page_template.button_back_label',
}

export enum ResourceSetMediaPackage {
    _Identifier = 'media_package',
    SelectAllLabel = 'media_package.select_all_label',
    DeselectAllLabel = 'media_package.deselect_all_label',
    ButtonDownLoadLabel = 'media_package.button_download_label',
    PackageLinkTextLabel = 'media_package.package_link_text_label',
    PackageLinkLabel = 'media_package.package_link_label',
}

export enum ResourceSetSubscriptionsBanner {
    _Identifier = 'subscriptions_banner',
    CloseLabel = 'subscriptions_banner.close',
    DoNotShowAgainLabel = 'subscriptions_banner.do_not_show_again',
    AddSubscriptionsLabel = 'subscriptions_banner.add_subscriptions',
}

export enum ResourceSetSubscriptionsSection {
    _Identifier = 'subscriptions_section',
    NoSubscriptions = 'subscriptions_section.no_subscriptions',
}

export enum ResourceSetLiveGames {
    _Identifier = 'live_games',
    StartList = 'live_games.start_list',
    MatchReport = 'live_games.match_report',
    RankingColumn = 'live_games.ranking_column',
    TeamColumn = 'live_games.team_column',
    PointsColumn = 'live_games.points_column',
    TotalPointsColumn = 'live_games.total_points_column',
    Updated = 'live_games.updated',
    Mens = 'live_games.mens',
    Womens = 'live_games.womens',
}

export enum ResourceSetBusSchedule {
    _Identifier = 'bus_schedule',
    AllLocations = 'bus_schedule.all_locations',
}
