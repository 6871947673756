import { Dictionary } from 'src/types/dictionary';
import { Maybe } from 'src/types/maybe';
import { AllResourceSet, Resource, ResourceSetWithAllLocales } from 'src/types/resource';
import { api } from './api';
import LanguageFunction from './languageCode';

const RESOURCE_SET_MAP_KEY = 'RESOURCE_SET_KEY';
const LAST_UPDATED_AT_MAP_KEY = 'LAST_UPDATED_AT_MAP_KEY';

class ResourcesSingleton {
    private static _resourceSetMap: ResourceSetWithAllLocales;
    private static _lastUpdatedAtMap: Dictionary<number>;

    public static getLabel(
        resourceSetIdentifier: string,
        resourceIdentifier: string,
        defaultValue?: Maybe<string>,
        locale?: Maybe<string>,
    ): string {
        const currentLocale: string = locale ?? LanguageFunction.getLocalStorageLocale();
        return (
            this._resourceSetMap?.[currentLocale]?.[resourceSetIdentifier]?.resources?.[resourceIdentifier]?.value ??
            defaultValue ??
            ''
        );
    }

    public static getLabelSet(resourceSetIdentifier: string, locale?: Maybe<string>): Dictionary<String> {
        const currentLocale: string = locale ?? LanguageFunction.getLocalStorageLocale();
        const resourceMap: Dictionary<Resource> =
            this._resourceSetMap?.[currentLocale]?.[resourceSetIdentifier]?.resources ?? {};

        const labelSet: Dictionary<string> = {};
        Object.keys(resourceMap).forEach((key) => (labelSet[key] = resourceMap[key].value));

        return labelSet;
    }

    public static async getResourceSetData(locale?: Maybe<string>): Promise<void> {
        const currentLocale: string = locale ?? LanguageFunction.getLocalStorageLocale();

        if (!this._resourceSetMap?.[currentLocale]) {
            this.getDataFromLocalStorage(currentLocale);
        }

        if (!this.shouldFetchData(currentLocale)) {
            return;
        }

        const data: Maybe<AllResourceSet> = await this.fetchData(currentLocale);

        if (!data) {
            return;
        } else {
            this.setDataToLocalStorage(data, currentLocale);
            this.getDataFromLocalStorage(currentLocale);
        }
    }

    private static async fetchData(locale): Promise<Maybe<AllResourceSet>> {
        try {
            const { data } = await api.get(`/v1/resourceset/all/${locale}`);
            return data as Maybe<AllResourceSet>;
        } catch (err) {
            return null;
        }
    }

    private static setDataToLocalStorage(data: AllResourceSet, locale: string): void {
        const localStorageResourceSetMap: ResourceSetWithAllLocales = JSON.parse(
            window.localStorage.getItem(RESOURCE_SET_MAP_KEY) ?? '{}',
        );
        const localStorageLastUpdatedAtMap: Dictionary<number> = JSON.parse(
            window.localStorage.getItem(LAST_UPDATED_AT_MAP_KEY) ?? '{}',
        );

        localStorageResourceSetMap[locale] = data;
        localStorageLastUpdatedAtMap[locale] = Date.now();

        window.localStorage.setItem(RESOURCE_SET_MAP_KEY, JSON.stringify(localStorageResourceSetMap));
        window.localStorage.setItem(LAST_UPDATED_AT_MAP_KEY, JSON.stringify(localStorageLastUpdatedAtMap));
    }

    private static getDataFromLocalStorage(locale: string): void {
        const localStorageResourceSetMap: ResourceSetWithAllLocales = JSON.parse(
            window.localStorage.getItem(RESOURCE_SET_MAP_KEY) ?? '{}',
        );
        const localStorageLastUpdatedAtMap: Dictionary<number> = JSON.parse(
            window.localStorage.getItem(LAST_UPDATED_AT_MAP_KEY) ?? '{}',
        );

        if (!this._resourceSetMap) {
            this._resourceSetMap = { [locale]: localStorageResourceSetMap[locale] };
            this._lastUpdatedAtMap = { [locale]: localStorageLastUpdatedAtMap[locale] };
        } else if (!this._resourceSetMap[locale]) {
            this._resourceSetMap[locale] = localStorageResourceSetMap[locale];
            this._lastUpdatedAtMap[locale] = localStorageLastUpdatedAtMap[locale];
        }
    }

    private static shouldFetchData(locale: string): boolean {
        return (
            !this._lastUpdatedAtMap?.[locale] ||
            this._lastUpdatedAtMap[locale] + Number(process.env.REACT_APP_RESOURCE_SET_TTL) < Date.now()
        );
    }
}

export default ResourcesSingleton;
