import classNames from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';
import { FMHLink } from 'src/atoms/fmh-link';
import { Icon } from 'src/components/icon-generator';
import { IconType } from 'src/components/icon-generator/dto';
import { useLocation } from 'react-router-dom';
import FFNavSubMenu from '../fc-header-nav-sub-menu/fc-header-nav-sub-menu';
import { FirstLevelMenu } from '../dto';
import styles from './fc-header-nav-item.module.scss';
import { FFHeading, HeadingSizeProp } from '@/atoms/ff-heading';

type headerProps = {
    firstLvlMenuItem: FirstLevelMenu;
    handleOpenBackdrop: () => void;
};

const FCNavItem: FC<headerProps> = ({ firstLvlMenuItem, handleOpenBackdrop }: headerProps): JSX.Element => {
    const [openSubMenu, setOpenSubMenu] = useState(false);
    const [activeChildIndex, setActiveChildIndex] = useState(-1);
    const elementRef = useRef<HTMLUListElement>(null);

    const handleOpenSubMenu = () => {
        setOpenSubMenu((prev) => !prev);
        handleOpenBackdrop();
    };

    const dropdownLock = (e, index) => {
        setActiveChildIndex((prev) => (prev === index ? -1 : index));
        e.currentTarget.blur();

        setOpenSubMenu(false);
        handleOpenBackdrop();
    };

    const dropdownUnlock = (e, index) => {
        setActiveChildIndex((prev) => (prev === index ? -1 : index));
        e.currentTarget.blur();

        setOpenSubMenu(true);
    };

    useEffect(() => {
        const onClickHandle = (event) => {
            if (
                !openSubMenu ||
                !elementRef.current ||
                elementRef.current.contains(event.target) ||
                elementRef.current.previousSibling?.contains(event.target)
            )
                return;

            setOpenSubMenu(false);
            handleOpenBackdrop();
        };

        document.addEventListener('mousedown', onClickHandle);
        document.addEventListener('touchstart', onClickHandle);
        return () => {
            document.removeEventListener('mousedown', onClickHandle);
            document.removeEventListener('touchstart', onClickHandle);
        };
    }, [openSubMenu, handleOpenBackdrop]);

    const location = useLocation();
    const isNavItemActive = (): boolean => {
        if (firstLvlMenuItem.uri) {
            return location.pathname.includes(firstLvlMenuItem.uri);
        } else {
            return firstLvlMenuItem.children.some((item) => {
                if (item.uri) {
                    return location.pathname.includes(item.uri);
                } else {
                    return item.children.some((child) => location.pathname.includes(child.uri));
                }
            });
        }
    };

    return (
        <>
            <li className={classNames(styles.linkLayout, isNavItemActive() && styles.active)}>
                {firstLvlMenuItem.uri ? (
                    <FMHLink
                        className={classNames('ff-mb-0', styles.menuItem, styles.withLink)}
                        href={`${firstLvlMenuItem.uri}`}
                    >
                        {firstLvlMenuItem.title}
                    </FMHLink>
                ) : (
                    <button className="d-flex align-items-center" onClick={handleOpenSubMenu}>
                        <FFHeading
                            className={classNames('ff-mb-0 ff-mr-8', styles.menuItem, openSubMenu && styles.active)}
                            text={firstLvlMenuItem.title}
                            size={HeadingSizeProp.H5}
                        />
                        {firstLvlMenuItem.children.length > 0 &&
                            (openSubMenu ? (
                                <Icon
                                    icon={IconType.ChevronUp}
                                    className={classNames(styles.icon, openSubMenu && styles.active)}
                                />
                            ) : (
                                <Icon
                                    icon={IconType.ChevronDown}
                                    className={classNames(styles.icon, openSubMenu && styles.active)}
                                />
                            ))}
                    </button>
                )}
                {firstLvlMenuItem.children.length > 0 && openSubMenu && (
                    <FFNavSubMenu
                        toggledClassName={classNames('ff-py-8', styles.subMenu)}
                        secondLvlMenu={firstLvlMenuItem.children}
                        previousTitle={firstLvlMenuItem.title}
                        dropdownLock={dropdownLock}
                        dropdownUnlock={dropdownUnlock}
                        menuOpened={openSubMenu}
                        activeChildIndex={activeChildIndex}
                        ref={elementRef}
                    />
                )}
            </li>
        </>
    );
};

export default FCNavItem;
