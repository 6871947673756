import { IconType } from 'src/components/icon-generator/dto';
import styles from './bottom-navigation-bar-mobile.module.scss';
import { NavItem } from './components/nav-item';
import BottomNavigationBarMobileProps from './dto';

const BottomNavigationBarMobile = ({
    openSideMenu,
    setOpenSideMenu,
    handleOpenSideMenu,
    quickNavigationLinks,
}: BottomNavigationBarMobileProps): JSX.Element => {
    return (
        <div className={styles.root}>
            <NavItem
                label="Home"
                link="/"
                iconType={IconType.BottomNavHome}
                isActive={
                    !openSideMenu && !quickNavigationLinks.some((m) => window.location.pathname.endsWith(m.link.uri))
                }
                onClick={() => setOpenSideMenu(false)}
            />
            {quickNavigationLinks.map((link, index) => (
                <NavItem
                    key={`${link.link.text}${link.link.uri}${index}`}
                    label={link.link.text}
                    link={link.link.uri}
                    image={link.image}
                    isActive={!openSideMenu && window.location.pathname.endsWith(link.link.uri)}
                    onClick={() => setOpenSideMenu(false)}
                />
            ))}
            <NavItem
                label="Menu"
                iconType={IconType.BottomNavHamburger}
                isActive={openSideMenu}
                onClick={handleOpenSideMenu}
            />
        </div>
    );
};

export default BottomNavigationBarMobile;
