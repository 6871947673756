import { FC, useContext } from 'react';
import { FMHLink } from 'src/atoms/fmh-link';
import { ConfigurationContext } from 'src/App';
import FMHCheckboxWithLabel from '../fmh-checkbox-with-label/fmh-checkbox-with-label';
import styles from './fmh-terms-and-conditions.module.scss';
import FMHTermsAndConditionsCheckProps from './dto';

const FMHTermsAndConditionsCheck: FC<FMHTermsAndConditionsCheckProps> = (
    props: FMHTermsAndConditionsCheckProps,
): JSX.Element => {
    const config = useContext(ConfigurationContext);

    return (
        <FMHCheckboxWithLabel onValueChange={props.onValueChange} isChecked={props.isChecked}>
            <div className={`${styles.termsAndConditions} ${props.className}`}>
                {'I agree to '}
                {config?.pageConfigurationData?.mediaDownloadTermsAndConditionsLink ? (
                    <FMHLink
                        className={styles.downloadPromptLink}
                        href={config?.pageConfigurationData?.mediaDownloadTermsAndConditionsLink.uri}
                        openInNewTab={true}
                    >
                        {config?.pageConfigurationData?.mediaDownloadTermsAndConditionsLink.text}
                    </FMHLink>
                ) : (
                    'FIFA’s Terms and conditions'
                )}
                *
            </div>
        </FMHCheckboxWithLabel>
    );
};

export default FMHTermsAndConditionsCheck;
