import classNames from 'classnames';
import Styles from './grid-section-skeleton.module.scss';

const GridSectionSkeleton = () => {
    return (
        <section className="ff-section">
            <div className={classNames(Styles.wrapper, 'container')}>
                <div className={Styles.grid}>
                    {Array.from(Array(8)).map((_, i) => (
                        <div className={Styles.square} key={i}></div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default GridSectionSkeleton;
