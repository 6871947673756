import { useEffect, useState } from 'react';
import { checkIfExternalLink } from 'src/atoms/fmh-link/fmh-link';
import { Icon } from 'src/components/icon-generator';
import { IconType } from 'src/components/icon-generator/dto';
import FMHPopupBlockerBannerProps from './dto';
import styles from './fmh-popup-blocker-banner.module.scss';

const FMHPopupBlockerBanner = (props: FMHPopupBlockerBannerProps): JSX.Element | null => {
    const [shouldBeShowing, setShouldBeShowing] = useState<boolean>(false);

    useEffect(() => {
        const newWindow = window.open();

        if (newWindow == null) {
            setShouldBeShowing(true);
        } else {
            newWindow.close();
        }
    }, []);

    if (!shouldBeShowing) return null;

    const MessageBody = (): JSX.Element => {
        return (
            <div
                className={`${props.onClickFunc ? styles.clickable : ''} d-flex align-items-center`}
                onClick={props.onClickFunc ?? undefined}
            >
                {props.message}
                {props.onClickFunc && (
                    <Icon icon={IconType.ChevronRight} className={styles.arrowIcon} width="10" height="10" />
                )}
            </div>
        );
    };

    const LinkMessageBody = (): JSX.Element => {
        if (!props.link) return <></>;

        return checkIfExternalLink(props.link.uri) ? (
            <a href={props.link.uri} className={`ff-mb-0`} target={'_blank'} rel={'noreferrer'}>
                {props.message}
                <Icon icon={IconType.ChevronRight} className={styles.arrowIcon} width="10" height="10" />
            </a>
        ) : (
            <a href={props.link.uri} className={`ff-mb-0`}>
                {props.message}
                <Icon icon={IconType.ChevronRight} className={styles.arrowIcon} width="10" height="10" />
            </a>
        );
    };

    return (
        <div
            className={`${styles.notificationBanner} ${props.sticky ? styles.sticky : ''}
         ${props.className ?? ''}`}
        >
            <div className="container">
                <div className={`h-100 ${styles.notificationInnerDiv}`}>
                    <div
                        className={`ff-mb-0 ff-px-4 ff-px-md-8 flex-row justify-content-center justify-content-md-start ${styles.message}`}
                    >
                        <div className={`ff-my-0`}>{props.link ? <LinkMessageBody /> : <MessageBody />}</div>
                    </div>

                    <div className={`ff-px-0 ${styles.iconContainer}`}>
                        {props.onBannerCloseClick && (
                            <Icon
                                icon={IconType.Cross}
                                onClick={props.onBannerCloseClick}
                                className={`${styles.icon}`}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FMHPopupBlockerBanner;
