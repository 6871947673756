import React, { FC, useRef } from 'react';
import { MenuItem } from 'src/components/layout/dto';
import { NavLink } from 'reactstrap';
import { ApplicationPaths } from 'src/components/authorization/ApiAuthorizationConstants';
import { Link } from 'react-router-dom';
import { Icon } from 'src/components/icon-generator';
import { IconType } from 'src/components/icon-generator/dto';
import itemStyles from '../../molecules/ff-mobile-header-menu-first-level-item/ff-mobile-header-menu-first-level-item.module.scss';
import styles from './ff-mobile-side-bar-menu.module.scss';
import { FFMobileHeaderMenuFirstLevelItem } from '@/molecules/ff-mobile-header-menu-first-level-item';

type MobileSidebarProps = {
    firstLvlMenu: Array<MenuItem>;
    closeMenu: any;
    shouldIncludeLogoutBtn: boolean;
    styles?: React.CSSProperties;
};

const FFMobileSidebarMenu: FC<MobileSidebarProps> = (props: MobileSidebarProps): JSX.Element => {
    const elementRef = useRef<HTMLDivElement>(null);

    const mobileMenu = props.firstLvlMenu.map((firstLevelItem, i) => {
        return (
            <li
                key={firstLevelItem.id || `first-level-item-${i}`}
                id={firstLevelItem.id || `first-level-item-${i}`}
                className={styles.linkLayout}
            >
                <FFMobileHeaderMenuFirstLevelItem
                    title={firstLevelItem.title}
                    uri={firstLevelItem.uri}
                    active={firstLevelItem.isActive}
                    parentItem={firstLevelItem}
                    menuItems={firstLevelItem.children}
                    hasActiveChild={firstLevelItem.hasActiveChild}
                    closeMenu={props.closeMenu}
                    trailingIcon={firstLevelItem.trailingIcon}
                />
            </li>
        );
    });

    return (
        <div
            className={styles.root}
            onClick={(e: React.SyntheticEvent<HTMLDivElement>) => {
                if (e.target === e.currentTarget) {
                    e.stopPropagation();
                    props.closeMenu();
                }
            }}
            style={props.styles}
            ref={elementRef}
        >
            <div className={styles.menu}>
                <ul className={styles.mobileMenu}>
                    {mobileMenu}
                    {props.shouldIncludeLogoutBtn && (
                        <li>
                            <NavLink
                                tag={Link}
                                to={{ pathname: ApplicationPaths.LogOut, state: { local: true } }}
                                className="d-flex flex-row justify-content-between ff-mb-0 ff-px-24 ff-py-16"
                            >
                                <p className={itemStyles.firstLevelTitle}>Logout</p>
                                <Icon icon={IconType.Logout} width="20" height="20" className={styles.logoutIcon} />
                            </NavLink>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default FFMobileSidebarMenu;
