import { FC } from 'react';
import classNames from 'classnames';
import { FMHLanguageDropdown } from 'src/atoms/fmh-language-dropdown';
import { DropdownItem } from 'src/atoms/fmh-language-dropdown/dto';
import { IconType } from 'src/components/icon-generator/dto';
import { languageOptionsSort } from 'src/utils/languagesSort';
import { FMHBreadcrumbs } from '../fmh-breadcrumbs';
import FMHBreadcrumbsBannerProps from './dto';
import Styles from './fmh-breadcrumbs-banner.module.scss';

const FMHBreadcrumbsBanner: FC<FMHBreadcrumbsBannerProps> = (props: FMHBreadcrumbsBannerProps): JSX.Element => {
    const getOptionsFromLanguages = () => {
        const dropdownItems = props.languagesDropdown!.sort((a, b) => {
            return languageOptionsSort(a.language, b.language, props.locale);
        });

        return dropdownItems.map((ld): DropdownItem => {
            const isRtl = ld.language.id === 'ar';
            return {
                label: ld.link?.text,
                value: ld.language.id!,
                uri: `${ld.link?.uri}`,
                labelPrefix: !isRtl ? `${ld.language.text} - ` : ` - ${ld.language.text}`,
                isRtl,
            };
        });
    };

    const currentLanguageWithLink = props?.languagesDropdown?.find((ld) => ld.language.id === props.locale);

    return (
        <div className={classNames('container', Styles.breadcrumbsWrapper, props.className)}>
            <div className="row">
                <div className="col-9 col-md-8">{props.menu && <FMHBreadcrumbs menu={props.menu} />}</div>
                {props.languagesDropdown && props.languagesDropdown.length > 1 && (
                    <div className={`col-3 col-md-4 d-flex align-items-center ${Styles.languageDropdownContainer}`}>
                        <FMHLanguageDropdown
                            options={getOptionsFromLanguages()}
                            currentSelected={props.locale}
                            icon={IconType.World}
                            selectedLabel={currentLanguageWithLink?.language.text ?? ''}
                            selectedLabelMobile={currentLanguageWithLink?.language.id ?? ''}
                            suffixLabel={`Other languages (${props.languagesDropdown.length - 1})`} // to be changed with labels feature
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default FMHBreadcrumbsBanner;
