import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory, BrowserHistoryOptions } from 'history';

const browserHistory = createBrowserHistory({ basename: '' } as BrowserHistoryOptions);
const reactPlugin = new ReactPlugin();
const key = process.env.REACT_APP_APPINSIGHTS_KEY;

const appInsights: ApplicationInsights | null = null;
if (key) {
    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: key,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: browserHistory },
            },
        },
    });

    appInsights.loadAppInsights();
}

export { reactPlugin, appInsights };
