/* eslint-disable react/jsx-no-target-blank */
import { FC, useState } from 'react';
import { FFHeading, HeadingSizeProp } from 'src/atoms/ff-heading';
import { FMHArrowButton } from 'src/atoms/fmh-arrow-button';
import { FMHHighlightLabel } from 'src/atoms/fmh-highlight-label';
import FMHLiveScore from 'src/atoms/fmh-live-score/fmh-live-score';
import { Icon } from 'src/components/icon-generator';
import LiveGamesService from 'src/services/live-games-service';
import { isLinkInternal } from 'src/utils/check';
import LiveGamesHeaderBottomRow from '../ff-live-games-header/live-games-header-bottom-row/live-games-header-bottom-row';
import FMHLiveGamesHeaderMobileProps from './dto';
import Styles from './fmh-live-games-header-mobile.module.scss';
import GameDetailedStatistics from './../fmh-game-detailed-statistics/fmh-game-detailed-statistics';
import { ReactComponent as FFDownArrowIcon } from '@/icons/ff-chevron/ff-chevron-down.svg';

const FMHLiveGamesHeaderMobile: FC<FMHLiveGamesHeaderMobileProps> = ({
    liveGamesPageUrl,
    funcNext,
    funcPrevious,
    hasPreviousGame,
    hasNextGame,
    liveGame,
    documents,
    showDynamicControls,
    includeSeasonName,
    isGameAvailable = true,
    isExpandable,
    link,
}): JSX.Element => {
    const isOpenNewTab = liveGamesPageUrl ? !isLinkInternal(liveGamesPageUrl) : false;
    const showControls = funcPrevious && funcNext && showDynamicControls ? true : false;
    const [isToggled, setIsToggled] = useState<boolean>(false);

    const expandMenuButtonPressed = () => {
        setIsToggled((prevState) => !prevState);
    };

    return (
        <div className={Styles.wrapper}>
            {showControls && (
                <div className={`ff-px-24 ff-py-8 ${Styles.topRow}`}>
                    {liveGame?.tournamentLogo ? (
                        <img src={`${liveGame?.tournamentLogo}`} alt="image" />
                    ) : (
                        <FMHHighlightLabel text="Today" />
                    )}
                    <a
                        className={`ff-m-0 ${Styles.viewMore}`}
                        href={liveGamesPageUrl ?? undefined}
                        target={isOpenNewTab ? '_blank' : ''}
                        rel={isOpenNewTab ? 'noopener noreferrer' : ''}
                    >
                        {liveGamesPageUrl && (
                            <>
                                <span>View More</span>
                                <span className="ff-ml-8">
                                    <Icon icon="chevronRight" width={14} height={20} />
                                </span>
                            </>
                        )}
                    </a>
                </div>
            )}
            <div className={`ff-pt-24 ff-pb-16 ${Styles.middleRow}`}>
                {isGameAvailable ? (
                    <FMHLiveScore liveGame={liveGame} />
                ) : (
                    <FFHeading
                        className={`ff-m-0 ${Styles.noMatchTodayText}`}
                        size={HeadingSizeProp.H3}
                        text="No matches scheduled Today"
                    />
                )}
                {showControls && isGameAvailable && (
                    <div className={`${Styles.arrowButtons}`}>
                        <FMHArrowButton
                            disabled={!hasPreviousGame}
                            className={`ff-mr-8`}
                            direction={'left'}
                            func={funcPrevious}
                        />
                        <FMHArrowButton disabled={!hasNextGame} direction={'right'} func={funcNext} />
                    </div>
                )}
            </div>
            {liveGame && (
                <LiveGamesHeaderBottomRow
                    isLive={liveGame?.status === 'Live'}
                    isFinished={liveGame?.status === 'Played'}
                    items={LiveGamesService.getMatchData(liveGame, includeSeasonName)}
                    documents={documents}
                />
            )}
            {isExpandable && (
                <button
                    className={`ff-px-0 ${Styles.filesDownloadButton} ${isToggled ? Styles.isToggled : ''}`}
                    onClick={expandMenuButtonPressed}
                >
                    <FFDownArrowIcon className={`${Styles.chevronIcon}`} />
                </button>
            )}
            {isToggled && <GameDetailedStatistics gameEvents={liveGame?.gameEvents} link={link} />}
        </div>
    );
};

export default FMHLiveGamesHeaderMobile;
