import { Logger } from './logger';

export const BytesToMegaBytes = (bytes: number) => {
    return Math.round((bytes / 1024.0 / 1024.0) * 100) / 100;
};

/** @param code: 2 character country code, e.g. 'DE' @param locale: e.g. 'ar' */
export const countryCodeToCountryName = (code: string, locale = 'en'): string | undefined => {
    if (!code) return;

    const names = new Intl.DisplayNames([locale], { type: 'region' });
    try {
        return names.of(code);
    } catch (e) {
        Logger.error(`Cannot convert country code to name: ${e}`);
    }
};

/** @param code: 2 character language code, e.g. 'es' @param locale: e.g. 'en' */
export const languageCodeToLanguageName = (code: string, locale = 'en'): string | undefined => {
    if (!code) return;

    const names = new Intl.DisplayNames([locale], { type: 'language' });
    try {
        return names.of(code);
    } catch (e) {
        Logger.error(`Cannot convert language code to name: ${e}`);
    }
};

export const stringToHtmlId = (str: string): string => {
    return str.trim().toLowerCase().replace(/ /g, '-');
};
