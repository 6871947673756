/* eslint-disable react/jsx-no-target-blank */
import { FC } from 'react';
import { Icon } from 'src/components/icon-generator';
import { isLinkInternal } from 'src/utils/check';
import FMHGameDetailedStatisticsProps from './dto';
import styles from './fmh-game-detailed-statistics.module.scss';
import DetailedStatisticsPlayer from '@/atoms/fmh-detailed-statistics-player/fmh-detailed-statistics-player';

const FMHGameDetailedStatistics: FC<FMHGameDetailedStatisticsProps> = ({
    gameEvents,
    link,
}: FMHGameDetailedStatisticsProps) => {
    const isOpenNewTab = link ? !isLinkInternal(link) : false;

    const gamesEvents = gameEvents?.map((gameEvent, index) => {
        return (
            <DetailedStatisticsPlayer
                teamType={gameEvent.teamType}
                playerName={gameEvent.playerName}
                minute={gameEvent.minute}
                index={index}
                card={gameEvent.card}
                eventType={gameEvent.eventType}
                playerOffName={gameEvent.playerOffName}
                playerOnName={gameEvent.playerOnName}
                key={index}
            />
        );
    });

    return (
        <>
            <div className={`${styles.statisticsPanel}`}>
                <ul>{gamesEvents}</ul>
                <a
                    className={`${styles.viewMore}`}
                    href={link ?? undefined}
                    target={isOpenNewTab ? '_blank' : ''}
                    rel={isOpenNewTab ? 'noopener noreferrer' : ''}
                >
                    {link && (
                        <>
                            <div className={`${styles.label}`}>View More</div>
                            <div className={`${styles.arrow}`}>
                                <Icon icon="chevronRight" width={14} height={20} />
                            </div>
                        </>
                    )}
                </a>
            </div>
        </>
    );
};

export default FMHGameDetailedStatistics;
