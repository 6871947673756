import classNames from 'classnames';
import Styles from './header-skeleton.module.scss';

const HeaderSkeleton = () => {
    return (
        <>
            <div className={Styles.header}>
                <div
                    className={classNames(
                        Styles.containerWrapper,
                        'd-flex flex-row align-items-center justify-content-between justify-content-lg-center container',
                    )}
                >
                    <span className={classNames(Styles.circle, 'order-1 order-lg-2 d-lg-none')}></span>
                    <span className={classNames(Styles.rectangle, 'order-2 order-lg-1')}></span>
                    <span className={classNames(Styles.circle, 'order-3 d-lg-none')}></span>

                    <div className={classNames(Styles.wrapper, 'd-none d-lg-flex order-3')}>
                        <span className={Styles.circle}></span>
                        <span className={Styles.rectangle2}></span>
                        <span className={Styles.circle}></span>
                    </div>
                </div>
            </div>
            <div className={classNames(Styles.subHeader, 'd-none d-lg-flex')}>
                <div className="d-flex flex-row align-items-center justify-content-center container">
                    {Array.from(Array(5)).map((_, i) => (
                        <span className={Styles.rectangle} key={i}></span>
                    ))}
                </div>
            </div>
        </>
    );
};

export default HeaderSkeleton;
