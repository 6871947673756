import classNames from 'classnames';
import Styles from './footer-skeleton.module.scss';

const FooterSkeleton = () => {
    return (
        <>
            <div
                className={classNames(
                    Styles.container,
                    'container d-flex flex-column align-items-center justify-content-center',
                )}
            >
                <div className={classNames(Styles.rectangle, Styles.titleSkeleton)}></div>
                <div
                    className={classNames(
                        Styles.logoStrips,
                        'd-flex flex-column flex-md-row flex-wrap align-items-center justify-content-center',
                    )}
                >
                    {Array.from(Array(5)).map((_, i) => (
                        <div className={Styles.rectangle2} key={i}></div>
                    ))}
                </div>
            </div>
            <div className={Styles.footer}>
                <div
                    className={classNames(
                        Styles.wrapper,
                        'container d-flex flex-column flex-sm-row align-items-center ff-px-8 ff-px-md-32 ff-py-16 ff-py-lg-8',
                    )}
                >
                    <div className={Styles.rectangle3}></div>
                    {Array.from(Array(4)).map((_, i) => (
                        <div className={Styles.rectangle} key={i}></div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default FooterSkeleton;
