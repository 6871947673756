import { FC, useCallback } from 'react';
import { Size } from '../ff/ff-button/dto';
import FMHHighlightLabelProps from './dto';
import Styles from './fmh-highlight-label.module.scss';

const FMHHighlightLabel: FC<FMHHighlightLabelProps> = ({ text, className = '', size = Size.Medium }): JSX.Element => {
    const getLabelSize = useCallback(() => {
        switch (size) {
            case Size.Small:
                return Styles.small;
            default:
                return Styles.medium;
        }
    }, [size]);

    return (
        <div className={`ff-px-16 ff-py-4 ${Styles.wrapper} ${getLabelSize()} ${className}`}>
            <span className={Styles.text}>{text}</span>
        </div>
    );
};

export default FMHHighlightLabel;
