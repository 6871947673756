import { FC } from 'react';
import { FMHLink } from 'src/atoms/fmh-link';
import { Icon } from 'src/components/icon-generator';
import styles from './ff-button.module.scss';
import FFButtonProps, { ButtonType, Size } from './dto';

const FFButton: FC<FFButtonProps> = (props: FFButtonProps): JSX.Element => {
    const size = props.size || Size.Medium;
    const loading = props.loading || false;
    const kind = props.kind || ButtonType.Primary;
    const tabIndex = props.disabled ? -1 : 0;

    const getButtonType = (): string => {
        switch (kind) {
            case ButtonType.Primary:
                return styles.button__primary;
            case ButtonType.PrimaryReversed:
                return styles.button__primary__reversed;
            case ButtonType.Secondary:
                return styles.button__secondary;
            case ButtonType.Tertiary:
                return styles.button__tertiary;
            case ButtonType.PureText:
                return styles.button__pure__text;
            case ButtonType.Transparent:
                return styles.button__transparent;
            default:
                return '';
        }
    };

    const getButtonSize = (): string => {
        switch (size) {
            case Size.Small:
                return styles.button__sm;
            case Size.Medium:
                return styles.button__md;
            case Size.Large:
                return styles.button__lg;
            default:
                return '';
        }
    };

    const getCommonStyle = (): string => {
        const className = props.className ?? '';
        const disabledStyle = props.disabled ? styles.button__disabled : '';
        const loadingStyle = loading && !props.link && !props.disabled ? styles.button__loading : '';
        return `${className} ${disabledStyle} ${loadingStyle}`;
    };

    const renderChildren = (): JSX.Element => {
        return (
            <>
                {props.prefixIcon && <Icon width={16} height={16} className="ff-mr-8" icon={props.prefixIcon} />}
                {props.text && props.text}
                {props.postfixIcon && <Icon width={16} height={16} className="ff-ml-8" icon={props.postfixIcon} />}
            </>
        );
    };

    const buttonElement = props.link ? (
        <FMHLink
            href={props.link ?? undefined}
            tabIndex={tabIndex}
            onClick={props.func}
            previousPageTitle={props.previousPageTitle}
            className={`ff-mb-0 ${styles.button__atom__link} ${getCommonStyle()} ${getButtonSize()} ${getButtonType()}`}
        >
            {renderChildren()}
        </FMHLink>
    ) : (
        <button
            tabIndex={tabIndex}
            onClick={props.func}
            className={`ff-mb-0 ${styles.button__atom__link} ${getCommonStyle()} ${getButtonSize()} ${getButtonType()}`}
        >
            {renderChildren()}
        </button>
    );

    return buttonElement;
};

export default FFButton;
