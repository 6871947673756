import classNames from 'classnames';
import { FFButton } from 'src/atoms/ff/ff-button';
import { Icon } from 'src/components/icon-generator';
import { IconType } from 'src/components/icon-generator/dto';
import { ButtonType } from 'src/atoms/ff/ff-button/dto';
import Styles from './pwa-install-landing.module.scss';
import PWAInstallLandingProps from './dto';
import { Loading } from '@/components/loading';

const PWAInstallLanding = ({ handleInstall, isButtonEnabled }: PWAInstallLandingProps) => {
    return (
        <div className={classNames(Styles.container)}>
            <div className={classNames(Styles.innerContainer)}>
                <Icon icon={IconType.FMHLogo} className={Styles.logo} />
                <p className={classNames(Styles.description, 'ff-my-32')}>
                    For the best experience on the FIFA Media Hub, we recommend installing our mobile app so you have
                    quick access to features on the go or offline.
                </p>
                {isButtonEnabled ? (
                    <FFButton
                        text="Install"
                        kind={ButtonType.Primary}
                        postfixIcon={IconType.ArrowRight}
                        func={handleInstall}
                        className={Styles.installButton}
                    />
                ) : (
                    <Loading />
                )}
            </div>
            <div className={classNames(Styles.background, 'ff-mt-32')} />
        </div>
    );
};

export default PWAInstallLanding;
