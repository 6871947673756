import { FC, useEffect, useRef, useState, KeyboardEvent, MutableRefObject, useCallback } from 'react';
import { Icon } from 'src/components/icon-generator';
import { objectToQuerystring } from 'src/utils/ff/url-query-helper';
import { removeTag } from 'src/utils/fc/htmlString';
import { FMHLink } from 'src/atoms/fmh-link';
import { Maybe } from 'src/types/maybe';
import SearchService from 'src/services/search-service';
import ResourcesSingleton from 'src/utils/resource';
import { useHistory } from 'react-router-dom';
import { SearchQuery } from 'src/templates/search-page-template/dto';
import { ResourceSetElasticSearch } from 'src/constants/resource-identifiers';
import styles from './ff-search-bar.module.scss';
import FFSearchBarProps from './dto';

const MAX_INPUT_LENGTH = 2048;

const FFSearchBar: FC<FFSearchBarProps> = ({
    currentValue,
    textFieldName,
    clicked,
    searchPageUrl,
    isFifacomSuggestionsEnabled = true,
}: FFSearchBarProps) => {
    const [value, setValue] = useState<string>('');
    const [suggestions, setSuggestions] = useState<Array<string>>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedSuggestion, _setSelectedSuggestion] = useState<Maybe<number>>(null);
    const [canFetchSuggestions, setCanFetchSuggestions] = useState(true);
    const [isInputActive, setIsInputActive] = useState(false);

    const searchRef = useRef<HTMLDivElement | null>(null);
    const suggestionsRef = useRef<HTMLDivElement | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const previousValueRef = useRef(currentValue?.trim());

    const history = useHistory();
    const isSuggestionResultsShown = isOpen && suggestions?.length > 0;
    const placeholderText = ResourcesSingleton.getLabel(
        ResourceSetElasticSearch._Identifier,
        ResourceSetElasticSearch.Placeholder,
    );

    const setSelectedSuggestion = (val: number) => {
        if (val === 0 || suggestions.length === 0) {
            return;
        }
        let defaultValue: number;
        if (val > 0) {
            defaultValue = selectedSuggestion ?? -1;
        } else {
            defaultValue = (selectedSuggestion ?? suggestions.length) + suggestions.length;
        }
        _setSelectedSuggestion((defaultValue + val) % suggestions.length);
    };

    useEffect(() => {
        if (selectedSuggestion || selectedSuggestion === 0) {
            setSearchString(removeTag(suggestions[selectedSuggestion]).trim());
        }
    }, [selectedSuggestion, suggestions]);

    const setSearchString = (searchString: string) => {
        setValue(searchString ? searchString : '');
    };

    const wasClicked = (ref: MutableRefObject<HTMLDivElement | null>, e: MouseEvent) =>
        ref.current?.contains(e.target as Node) ?? false;
    const search = () => buttonRef.current?.click();
    const searchUrl = (queryValue: string) =>
        `${searchPageUrl}${objectToQuerystring({
            searchPhrase: removeTag(queryValue.trim()),
        } as SearchQuery)}`;

    useEffect(() => {
        // Focus on search bar every time is gets displayed.
        if (clicked) {
            return inputRef.current?.focus();
        }
        inputRef.current?.blur();
    }, [clicked]);

    const getSuggestionsWithTimeout = useCallback(
        (value: string) => {
            if (value.length >= 3 && canFetchSuggestions) {
                SearchService.getSearchSuggestions(value, isFifacomSuggestionsEnabled).then((suggestions) => {
                    if (suggestions) {
                        setSuggestions(suggestions);
                        setIsOpen(suggestions.length > 0 && value !== currentValue);
                    }
                });
            }
        },
        [canFetchSuggestions, currentValue, isFifacomSuggestionsEnabled],
    );

    const handleSpecial = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.nativeEvent.code === 'Escape') {
            setValue('');
        } else if (event.nativeEvent.code === 'Enter') {
            search();
        } else if (event.nativeEvent.code === 'ArrowDown') {
            setSelectedSuggestion(1);
            setCanFetchSuggestions(false);
            return;
        } else if (event.nativeEvent.code === 'ArrowUp') {
            setSelectedSuggestion(-1);
            setCanFetchSuggestions(false);
            return;
        }
        setCanFetchSuggestions(true);
    };

    const handleClick = useCallback((e: MouseEvent) => {
        if (wasClicked(suggestionsRef, e)) {
            // suggestion clicked
            return;
        } else if (wasClicked(searchRef, e)) {
            // searchbar clicked
            setIsOpen(true);
            return;
        } else {
            // outside click
            setIsOpen(false);
        }
    }, []);

    useEffect(() => {
        setValue(currentValue?.trim() ?? '');
    }, [currentValue]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [handleClick]);

    useEffect(() => {
        if (previousValueRef.current === value) return;

        const trimValue = value.trim();
        previousValueRef.current = trimValue;

        const timeOut = setTimeout(() => getSuggestionsWithTimeout(trimValue), 500);

        return () => clearTimeout(timeOut);
    }, [getSuggestionsWithTimeout, value]);

    return (
        <>
            <div
                ref={searchRef}
                className={`row d-flex g-0 ff-mb-0 ${
                    isSuggestionResultsShown ? styles.searchBarActive : styles.searchBar
                }`}
            >
                <div className="col-10 col-md-11 g-0">
                    <input
                        tabIndex={0}
                        type={'text'}
                        value={value}
                        maxLength={MAX_INPUT_LENGTH}
                        name={textFieldName ?? ''}
                        placeholder={placeholderText}
                        className={`${styles.textField} ${isSuggestionResultsShown && styles.searchActive}`}
                        onChange={(event) => setSearchString(event?.target?.value)}
                        onKeyDown={handleSpecial}
                        aria-label={placeholderText}
                        ref={inputRef}
                        onFocus={() => setIsInputActive(true)}
                        onBlur={() => setIsInputActive(false)}
                    />
                </div>
                <button
                    ref={buttonRef}
                    disabled={value?.trim().length <= 0}
                    onClick={() => {
                        history.push(searchUrl(value));
                        setIsOpen(false);
                    }}
                    className={`col-2 col-md-1 ff-mb-0 ff-bg-blue-lighter d-flex align-items-center justify-content-center ${
                        styles.searchIconActiveWrapper
                    } ${(isSuggestionResultsShown || isInputActive) && styles.searchActive} ${
                        !isSuggestionResultsShown && isInputActive && styles.borderActive
                    }`}
                >
                    <Icon icon="search" width="32" height="32" viewBox="0 0 28 28" className={`${styles.searchIcon}`} />
                </button>
            </div>
            {isSuggestionResultsShown && (
                <div ref={suggestionsRef} className={`row ${styles.relative}`}>
                    <div className={`col-12 d-flex flex-column ff-text-white ${styles.searchResultsContainer}`}>
                        {suggestions.map((suggestion, index) =>
                            suggestion ? (
                                <FMHLink
                                    key={index}
                                    href={searchPageUrl}
                                    search={objectToQuerystring({
                                        searchPhrase: removeTag(suggestion.trim()),
                                    } as SearchQuery)}
                                    onClick={() => setIsOpen(false)}
                                    className={`ff-bg-white ff-text-grey-slate ff-p-16 ff-px-md-24 ff-mb-0 ${
                                        styles.searchItem
                                    } ${index === selectedSuggestion && styles.activeSearchItem} `}
                                >
                                    <div dangerouslySetInnerHTML={{ __html: suggestion }} />
                                </FMHLink>
                            ) : null,
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default FFSearchBar;
