import Dexie, { Table } from 'dexie';
import { BusHotelClusterMapping, BusLocationCodeMapping, BusRoute, UserData } from 'src/types/database-types';

export class MediaHubIndexedDb extends Dexie {
    // All tables are added by dexie when declaring the stores()
    // We just tell the typing system this is the case
    busHotelClusterMappings!: Table<BusHotelClusterMapping>;
    busLocationCodeMappings!: Table<BusLocationCodeMapping>;
    busRoutes!: Table<BusRoute>;
    userData!: Table<UserData>;

    constructor() {
        super('media-hub');
        this.version(2).stores({
            busHotelClusterMappings:
                'id,accommodationName,accommodationCoordinates,clusterName,pickupPoint,pickupPointCode,pickupPointCoordinates,walkingTimeInMinutes',
            busLocationCodeMappings: 'code,description,category,country,city',
            busRoutes:
                'id,routeCode,routeDescription,originCode,originDescription,originCategory,destinationCode,destinationDescription,destinationCategory,departureTime,arrivalTime,oldDepartureTime,oldArrivalTime',
            userData: 'key,token',
        });
    }
}

export const mediaHubDb = new MediaHubIndexedDb();
