import { FC } from 'react';
import classNames from 'classnames';
import styles from './ff-image.module.scss';
import FFImageProps from './dto';
import { getCorrectImageSrc } from './ff-image-utils';
import { isSourceTransformable, useBynderOnload } from '@/utils/ff/bynder-transform';

const FFImage: FC<FFImageProps> = (props: FFImageProps): JSX.Element | null => {
    const { isVerticalImage, handleImageLoad } = useBynderOnload(props.src);

    // Default aspect ratio of 16-by-9 if not specified in props
    let aspectRatioWidth: number | null = null;
    let aspectRatioHeight: number | null = null;
    if (
        props.aspectRatioWidth &&
        props.aspectRatioWidth !== '' &&
        props.aspectRatioHeight &&
        props.aspectRatioHeight !== ''
    ) {
        // Parse to integers
        aspectRatioWidth = parseInt(props.aspectRatioWidth);
        aspectRatioHeight = parseInt(props.aspectRatioHeight);
    }

    const handleOnClick = (e) => {
        if (props.onClick != null) {
            props.onClick(e);
        }
    };

    const aspectRatio = !isSourceTransformable(props.src) ? `${aspectRatioWidth}/${aspectRatioHeight}` : '';

    const picture: JSX.Element = (
        <img
            decoding="async"
            loading={props.isEager ? 'eager' : 'lazy'}
            height={props.height}
            width={props.width}
            src={getCorrectImageSrc(props, aspectRatioWidth, aspectRatioHeight)}
            alt={props.alt}
            title={props.title}
            className={
                props.imageClassName ??
                classNames(styles.img, isVerticalImage ? styles.verticalImage : styles.horizontalImage)
            }
            onClick={handleOnClick}
            onLoad={handleImageLoad}
            style={{ aspectRatio }}
        />
    );

    return (
        <div className={`${styles.figureImage} ${props.className ? props.className : ''}`}>
            {props.caption ? (
                <figure className={styles.figure}>
                    {picture}
                    {props.caption && (
                        <figcaption className={`ff-my-8 ${styles.figcaption}`}>{props.caption}</figcaption>
                    )}
                </figure>
            ) : (
                picture
            )}
        </div>
    );
};

export default FFImage;
