import { PageConfigurationType } from 'src/types/apiDtos';
import { FFArticleCardProps } from 'src/molecules/ff/ff-article-card';
import { FMHDocumentGridProps } from 'src/organisms/fmh-document-grid';
import { DocumentGridRequestParameters } from 'src/organisms/fmh-document-grid/dto';
import { api } from '@/utils/api';
import { PageType } from '@/types/page-type';
import { Logger } from '@/utils/logger';

const getPageByRelativeUrl = async (relativeUrl: string, relativeUrlRaw: string | null): Promise<PageType> => {
    if (relativeUrl) {
        if (nextHttpRequestNoCacheCheck()) localStorage.removeItem('nextHttpRequestNoCache');

        const urlParams = new URLSearchParams(window.location.search);
        const preview = urlParams.get('preview') ?? 'false';
        const pageNumber = urlParams.get('pageNumber') ?? null;

        const res = await api.get(`/v1/pages${relativeUrl}&preview=${preview}&pageNumber=${pageNumber}`, {
            headers: { relativeUrlRaw },
        });

        return res.data;
    }

    Logger.error('RelativeUrl parameter missing');
    return Promise.reject();
};

const getPageConfiguration = async (langCode: string, pageRelativeUrl?: string): Promise<PageConfigurationType> => {
    nextHttpRequestNoCacheCheck();

    const url = `/v1/PageConfiguration?locale=${langCode}`;
    const res = await api.get(url, {
        params: {
            pageRelativeUrl: navigator.onLine ? pageRelativeUrl : null,
            name: navigator.onLine ? null : 'MediaHubOffline',
        },
    });
    return res.data;
};

const nextHttpRequestNoCacheCheck = () => {
    if (localStorage.getItem('nextHttpRequestNoCache')) {
        api.defaults.headers = {
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0',
        };
        return true;
    }
    return false;
};

const getArticlePageByArticlePageEntryId = async (entryId: string, langCode: string): Promise<PageType> => {
    const res = await api.get(`/v1/articlePageByArticlePageEntryId/${entryId}?locale=${langCode}`);
    return res.data;
};

const getArticlePageByPagination = async (
    path: string,
    skip: number,
    locale: string,
): Promise<FFArticleCardProps | null> => {
    try {
        const res = await api.get(path, { params: { skip, locale } });
        if (res.data) return res.data;
        return null;
    } catch (err) {
        Logger.error(`${err}`);
        return null;
    }
};

const getDocumentsPageByPagination = async (
    path: string,
    params: DocumentGridRequestParameters,
): Promise<FMHDocumentGridProps | null> => {
    try {
        const res = await api.get(path, { params });
        if (res.data) return res.data;
        return null;
    } catch (err) {
        Logger.error(`${err}`);
        return null;
    }
};

const PageService = {
    getPageByRelativeUrl,
    getPageConfiguration,
    getArticlePageByArticlePageEntryId,
    getArticlePageByPagination,
    getDocumentsPageByPagination,
};

export default PageService;
