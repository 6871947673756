import classNames from 'classnames';
import { FC } from 'react';
import { FMHLink } from 'src/atoms/fmh-link';
import { Icon } from 'src/components/icon-generator';
import { MenuItem } from 'src/components/layout/dto';
import { IconType } from 'src/components/icon-generator/dto';
import { isArrayInvalid } from 'src/utils/check';
import { FFMobileHeaderMenuThirdLevelItem } from '../ff-mobile-header-menu-third-level-item';
import styles from './ff-mobile-header-menu-second-level-item.module.scss';

type secondLevelProps = {
    title: string;
    uri?: string;
    open?: boolean;
    active: boolean;
    parentItem: MenuItem;
    menuItems?: Array<MenuItem>;
    hasActiveChild?: boolean;
    icon?: JSX.Element;
    closeMenu: () => void;
    click: () => void;
};

const FFMobileHeaderMenuSecondLevelItem: FC<secondLevelProps> = (props: secondLevelProps): JSX.Element => {
    const mobileMenu = props.menuItems?.map((item, i) => {
        return (
            <li key={i} id={`${i}`}>
                <FFMobileHeaderMenuThirdLevelItem
                    title={item.title}
                    uri={item.uri}
                    active={item.isActive}
                    closeMenu={props.closeMenu}
                />
            </li>
        );
    });

    return (
        <>
            <div
                className="ff-py-24 ff-px-16 ff-mb-0 d-flex justify-content-between align-items-center"
                onClick={props.click}
                role="button"
            >
                {props.uri ? (
                    <FMHLink href={props.uri} onClick={() => props.closeMenu()} className="ff-mb-0">
                        <div className="d-flex align-items-center">
                            {props.icon && <div className={styles.icon}>{props.icon}</div>}
                            <p className={styles.secondLevelTitle}>{props.title}</p>
                        </div>
                    </FMHLink>
                ) : (
                    <div className="d-flex align-items-center">
                        {props.icon && <div className={styles.icon}>{props.icon}</div>}
                        <p className={styles.secondLevelTitle}>{props.title}</p>
                    </div>
                )}
                <div
                    className={classNames(
                        'd-flex flex-row align-items-center justify-content-center',
                        props.menuItems?.length !== 0 && styles.expandIcon,
                    )}
                >
                    {!isArrayInvalid(props.menuItems) &&
                        (props.active ? <Icon icon={IconType.ChevronUp} /> : <Icon icon={IconType.ChevronDown} />)}
                </div>
            </div>
            {props.active && <ul>{mobileMenu}</ul>}
        </>
    );
};

export default FFMobileHeaderMenuSecondLevelItem;
