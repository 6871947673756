import axios, { AxiosError } from 'axios';
import { RedirectToLoginWithReturnUrl } from 'src/components/authorization/Login';
import { Logger } from '@/utils/logger';
import { AuthorizeService } from '@/components/authorization/AuthorizeService';

const FUNCTION_API_URL = process.env.REACT_APP_FUNCTION_API_URL;
const FUNCTION_API_KEY = process.env.REACT_APP_FUNCTION_API_KEY;
const authService = new AuthorizeService();

export const api = axios.create({
    baseURL: FUNCTION_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use(
    async (request) => {
        Logger.info(`[API] HTTP.${request.method?.toUpperCase()} ${request.url}`);

        const token = await authService.getAccessToken();
        request.headers['Authorization'] = `Bearer ${token}`;

        if (token) {
            request.params = { ...request.params, isPrivate: 'true' };
        } else {
            request.params = { ...request.params, isPublic: 'true' };
        }

        const impersonationTrigram = await authService.getImpersonationTrigram();
        if (impersonationTrigram) {
            request.headers['Mediahub-Impersonation-Trigram'] = `${impersonationTrigram}`;
        }

        request.headers['x-functions-key'] = `${FUNCTION_API_KEY}`;

        return request;
    },
    (error) => {
        return Promise.reject(error);
    },
);

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error: AxiosError) => {
        if (error.response) {
            if (error.response.status === 401) {
                if (error.response.config.headers.relativeUrlRaw) {
                    if (endpointIsPage(error.request.responseURL)) {
                        RedirectToLoginWithReturnUrl(error.response.config.headers.relativeUrlRaw);
                    } else if (endpointIsSection(error.request.responseURL)) {
                        Logger.info('Response 401/403 from section endpoint');
                    } else {
                        Logger.info('Response 401/403 from unknown endpoint');
                    }
                }
            }
            if (error.response.status === 403) {
                if (error.response.config.headers.relativeUrlRaw) {
                    if (endpointIsPage(error.request.responseURL)) {
                        window.location.replace('/forbidden');
                    } else if (endpointIsSection(error.request.responseURL)) {
                        Logger.info('Response 401/403 from section endpoint');
                    } else {
                        Logger.info('Response 401/403 from unknown endpoint');
                    }
                }
            }
        }

        return Promise.reject(error);
    },
);

const endpointIsPage = (relativeUrl: string) => {
    return relativeUrl.startsWith(`${FUNCTION_API_URL}/v1/pages`);
};

const endpointIsSection = (relativeUrl: string) => {
    return relativeUrl.startsWith(`${FUNCTION_API_URL}/v1/sections`);
};
export const apiEndpoints = {
    // accreditationSystem/accreditationStatus/{eventName}/{userTrigram?}
    accreditationStatus: `/v1/accreditationSystem/accreditationStatus`,
    // sections/accreditationStatusTemplate/{entryId}
    accreditationStatusSection: `/v1/sections/accreditationStatusTemplate`,
    // sections/accreditationFormTemplate/{entryId}/{userId?}
    accreditationFormSection: `/v1/sections/accreditationFormTemplate`,
    // sections/accreditationFormConfiguration/{pageConfigurationEntryId}/{eventName}/{userId?}
    accreditationFormConfiguration: `/v1/sections/accreditationFormConfiguration`,
    // "accreditationForm/{eventName}/{qmsCode}"
    postAccreditationFormEndpoint: `/v1/accreditationForm`,
    // sections/accreditationBlockSection/{entryId}
    accreditationBlockSectionByAccreditationPageEntryId: `/v1/sections/accreditationBlockSectionByAccreditationPageEntryId`,
    // sections/accreditationTypeTemplate/{entryId}
    proxyUserSectionEndpoint: '/v1/sections/accreditationTypeTemplate',
    // sections/accreditationTypeTemplateUsers/{eventName}/{organizationId}
    proxyUserSectionUsersEndpoint: '/v1/sections/accreditationTypeTemplateUsers',
    // busJourneys/{departureLocationCategory}/{departureLocationCode}/{destinationLocationCategory?}/{destinationLocationCode?}
    busScheduleJourneys: 'v1/busJourneys',
    busScheduleJourneysBeforeDeparture: 'v1/busJourneysBeforeDeparture',
    busScheduleJourneysAfterArrival: 'v1/busJourneysAfterArrival',
    busSchedulePossibleDestinations: 'v1/possibleDestinations',
    busScheduleCitiesAndCountries: 'v1/citiesAndCountries',
    // routeLocationNameById/{routeLocationId}
    busScheduleGetLocationNameById: 'v1/routeLocationNameById',
    subscribeBusScheduleNotification: '/v1/notification/bus-schedule/subscription',
    unsubscribeBusScheduleNotification: '/v1/notification/bus-schedule/unsubscription',
    getBusScheduleNotifications: '/v1/notification/bus-schedule',
    // /liveGames/{competition}
    liveGames: '/v1/liveGames',
    // /firebaseTokens/{token}
    firebaseTokens: '/v1/firebaseTokens',
    // /tournamentGames/{competitionId} ex. for WC2022 it is FDCP IdSeason=255711
    tournamentGamesUntilToday: '/v1/tournamentGamesUntilToday',
    contactList: 'v1/contacts',
};

export const profileImageEndpoint = `/v1/profileImage`;
export const externalPortalsEndpoint = `/v1/extranetPortalList`;

export default api;
