import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { FFButton } from 'src/atoms/ff/ff-button';
import { ButtonType, Size } from 'src/atoms/ff/ff-button/dto';
import { downloadFromApiEndpoints } from 'src/utils/download-assets-functions';
import { BytesToMegaBytes } from 'src/utils/converters';
import { FMHLink } from 'src/atoms/fmh-link';
import { ResourceSetMediaPackage as MediaPackageRS } from 'src/constants/resource-identifiers';
import { FMHDownloadCheckboxes } from 'src/atoms/fmh-download-checkboxes';
import classNames from 'classnames';
import FMHCheckboxWithLabel from '../fmh-checkbox-with-label/fmh-checkbox-with-label';
import FMHTermsAndConditionsCheck from '../fmh-terms-and-conditions-check/fmh-terms-and-conditions-check';
import styles from './fmh-download-package-button.module.scss';
import FMHDownloadPackageButtonProps, { DownloadStatus } from './dto';
import { ReactComponent as CrossIcon } from '@/icons/ff-cross.svg';
import { ReactComponent as FFDownArrowIcon } from '@/icons/ff-chevron/ff-chevron-down.svg';
import ResourcesSingleton from '@/utils/resource';

export const FMHDownloadPackageButton: FC<FMHDownloadPackageButtonProps> = ({
    className,
    videosDownloadApiEndpoints,
    videosDownloadLabels,
    videosDownloadSizes,
    additionalVideoFiles,
    additionalVideoFilesTitle,
    additionalAudioFiles,
    additionalAudioFilesTitle,
    additionalDocumentFiles,
    additionalDocumentFilesTitle,
    additionalImageFiles,
    additionalImageFilesTitle,
    showAllFileSize,
    isAllSelected: isAllSelectedProp,
    onSelectAll,
    downloadEndpoints,
    onDownloadEndpointsChange,
    buttonName,
    onDownloadButtonClicked,
    showFileSizes = true,
    isMatchCardDropdown = false,
}: FMHDownloadPackageButtonProps): JSX.Element => {
    const downloadStartedMessage = 'Download Started';

    const [hasDownloadStarted, setHasDownloadStarted] = useState<boolean>(false);
    const [isToggled, setIsToggled] = useState<boolean>(false);
    const [isTermsAndConditionsAccepted, setIsTermsAndConditionsAccepted] = useState<boolean>(false);
    const [selectedDownloadEndpoints, setSelectedDownloadEndpoints] = useState<Array<string>>(downloadEndpoints ?? []);
    const [downloadMessage, setDownloadMessage] = useState<string>(downloadStartedMessage);
    const [downloadProgress, setDownloadProgress] = useState<number>(0);
    const [downloadStatus, setDownloadStatus] = useState<DownloadStatus>(DownloadStatus.Initial);
    const [isDownloadMessageClosed, setIsDownloadMessageClosed] = useState<boolean>(false);
    const [isAllSelected, setIsAllSelected] = useState<boolean>(isAllSelectedProp ?? false);

    const [mainVideoCheckboxesSelectionArray, setMainVideosCheckboxesSelectionArray] = useState<Array<boolean>>([]);
    const [videosCheckboxesSelectionArray, setVideoCheckboxesSelectionArray] = useState<Array<boolean>>([]);
    const [audiosCheckboxesSelectionArray, setAudiosCheckboxesSelectionArray] = useState<Array<boolean>>([]);
    const [imagesCheckboxSelected, setImagesCheckboxSelected] = useState<boolean>(false);
    const [documentsCheckboxesSelectionArray, setDocumentsCheckboxesSelectionArray] = useState<Array<boolean>>([]);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const dropdownBtnRef = useRef<HTMLButtonElement>(null);
    const dropdownPositionRef = useRef({ top: 0, left: 0 });

    const labels = useMemo(() => ResourcesSingleton.getLabelSet(MediaPackageRS._Identifier), []);

    useEffect(() => {
        if (videosDownloadApiEndpoints && videosDownloadApiEndpoints.length > 0) {
            setMainVideosCheckboxesSelectionArray(
                Array.from({ length: videosDownloadApiEndpoints.length }, () => false),
            );
        }
        if (additionalVideoFiles && additionalVideoFiles.length > 0) {
            setVideoCheckboxesSelectionArray(Array.from({ length: additionalVideoFiles.length }, () => false));
        }
        if (additionalAudioFiles && additionalAudioFiles.length > 0) {
            setAudiosCheckboxesSelectionArray(Array.from({ length: additionalAudioFiles.length }, () => false));
        }
        if (additionalDocumentFiles && additionalDocumentFiles.length > 0) {
            setDocumentsCheckboxesSelectionArray(Array.from({ length: additionalDocumentFiles.length }, () => false));
        }
    }, [additionalAudioFiles, additionalDocumentFiles, additionalVideoFiles, videosDownloadApiEndpoints]);

    useEffect(() => {
        onSelectAll?.(isAllSelected);
    }, [isAllSelected, onSelectAll]);

    useEffect(() => {
        setIsAllSelected(isAllSelectedProp ?? false);
    }, [isAllSelectedProp]);

    useEffect(() => {
        onDownloadEndpointsChange?.(selectedDownloadEndpoints);
    }, [onDownloadEndpointsChange, selectedDownloadEndpoints]);

    useEffect(() => {
        setSelectedDownloadEndpoints(downloadEndpoints ?? []);
    }, [downloadEndpoints]);

    useEffect(() => {
        if (!isMatchCardDropdown) return;

        const onClickHandle = (event) => {
            if (
                !dropdownRef.current ||
                dropdownRef.current.contains(event.target) ||
                dropdownRef.current.previousSibling?.contains(event.target)
            )
                return;

            setIsToggled(false);
        };

        document.addEventListener('mousedown', onClickHandle);
        document.addEventListener('touchstart', onClickHandle);
        document.addEventListener('wheel', onClickHandle);
        return () => {
            document.removeEventListener('mousedown', onClickHandle);
            document.removeEventListener('touchstart', onClickHandle);
            document.removeEventListener('wheel', onClickHandle);
        };
    }, [isMatchCardDropdown]);

    const expandMenuButtonPressed = () => {
        const buttonBoundingClientRect = dropdownBtnRef.current?.getBoundingClientRect();

        if (buttonBoundingClientRect) {
            dropdownPositionRef.current = {
                top: buttonBoundingClientRect?.bottom + window.scrollY || 0,
                left: buttonBoundingClientRect?.x || 0,
            };
        }
        setIsToggled((prevState) => !prevState);
    };

    const pressedDownloadButton = () => {
        setHasDownloadStarted(true);

        downloadFromApiEndpoints(selectedDownloadEndpoints, setDownloadProgress, setDownloadStatus, setDownloadMessage);
    };

    const handleOptionChecked = (val, endpoint) => {
        let newSelectedEndpoints = [...selectedDownloadEndpoints];
        if (val && !newSelectedEndpoints.includes(endpoint)) newSelectedEndpoints.push(endpoint);
        else if (!val) newSelectedEndpoints = newSelectedEndpoints.filter((el) => el !== endpoint);

        setSelectedDownloadEndpoints(newSelectedEndpoints);
    };

    const handleMultipleOptionsChecked = (val: boolean, endpoints: Array<string>) => {
        let newSelectedEndpoints = [...selectedDownloadEndpoints];
        endpoints.forEach((element: string) => {
            if (val && !selectedDownloadEndpoints.includes(element)) newSelectedEndpoints.push(element);
            if (!val) newSelectedEndpoints = newSelectedEndpoints.filter((el) => el !== element);
        });
        setSelectedDownloadEndpoints(newSelectedEndpoints);
    };

    const getAllFilesSize = () => {
        let sum = 0;
        if (videosDownloadSizes) {
            sum += videosDownloadSizes.reduce((sum, item) => {
                return item ? sum + item : sum;
            }, 0);
        }
        if (additionalVideoFiles) {
            sum += additionalVideoFiles.reduce((sum, item) => {
                return Number(item.fileSizeInBytes) ? sum + Number(item.fileSizeInBytes) : sum;
            }, 0);
        }
        if (additionalAudioFiles) {
            sum += additionalAudioFiles.reduce((sum, item) => {
                return item.size ? sum + item.size : sum;
            }, 0);
        }
        if (additionalImageFiles) {
            sum += additionalImageFiles.reduce((sum, item) => {
                return item.size ? sum + item.size : sum;
            }, 0);
        }
        if (additionalDocumentFiles) {
            sum += additionalDocumentFiles.reduce((sum, item) => {
                return item.download.size ? sum + item.download.size : sum;
            }, 0);
        }
        return sum;
    };

    const toggleSelection = () => {
        setIsAllSelected((prevState) => {
            toggleAll(!prevState);
            return !prevState;
        });
    };

    const toggleAll = (value: boolean) => {
        setMainVideosCheckboxesSelectionArray(
            Array.from({ length: mainVideoCheckboxesSelectionArray.length }, () => value),
        );
        setVideoCheckboxesSelectionArray(Array.from({ length: videosCheckboxesSelectionArray.length }, () => value));
        setAudiosCheckboxesSelectionArray(Array.from({ length: audiosCheckboxesSelectionArray.length }, () => value));
        setImagesCheckboxSelected(value);
        setDocumentsCheckboxesSelectionArray(
            Array.from({ length: documentsCheckboxesSelectionArray.length }, () => value),
        );

        const newSelectedDownloads: Array<string> = [];

        if (value) {
            videosDownloadApiEndpoints?.forEach((el) => newSelectedDownloads.push(el));
            additionalVideoFiles?.forEach((el) => {
                if (el.downloadEndpoint) newSelectedDownloads.push(el.downloadEndpoint);
            });
            additionalAudioFiles?.forEach((el) => newSelectedDownloads.push(el.downloadEndpoint));
            additionalImageFiles?.forEach((el) => {
                if (el.downloadEndpoint) newSelectedDownloads.push(el.downloadEndpoint);
            });
            additionalDocumentFiles?.forEach((el) => {
                if (el.downloadEndpoint) newSelectedDownloads.push(el.downloadEndpoint);
            });
        }

        setSelectedDownloadEndpoints(newSelectedDownloads);
    };

    const getSelectAllToggleText = () => {
        return isAllSelected ? labels[MediaPackageRS.DeselectAllLabel] : labels[MediaPackageRS.SelectAllLabel];
    };

    const isDownloadEnabled = () => {
        return isTermsAndConditionsAccepted && selectedDownloadEndpoints.length > 0;
    };

    const resetDownloadComponent = () => {
        setHasDownloadStarted(false);
        setDownloadStatus(DownloadStatus.Initial);
        setIsDownloadMessageClosed(false);
        setIsToggled(false);
        setIsTermsAndConditionsAccepted(false);
        setSelectedDownloadEndpoints([]);
        setDownloadProgress(0);
        setDownloadMessage(downloadStartedMessage);
        setIsAllSelected(false);
        toggleAll(false);
    };

    return (
        <div className={`${styles.videoDownloadSection} ${className ?? ''}`}>
            {!hasDownloadStarted ? (
                <>
                    <button
                        className={`ff-px-0 ${styles.filesDownloadButton} ${isToggled ? styles.isToggled : ''}`}
                        onClick={expandMenuButtonPressed}
                        ref={dropdownBtnRef}
                    >
                        <p>
                            {buttonName ? buttonName : labels[MediaPackageRS.ButtonDownLoadLabel]}
                            {!isMatchCardDropdown && ` (${selectedDownloadEndpoints?.length ?? 0})`}
                        </p>
                        <FFDownArrowIcon className={`${styles.chevronIcon}`} />
                    </button>
                    <div
                        className={`${styles.dropdown}`}
                        style={{
                            display: isToggled ? 'block' : 'none',
                            top: isMatchCardDropdown ? dropdownPositionRef.current.top : '',
                            left: isMatchCardDropdown ? dropdownPositionRef.current.left : '',
                        }}
                        ref={dropdownRef}
                    >
                        <FMHLink onClick={toggleSelection} className={styles.selectAllButton}>
                            {getSelectAllToggleText()}
                        </FMHLink>
                        {showAllFileSize && (
                            <div className={styles.downloadSize}>{`${BytesToMegaBytes(getAllFilesSize())} MB`}</div>
                        )}
                        <hr className={`${styles.divider}`} />

                        {videosDownloadApiEndpoints && videosDownloadLabels && videosDownloadSizes && (
                            <FMHDownloadCheckboxes
                                title="Video"
                                items={videosDownloadApiEndpoints.map((downloadEndpoint, index) => ({
                                    title: videosDownloadLabels[index],
                                    downloadEndpoint,
                                    fileSizeInBytes: videosDownloadSizes[index],
                                }))}
                                checkboxSelections={mainVideoCheckboxesSelectionArray}
                                showFileSizes={showFileSizes}
                                onCheck={(value, _, item) => handleOptionChecked(value, item.downloadEndpoint)}
                            />
                        )}

                        {additionalVideoFilesTitle && additionalVideoFiles && (
                            <FMHDownloadCheckboxes
                                title={additionalVideoFilesTitle}
                                items={additionalVideoFiles.map((file) => ({
                                    title: file.title,
                                    downloadEndpoint: file.downloadEndpoint!,
                                    fileSizeInBytes: Number(file.fileSizeInBytes),
                                }))}
                                checkboxSelections={videosCheckboxesSelectionArray}
                                showFileSizes={showFileSizes}
                                onCheck={(value, _, item) => handleOptionChecked(value, item.downloadEndpoint)}
                            />
                        )}

                        {additionalAudioFilesTitle && additionalAudioFiles && (
                            <FMHDownloadCheckboxes
                                title={additionalAudioFilesTitle}
                                items={additionalAudioFiles.map((file) => ({
                                    title: file.title,
                                    downloadEndpoint: file.downloadEndpoint!,
                                    fileSizeInBytes: Number(file.size),
                                }))}
                                checkboxSelections={audiosCheckboxesSelectionArray}
                                showFileSizes={showFileSizes}
                                onCheck={(value, _, item) => handleOptionChecked(value, item.downloadEndpoint)}
                            />
                        )}

                        {additionalImageFilesTitle && additionalImageFiles && additionalImageFiles.length > 0 && (
                            <>
                                <p className={styles.groupTitle}>{additionalImageFilesTitle}</p>

                                <FMHCheckboxWithLabel
                                    isChecked={imagesCheckboxSelected}
                                    onValueChange={(val) => {
                                        handleMultipleOptionsChecked(
                                            val,
                                            additionalImageFiles.map((el) => el.downloadEndpoint!),
                                        );
                                    }}
                                    className={styles.downloadOption}
                                >
                                    <div className={styles.downloadTitle}>
                                        {`${additionalImageFiles.length}`}
                                        {' | '}
                                        {additionalImageFiles
                                            .reduce((result: Array<string>, item) => {
                                                const extension = item.extension?.toUpperCase();
                                                if (extension && !result.includes(extension)) {
                                                    result.push(extension);
                                                }
                                                return result;
                                            }, [])
                                            .join(', ')}
                                    </div>
                                    {showFileSizes && (
                                        <div className={styles.downloadSize}>
                                            {`${BytesToMegaBytes(
                                                additionalImageFiles.reduce((sum, item) => {
                                                    return item.size ? sum + item.size : sum;
                                                }, 0),
                                            )} MB`}
                                        </div>
                                    )}
                                </FMHCheckboxWithLabel>

                                <hr className={`${styles.divider}`} />
                            </>
                        )}

                        {additionalDocumentFilesTitle && additionalDocumentFiles && (
                            <FMHDownloadCheckboxes
                                title={additionalDocumentFilesTitle}
                                items={additionalDocumentFiles.map((file) => ({
                                    title: file.title,
                                    downloadEndpoint: file.downloadEndpoint!,
                                    fileSizeInBytes: Number(file.download.size),
                                    pdfPreviewFileUrl: file.download.url,
                                }))}
                                checkboxSelections={documentsCheckboxesSelectionArray}
                                showFileSizes={showFileSizes}
                                onCheck={(value, _, item) => handleOptionChecked(value, item.downloadEndpoint)}
                            />
                        )}

                        <FMHTermsAndConditionsCheck
                            isChecked={isTermsAndConditionsAccepted}
                            onValueChange={(val) => {
                                setIsTermsAndConditionsAccepted(val);
                            }}
                            className={styles.termsAndConditions}
                        />

                        <FFButton
                            kind={ButtonType.Primary}
                            className={`ff-px-0 ff-mt-16 ${styles.downloadButton}`}
                            func={
                                onDownloadButtonClicked
                                    ? () => onDownloadButtonClicked(selectedDownloadEndpoints)
                                    : pressedDownloadButton
                            }
                            text={'Download'}
                            size={Size.Small}
                            disabled={!isDownloadEnabled()}
                        />
                    </div>
                </>
            ) : (
                !isDownloadMessageClosed && (
                    <>
                        <p
                            className={classNames('ff-py-32 ff-pl-16', {
                                [styles.downloadStartedBlock]: true,
                                [styles.downloadFinishedBlock]: downloadStatus === DownloadStatus.Finished,
                                [styles.downloadErrorBlock]: downloadStatus === DownloadStatus.Error,
                            })}
                        >
                            {downloadMessage}
                            {(downloadStatus === DownloadStatus.Finished || DownloadStatus.Error) && (
                                <CrossIcon onClick={resetDownloadComponent} />
                            )}
                        </p>
                        {downloadProgress >= 0 && (
                            <div className={styles.downloadProgressBarOuter}>
                                <div className={styles.downloadProgressBar} style={{ width: `${downloadProgress}%` }} />
                            </div>
                        )}
                    </>
                )
            )}
        </div>
    );
};

export default FMHDownloadPackageButton;
