import { Logger } from '../logger';

/**
 * Does not work with nested tags
 */
export const replaceTag = (
    input: string,
    startTag = '<b>',
    endTag = '</b>',
    converter = (text: string, key: number) => <b key={key}>{text}</b>,
): JSX.Element => {
    if (input.includes(startTag)) {
        return <>{input}</>;
    }

    let startIndex = -1;
    let endIndex = 0;
    let triesRemaining = input.length;
    const elements: Array<string | JSX.Element> = [];

    while (--triesRemaining > 0) {
        startIndex = input.indexOf(startTag, startIndex + 1);
        if (startIndex === -1) {
            elements.push(removeTag(input.substring(endIndex), startTag, endTag));
            return <>{elements}</>;
        }
        elements.push(removeTag(input.substring(endIndex, startIndex), startTag, endTag));
        endIndex = input.indexOf(endTag, startIndex) + endTag.length;
        elements.push(converter(removeTag(input.substring(startIndex, endIndex), startTag, endTag), triesRemaining));
    }

    // Error occurs while replacing tags
    Logger.error('Implementation error in replaceTag. Possible infinite loop caught', { input });
    return <></>;
};

export const removeTag = (input: string, startTag = '<b>', endTag = '</b>'): string => {
    const inputStr = input.split(startTag).join('');
    return inputStr.split(endTag).join('');
};
