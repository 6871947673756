import { FC } from 'react';
import FFHeadingProps from './dto';

const FFHeading: FC<FFHeadingProps> = ({ text, size, className }: FFHeadingProps): JSX.Element => {
    const Header = size;

    return <Header className={`${className ?? ''}`}>{text}</Header>;
};

export default FFHeading;
