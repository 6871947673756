import { FC, useMemo, useRef } from 'react';
import { ResourceSetLiveGames } from 'src/constants/resource-identifiers';
import { FMHDownloadPackageButton } from 'src/molecules/fmh-download-package-button';
import { Maybe } from 'src/types/maybe';
import ResourcesSingleton from 'src/utils/resource';
import { LiveGamesDocuments } from '../dto';
import Styles from './live-games-header-bottom-row.module.scss';

const LiveGamesHeaderBottomRow: FC<{
    items: Maybe<string | Date>[];
    documents?: Maybe<LiveGamesDocuments>;
    isLive: Maybe<boolean>;
    isFinished: Maybe<boolean>;
}> = ({ items, documents, isLive, isFinished }): JSX.Element => {
    const isAllSelected = useRef(false);
    const labels = useMemo(
        () => ({
            startList: ResourcesSingleton.getLabel(ResourceSetLiveGames._Identifier, ResourceSetLiveGames.StartList),
            matchReport: ResourcesSingleton.getLabel(
                ResourceSetLiveGames._Identifier,
                ResourceSetLiveGames.MatchReport,
            ),
        }),
        [],
    );

    const ListItem: FC<{}> = ({ children }): JSX.Element => (
        <li className={`ff-mb-8 ff-px-16 ${Styles.listItem}`}>{children}</li>
    );

    const onDropdownDownloadClicked = (endpoints: string[]) => {
        endpoints.forEach((path) => {
            window.open(path);
        });
    };

    const DocumentDropdown: FC<{ isMatchReport?: boolean }> = ({ isMatchReport = false }): JSX.Element => (
        <FMHDownloadPackageButton
            className={Styles.downloadButton}
            buttonName={isMatchReport ? labels.matchReport : labels.startList}
            additionalDocumentFilesTitle={' '}
            additionalDocumentFiles={isMatchReport ? documents!.matchReports : documents!.startLists}
            isAllSelected={isAllSelected.current}
            onSelectAll={(value: boolean) => (isAllSelected.current = value)}
            onDownloadButtonClicked={onDropdownDownloadClicked}
            showFileSizes={false}
        />
    );

    return (
        <div className={`ff-px-24 ${Styles.lastRow}`}>
            <ul className={Styles.list}>
                {items.map((value, index) => {
                    if (!value) return null;
                    return <ListItem key={`${value}-${index}`}>{value}</ListItem>;
                })}

                {(isLive || isFinished) && documents?.startLists && (
                    <ListItem>
                        <DocumentDropdown />
                    </ListItem>
                )}

                {isFinished && documents?.matchReports && (
                    <ListItem>
                        <DocumentDropdown isMatchReport />
                    </ListItem>
                )}
            </ul>
        </div>
    );
};

export default LiveGamesHeaderBottomRow;
